import { userSelectors } from '@store/reducers/user/selectors';
import { useSelector } from 'react-redux';
import React from 'react';
import { Toast } from 'react-hot-toast';
import { NotificattionContainer } from '../NotificattionContainer';
import { formatNumber, renderCurrencySymbol, getImageByURL } from '@utils';
import s from './style.module.scss';
import convertToUsers from '@utils/convertToUsers';
import { Trans } from 'react-i18next';

type PropsType = {
	bonusAmount: number;
	t: Toast;
};

export const AddedBonusBalanceToast = (props: PropsType) => {
	const { bonusAmount, t } = props;
	const { currency } = useSelector(userSelectors.userInfo);

	return (
		<NotificattionContainer t={t}>
			<div className={s.container}>
				<img
					className={s.levelImg}
					src={getImageByURL(
						'../../../../../../assets/img/common/coin_without_space.webp'
					)}
				/>

				<h3 className={s.textTitle}>
					<Trans
						ns='global/translation'
						i18nKey={'eventNotifications.added_bonus_balance'}
						values={{
							sum: formatNumber(convertToUsers(bonusAmount)),
							cur: renderCurrencySymbol(currency?.toLowerCase())
						}}
						components={{ green: <b className={s.green} /> }}
					/>
				</h3>
			</div>
		</NotificattionContainer>
	);
};

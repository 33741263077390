import { SessionStorageParams } from '@types';

const removeGamesData = () => {
	const keys = [];

	for (let i = 0; i < sessionStorage.length; i++) {
		const key = sessionStorage.key(i);

		if (key?.startsWith(SessionStorageParams?.games_)) {
			keys.push(key);
		}
	}

	for (const key of keys) {
		sessionStorage.removeItem(key);
	}
};

export default removeGamesData;

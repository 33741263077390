import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={20}
		height={20}
		fill='none'
		{...props}
	>
		<path
			fill='#fff'
			d='M16.563 8.015c1.527.882 1.527 3.088 0 3.97l-8.125 4.69C6.91 17.559 5 16.456 5 14.692V5.309c0-1.764 1.91-2.867 3.438-1.985l8.124 4.691Z'
		/>
	</svg>
);

export default SvgComponent;

import React from 'react';
import { Toast } from 'react-hot-toast';
import { NotificattionContainer } from '../NotificattionContainer';
import { Trans } from 'react-i18next';
import { getImageByURL } from '@utils';
import s from './style.module.scss';

type PropsType = {
	amount: number;
	t: Toast;
};

export const AddedFreespinsToast = (props: PropsType) => {
	const { amount, t } = props;

	return (
		<NotificattionContainer t={t}>
			<div className={s.container}>
				<img
					className={s.levelImg}
					src={getImageByURL('../../../../../../assets/img/common/free_spins.webp')}
				/>

				<h3 className={s.textTitle}>
					<Trans
						ns='global/translation'
						i18nKey={'eventNotifications.added_freespins'}
						values={{
							amount: amount
						}}
						components={{ gold: <b className={s.gold} /> }}
					/>
				</h3>
			</div>
		</NotificattionContainer>
	);
};

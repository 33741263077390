import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M4.689 18.585a7.012 7.012 0 0 1-1.611-5.73c.066-.404-.161-.813-.556-.922-.395-.109-.807.123-.881.526a8.5 8.5 0 1 0 1.162-2.982c-.218.347-.071.796.293.983.365.187.809.04 1.033-.302a7.017 7.017 0 1 1 .56 8.427Zm.78-.626a4.999 4.999 0 0 0 8.75-1.308c.139-.395-.138-.795-.55-.872-.412-.077-.801.2-.966.586a3.482 3.482 0 1 1 .031-2.655c.156.389.538.675.952.607.414-.067.7-.46.57-.859A5 5 0 1 0 5.47 17.96Zm14.55-7.385c-.384.17-.804-.075-.913-.48-.11-.404.136-.814.507-1.008a3.481 3.481 0 1 0-4.753-4.591c-.181.378-.582.637-.99.542-.409-.095-.668-.506-.512-.895a4.998 4.998 0 0 1 5.833-2.998 5 5 0 0 1 .828 9.43Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			d='M9.807 16.783c-.26.041-.496.04-.707-.004a1.113 1.113 0 0 1-.514-.254.796.796 0 0 1-.247-.473.734.734 0 0 1 .095-.514c.093-.153.234-.28.421-.382.188-.101.408-.172.66-.212.251-.04.482-.04.692-.003.21.039.383.116.52.232.135.116.218.27.249.46.03.19 0 .366-.089.526-.09.158-.228.292-.414.402a1.92 1.92 0 0 1-.666.222Zm-.106-.668A.335.335 0 0 0 9.919 16a.295.295 0 0 0 .056-.242.282.282 0 0 0-.128-.207.325.325 0 0 0-.244-.048.34.34 0 0 0-.222.122.289.289 0 0 0-.052.236.302.302 0 0 0 .124.213c.07.043.152.058.248.042Zm-.11-.692c-.23.036-.442.037-.637.002a1.04 1.04 0 0 1-.478-.217.689.689 0 0 1-.232-.428.723.723 0 0 1 .082-.487c.083-.148.21-.272.38-.374.17-.102.374-.172.612-.21.238-.037.454-.034.647.01.194.043.353.123.477.238.124.112.2.258.23.437a.688.688 0 0 1-.088.479 1.04 1.04 0 0 1-.387.354 1.88 1.88 0 0 1-.606.196Zm-.087-.542a.23.23 0 0 0 .158-.094.253.253 0 0 0 .038-.195c-.013-.082-.046-.14-.097-.174a.231.231 0 0 0-.179-.04.23.23 0 0 0-.157.093c-.038.05-.05.114-.038.195a.253.253 0 0 0 .097.175.23.23 0 0 0 .178.04ZM17.278 7.361l1.443-2.456.213.451-1.4-.178.445-.402-.112.882-.822-.105.153-1.202 2.526.321-.078.612-1.28 2.216-1.088-.139Z'
		/>
	</svg>
);

export default SvgComponent;

import React, { useEffect, useRef, useState } from 'react';
import { CheckIcon, FlagComponents, ShevronSvg } from '@components/svg';
import { AnalyticsEventType, CountryCodes, Languages, LanguagesTopXGo } from '@types';
import { useOutsideAlerter, useUrlLang } from '@lib/hooks';
import { DEFAULT_LANG, isTapX, isTopXGo, SUPPORTED_LANGS } from '@config/global';
import { changeCurrencyTimezoneLocale } from '@lib/api/user';
import { useNavigate } from 'react-router-dom';
import { convertLocale, sendAnalyticsHandler } from '@utils';
import { useTranslation } from 'react-i18next';
import { authSelectors } from '@store/reducers/auth/selectors';
import { useSelector } from 'react-redux';
import CountryLanguage from '@ladjs/country-language';

import dayjs from 'dayjs';
import s from './style.module.scss';
import cn from 'classnames';

import 'dayjs/locale/en';
import 'dayjs/locale/ru';
import 'dayjs/locale/bn';
import 'dayjs/locale/pt';
import 'dayjs/locale/hi';
import { userSelectors } from '@store/reducers/user/selectors';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';

const LANGUAGES =
	isTopXGo || isTapX
		? [
				{ value: LanguagesTopXGo?.en, name: 'English' },
				{ value: LanguagesTopXGo?.pt, name: 'Português' }
			]
		: [
				{ value: Languages?.en, name: 'English' },
				{ value: Languages?.bn, name: 'বাংলা' },
				{ value: Languages?.ru, name: 'Русский' },
				{ value: Languages?.pt, name: 'Português' },
				{ value: Languages?.hi, name: 'हिन्दी' }
			];

type LangItemType = {
	value: Languages | LanguagesTopXGo;
	name: string;
};

export const DropdownLang = ({ menu, hide }: { menu?: boolean; hide?: boolean }) => {
	const { path, lang } = useUrlLang();

	const { isAuthed } = useSelector(authSelectors.authInfo);
	const { isMobile } = useSelector(commonUISelectors?.commonUIInfo);

	const [isOpened, setIsOpened] = useState(false);

	const [currentLang, setCurrentLang] = useState<LangItemType>();

	const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

	const { locationData } = useSelector(userSelectors.userInfo);

	const toggleIsOpened = () => {
		setIsOpened(!isOpened);
	};

	const navigate = useNavigate();
	const { i18n } = useTranslation();

	const dropdown = useRef<HTMLDivElement>(null);

	const pathPrefix = path.replace(/\/$/, '');

	useEffect(() => {
		if (lang) {
			//@ts-ignore
			const currLang = LANGUAGES?.find((l) => l.value === lang);

			if (currLang) {
				setCurrentLang(currLang);
				dayjs.locale(currLang?.value);
			}
		}
	}, [lang]);

	useEffect(() => {
		if (isFirstLoad && (i18n?.language !== lang || pathPrefix?.length === 0)) {
			//@ts-ignore
			let currLang = LANGUAGES?.find(
				(l: any) =>
					l?.value === convertLocale(i18n?.language) || l?.value === i18n?.language
			);

			if (!isAuthed && pathPrefix?.length === 0) {
				//@ts-ignore
				const navigatorLang = LANGUAGES?.find(
					(l: any) => l?.value === convertLocale(window?.navigator?.language)
				);

				if (locationData?.countryCode) {
					if (locationData?.countryCode === CountryCodes?.IN) {
						changeLanguage(LANGUAGES[0], true);
					} else {
						CountryLanguage.getCountryLanguages(
							locationData?.countryCode,
							(_, languages) => {
								if (languages?.length > 0) {
									//@ts-ignore
									currLang = LANGUAGES?.find(
										(l: any) => l?.value === languages[0]?.iso639_1
									);

									if (!currLang) {
										currLang = navigatorLang;
									}
								} else {
									currLang = navigatorLang;
								}
							}
						);
					}
				} else {
					currLang = navigatorLang;
				}
			}

			if (currLang) {
				changeLanguage(currLang, true);
			} else {
				changeLanguage(LANGUAGES[0], true);
			}
		}

		setIsFirstLoad(false);
	}, [i18n, lang]);

	const changeLanguage = (lang: LangItemType, withoutToggle?: boolean) => {
		!withoutToggle && toggleIsOpened();

		sendAnalyticsHandler(
			isMobile
				? AnalyticsEventType?.menu_mobile_click_choose_language
				: AnalyticsEventType?.language_choose,
			{
				language: lang
			}
		);

		i18n?.changeLanguage(lang?.value);

		const currentPathname = window.location.pathname?.replace(pathPrefix, '');

		const isDefault = lang?.value === DEFAULT_LANG ? '' : `/${lang?.value}`;

		navigate(
			`${currentPathname?.includes(isDefault) ? '' : isDefault}${currentPathname}${window?.location?.search ? window?.location?.search : ''}`
		);

		isAuthed &&
			changeCurrencyTimezoneLocale({
				locale: SUPPORTED_LANGS?.includes(lang?.value) ? lang?.value : 'en'
			});
	};

	useOutsideAlerter(dropdown, isOpened, toggleIsOpened);

	return (
		<div
			ref={dropdown}
			className={s.Dropdown}
		>
			<div
				onClick={() => {
					toggleIsOpened();

					sendAnalyticsHandler(
						isMobile
							? AnalyticsEventType?.menu_mobile_click_change_language
							: AnalyticsEventType?.language_click_change
					);
				}}
				className={cn(s.DropdownHeader, { [s.hidden]: hide })}
			>
				<div
					className={cn(
						s.DropdownTitle,
						{ [s.menu]: menu },
						{ [s.menuMobile]: isMobile }
					)}
				>
					{currentLang && <FlagComponents name={currentLang?.value} />}
					{!hide && currentLang?.name}
				</div>
				{!hide && (
					<div className={cn(s.DropdownArrow, { [s.opened]: isOpened })}>
						<ShevronSvg />
					</div>
				)}
			</div>
			<div className={cn(s.hiddenBlock, { [s.opened]: isOpened, [s.hidden]: hide })}>
				{LANGUAGES?.map((l) => {
					const isSelected = l.value === currentLang?.value;

					return (
						<button
							key={l.value}
							onClick={() => changeLanguage(l)}
							className={cn(s.itemBtn, { [s.selected]: isSelected })}
						>
							{currentLang && <FlagComponents name={l?.value} />}
							{!hide && l?.name}

							{!hide && isSelected && <CheckIcon className={s.check} />}
						</button>
					);
				})}
			</div>
		</div>
	);
};

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={16}
		height={16}
		fill='none'
		viewBox='0 0 16 16'
		{...props}
	>
		<path
			fill='#A0A4AD'
			fillRule='evenodd'
			d='M5.026 11.807a.73.73 0 0 1 0-1.031L8.302 7.5 5.026 4.224a.73.73 0 1 1 1.031-1.032L9.85 6.984a.73.73 0 0 1 0 1.031l-3.792 3.792a.73.73 0 0 1-1.03 0Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

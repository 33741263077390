import { UserService } from './UserService';
import { getCurrentUserData } from '@lib/api/user/getCurrentUserData';
import { setActiveCurrency } from '@store/reducers/user/dispatchers';

import { Currencies } from '@types';

export const changeCurrencyTimezoneLocale = async (params: {
	currency?: Currencies;
	timezone?: string;
	locale?: string;
}) => {
	try {
		const { status } = await UserService.changeCurrencyTimezoneLocale({ ...params });

		if (status === 200) {
			await getCurrentUserData();
			params?.currency && setActiveCurrency(params?.currency);

			// params?.locale && window?.location?.reload();
		}
	} catch (e) {
		console.error(e);
	}
};

import { AuthService } from './AuthService';

import { removeAntiSpamMonitor, removeAuthToken } from '@lib/browser-storage';
import { auth, setAuthToken } from '@store/reducers/auth/dispathcers';
import { clearUserData } from '@store/reducers/user/dispatchers';
import { clearPayment } from '@store/reducers/payment/dispathcers';
import { clearBonus } from '@store/reducers/bonus/dispatchers';
import { AntiSpamModules } from '@lib/browser-storage/anti-spam/antiSpamMonitor';
import { NavigateFunction } from 'react-router-dom';
import { pathsConfig } from '@config/paths';
import { NotificationsModalStorageE } from '@types';

export const logout = async (navigate: NavigateFunction) => {
	const { data } = await AuthService.logout();

	if (data) {
		navigate && navigate(pathsConfig?.home?.link);

		removeAuthToken();
		removeAntiSpamMonitor(AntiSpamModules.promocode);

		localStorage?.removeItem('deposite_bonus_authed');
		localStorage?.removeItem(NotificationsModalStorageE.notification_modal);

		auth(false);
		setAuthToken(null);
		clearUserData();
		clearPayment();
		clearBonus();
	}
};

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M11.75 6.5a3.25 3.25 0 1 0 0 6.5 3.25 3.25 0 0 0 0-6.5Zm0 1.454a1.796 1.796 0 1 1 0 3.592 1.796 1.796 0 0 1 0-3.592ZM17.75 16a2.25 2.25 0 0 0-2.25-2.25h-.25a.75.75 0 0 0 0 1.5h.25a.75.75 0 0 1 .75.75v.5a.75.75 0 0 0 1.5 0V16ZM6.25 16a2.25 2.25 0 0 1 2.25-2.25h.25a.75.75 0 0 1 0 1.5H8.5a.75.75 0 0 0-.75.75v.5a.75.75 0 0 1-1.5 0V16Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M3.25 6.5A2.75 2.75 0 0 1 6 3.75h12a2.75 2.75 0 0 1 2.75 2.75V17A2.75 2.75 0 0 1 18 19.75H6A2.75 2.75 0 0 1 3.25 17v-4.5a.75.75 0 0 1 1.5 0V17c0 .69.56 1.25 1.25 1.25h12c.69 0 1.25-.56 1.25-1.25V6.5c0-.69-.56-1.25-1.25-1.25H6c-.69 0-1.25.56-1.25 1.25V9a.75.75 0 0 1-1.5 0V6.5Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M8.47 1.47a.75.75 0 0 1 1.06 0l2.5 2.5a.75.75 0 0 1-1.06 1.06l-2.5-2.5a.75.75 0 0 1 0-1.06Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M14.488 1.93a.75.75 0 0 0-1.057.082l-1.5 1.75a.75.75 0 1 0 1.138.976l1.5-1.75a.75.75 0 0 0-.08-1.057ZM7 18.25a.75.75 0 0 1 .75.75v2.5a.75.75 0 0 1-1.5 0V19a.75.75 0 0 1 .75-.75ZM17 18.25a.75.75 0 0 1 .75.75v2.5a.75.75 0 0 1-1.5 0V19a.75.75 0 0 1 .75-.75Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='M12 13.5a.75.75 0 0 1 .75.75V16a.75.75 0 0 1-1.5 0v-1.75a.75.75 0 0 1 .75-.75Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

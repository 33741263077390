import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={20}
		height={20}
		fill='none'
		{...props}
	>
		<path
			fill='#A0A4AD'
			fillRule='evenodd'
			d='M1.458 7.915a2.708 2.708 0 0 1 2.709-2.708h11.666a2.708 2.708 0 0 1 2.709 2.708v6.667a2.708 2.708 0 0 1-2.709 2.708h-5.208a.625.625 0 1 1 0-1.25h5.209c.805 0 1.458-.653 1.458-1.458V7.915c0-.805-.653-1.458-1.459-1.458H4.168c-.806 0-1.458.653-1.458 1.458v6.667c0 .805.652 1.458 1.458 1.458h1.875a.625.625 0 1 1 0 1.25H4.167a2.708 2.708 0 0 1-2.708-2.708V7.915Z'
			clipRule='evenodd'
		/>
		<path
			fill='#A0A4AD'
			fillRule='evenodd'
			d='M15.209 5.11a1.875 1.875 0 0 0-2.23-1.84l-7.47 1.44a1.142 1.142 0 0 0-.926 1.122h-1.25c0-1.146.814-2.131 1.939-2.349l7.47-1.441a3.125 3.125 0 0 1 3.716 3.068v.305h-1.25V5.11ZM12.709 11.248c0-.345.28-.624.625-.624h2.083a.625.625 0 1 1 0 1.25h-2.083a.625.625 0 0 1-.625-.626Z'
			clipRule='evenodd'
		/>
		<path
			fill='red'
			fillRule='evenodd'
			d='M5.625 13.751c0-.345.28-.625.625-.625h4.167a.625.625 0 0 1 0 1.25H6.25a.625.625 0 0 1-.625-.625Z'
			clipRule='evenodd'
		/>
		<path
			fill='red'
			fillRule='evenodd'
			d='M8.333 11.043c.346 0 .625.28.625.625v4.167a.625.625 0 1 1-1.25 0v-4.167c0-.345.28-.625.625-.625Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

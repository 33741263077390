import React from 'react';
import toast, { Toast } from 'react-hot-toast';
import { NotificattionContainer } from '../NotificattionContainer';
import { useLocales } from '@lib/hooks';
import { getImageByURL } from '@utils';
import s from './style.module.scss';

type PropsType = {
	t: Toast;
};

export const ErrorPaymentMethodNotFound = (props: PropsType) => {
	const { t } = props;

	const { localizeText } = useLocales({
		path: 'global',
		node: 'notifications'
	});

	const closeHandler = () => {
		toast?.dismiss(t?.id);
	};

	return (
		<NotificattionContainer
			duration={10000}
			onClose={() => closeHandler && closeHandler()}
			t={t}
		>
			<div className={s.container}>
				<img
					className={s.img}
					src={getImageByURL(
						'../../../../../../assets/img/common/not-found-payment-method.webp'
					)}
				/>

				<div className={s.textBlock}>
					<h3 className={s.textTitle}>
						{localizeText('not_found_payment_method_title')}
					</h3>

					<span className={s.textDescr}>
						{localizeText('not_found_payment_method_descr')}
					</span>
				</div>
			</div>
		</NotificattionContainer>
	);
};

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={18}
		height={18}
		fill='none'
		{...props}
	>
		<circle
			cx={9}
			cy={9}
			r={9}
			fill='#0BD712'
			opacity={0.15}
		/>
		<circle
			cx={9}
			cy={9}
			r={6}
			fill='#0BD712'
			opacity={0.15}
		/>
		<circle
			cx={9}
			cy={9}
			r={3}
			fill='#0BD712'
		/>
	</svg>
);

export default SvgComponent;

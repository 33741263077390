import { BonusService } from './BonusService';
import { setBonusBalances } from '@store/reducers/bonus/dispatchers';

export const getBonusBalances = async () => {
	const { status, data } = await BonusService.getBonusBalances();

	if (status === 200)
		setBonusBalances(
			data.sort((a, b) => {
				const keyA = new Date(a.createdAt);
				const keyB = new Date(b.createdAt);

				if (keyA < keyB) return -1;

				if (keyA > keyB) return 1;

				return 0;
			})
		);
};

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M7 20.5a.75.75 0 0 0-.75.75v.25a.75.75 0 0 1-1.5 0v-.25A2.25 2.25 0 0 1 7 19h10.25a2.25 2.25 0 0 1 2.25 2.25v.25a.75.75 0 0 1-1.5 0v-.25a.75.75 0 0 0-.75-.75H7Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M6.75 4.25h10.5V10h-1.5V5.75h-7.5V9h-1.5V4.25Zm.75 10a.75.75 0 0 1 .75.75v4.75h-1.5V15a.75.75 0 0 1 .75-.75Zm9 0a.75.75 0 0 1 .75.75v4.75h-1.5V15a.75.75 0 0 1 .75-.75Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M6.25 2.75v.75c0 .414.336.75.75.75h9.75a.75.75 0 0 0 .75-.75v-.75H6.25Zm-1.5-.25c0-.69.56-1.25 1.25-1.25h11.75c.69 0 1.25.56 1.25 1.25v1a2.25 2.25 0 0 1-2.25 2.25H7A2.25 2.25 0 0 1 4.75 3.5v-1Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='M3.754 9.317C4.456 8.871 5.307 8.75 6 8.75h12c.693 0 1.544.121 2.246.567.753.478 1.254 1.283 1.254 2.433 0 1.45-.93 2.478-2.09 2.53a1.924 1.924 0 0 1-1.543-.663 2.196 2.196 0 0 1-.26-.367H6.393a2.196 2.196 0 0 1-.26.367c-.411.466-.976.69-1.543.663C3.43 14.229 2.5 13.2 2.5 11.75c0-1.15.501-1.955 1.254-2.433ZM17.25 11.75a.75.75 0 0 1 1.5 0c0 .49.134.752.243.876.105.12.228.162.348.156.214-.01.659-.231.659-1.032 0-.65-.249-.97-.558-1.167-.361-.23-.885-.333-1.442-.333H6c-.557 0-1.08.104-1.442.333C4.25 10.78 4 11.1 4 11.75c0 .8.445 1.022.66 1.032a.425.425 0 0 0 .347-.156c.11-.124.243-.386.243-.876a.75.75 0 0 1 1.5 0h10.5Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			d='M12.75 17a.75.75 0 0 0-1.5 0v2.25h1.5V17Z'
		/>
	</svg>
);

export default SvgComponent;

import { GameProviderService } from './GameService';
import { setGameCategories } from '@store/reducers/game-provider/dispatchers';
import { CategoriesKeys, IGameProviderInitialState } from '@types';

type GetGameCategories = (
	params:
		| {
				limit: number;
				page: number;
				filter?: any;
		  }
		| URLSearchParams,
	isSpecificElement: boolean
) => Promise<IGameProviderInitialState['currentGameCategory'] | void>;

export const getGameCategories: GetGameCategories = async (params) => {
	const {
		status,
		data: { data: categories }
	} = await GameProviderService.getGameCategories(params);

	if (status === 200) {
		const result = categories
			?.map((e) => {
				return { ...e };
			})
			?.filter((e) => e?.key !== CategoriesKeys?.jackpot);

		setGameCategories(result);
	}
};

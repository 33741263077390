import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M11.25 5.75a.75.75 0 0 1 .75.75c0 .578.117.954.263 1.228.152.284.36.505.627.758l.136.126c.514.474 1.224 1.129 1.224 2.388 0 .512-.114.963-.352 1.336-.24.375-.57.619-.916.766-.3.129-.618.188-.92.205.171 1.03.64 1.456.773 1.522a.75.75 0 1 1-.67 1.342c-.837-.419-1.665-1.693-1.665-3.671a.75.75 0 0 1 .897-.735c.35.07.738.068.996-.042a.503.503 0 0 0 .24-.194c.054-.084.117-.24.117-.529 0-.587-.245-.817-.781-1.32a61.296 61.296 0 0 1-.109-.103c-.295-.279-.65-.636-.92-1.141-.275-.515-.44-1.14-.44-1.936a.75.75 0 0 1 .75-.75ZM18.25 9a.75.75 0 0 1 .75-.75h1c.966 0 1.75.784 1.75 1.75v4A1.75 1.75 0 0 1 20 15.75h-1a.75.75 0 0 1 0-1.5h1a.25.25 0 0 0 .25-.25v-4a.25.25 0 0 0-.25-.25h-1a.75.75 0 0 1-.75-.75Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M21 4a.75.75 0 0 1 .75.75V12a.75.75 0 0 1-1.5 0V4.75A.75.75 0 0 1 21 4ZM2 5.75A4.25 4.25 0 0 1 6.25 1.5h8.5A4.25 4.25 0 0 1 19 5.75v10A4.25 4.25 0 0 1 14.75 20h-8.5A4.25 4.25 0 0 1 2 15.75v-2.125a.75.75 0 0 1 1.5 0v2.125a2.75 2.75 0 0 0 2.75 2.75h8.5a2.75 2.75 0 0 0 2.75-2.75v-10A2.75 2.75 0 0 0 14.75 3h-8.5A2.75 2.75 0 0 0 3.5 5.75V8A.75.75 0 0 1 2 8V5.75ZM2.5 21.75a.75.75 0 0 1 .75-.75H20.5a.75.75 0 0 1 0 1.5H3.25a.75.75 0 0 1-.75-.75Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='M9.75 5.75a.75.75 0 0 1 .75.75c0 .797-.165 1.42-.44 1.936-.27.505-.625.862-.92 1.14l-.109.103c-.536.504-.781.734-.781 1.321 0 .288.063.445.117.53a.503.503 0 0 0 .24.193c.258.11.645.112.996.042a.75.75 0 0 1 .897.735c0 1.978-.827 3.252-1.665 3.67a.75.75 0 1 1-.67-1.34c.132-.067.602-.493.772-1.523a2.734 2.734 0 0 1-.92-.205 2.001 2.001 0 0 1-.915-.766c-.238-.373-.352-.824-.352-1.336 0-1.26.71-1.914 1.224-2.388l.136-.126c.268-.253.475-.474.627-.758C8.883 7.454 9 7.078 9 6.5a.75.75 0 0 1 .75-.75Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			d='M23 4.5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z'
		/>
	</svg>
);

export default SvgComponent;

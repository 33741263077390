export enum FilterType {
	EQ = 'eq',
	IN = 'in'
}

export function filterStringGenerator(
	parameter: FilterType,
	data: Record<string, unknown>
): string | Array<string> {
	let operator;

	if (typeof data === 'string') {
		return data;
	}

	const result: Array<string> = [];

	switch (parameter) {
		case FilterType.EQ:
			operator = '$eq';
			break;
		case FilterType.IN:
			operator = '$in';
			break;
		default:
			throw new Error(`Unsupported parameter: ${parameter}`);
	}

	for (const key in data) {
		if (Object.prototype.hasOwnProperty.call(data, key)) {
			const value = data[key];

			result.push(`${key}||${operator}||${value}`);
		}
	}

	if (result?.length > 1) {
		return result;
	}

	return result[0];
}

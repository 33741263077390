import * as React from 'react';

const SvgComponent = () => (
	<svg
		width='25'
		height='24'
		viewBox='0 0 25 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M12.7498 9.77271V13.4518H17.8626C17.6381 14.635 16.9643 15.6368 15.9539 16.3105L19.0371 18.7028C20.8334 17.0446 21.8698 14.6092 21.8698 11.716C21.8698 11.0424 21.8094 10.3946 21.6971 9.77281L12.7498 9.77271Z'
			fill='#4285F4'
		/>
		<path
			d='M7.42582 13.3086L6.73045 13.8409L4.26904 15.7581C5.83222 18.8586 9.03607 21.0004 12.7497 21.0004C15.3146 21.0004 17.465 20.154 19.0369 18.7032L15.9537 16.3109C15.1074 16.8809 14.0278 17.2264 12.7497 17.2264C10.2797 17.2264 8.18112 15.5596 7.4297 13.3141L7.42582 13.3086Z'
			fill='#34A853'
		/>
		<path
			d='M4.26902 7.24228C3.62132 8.52041 3.25 9.9627 3.25 11.4999C3.25 13.0372 3.62132 14.4795 4.26902 15.7576C4.26902 15.7662 7.42998 13.3049 7.42998 13.3049C7.23998 12.7349 7.12768 12.1304 7.12768 11.4998C7.12768 10.8693 7.23998 10.2648 7.42998 9.69481L4.26902 7.24228Z'
			fill='#FBBC05'
		/>
		<path
			d='M12.7498 5.78272C14.149 5.78272 15.3926 6.26635 16.3858 7.19909L19.1062 4.47867C17.4566 2.94142 15.3149 2 12.7498 2C9.03624 2 5.83219 4.13318 4.26901 7.24228L7.42988 9.69501C8.1812 7.44953 10.2799 5.78272 12.7498 5.78272Z'
			fill='#EA4335'
		/>
	</svg>
);

export default SvgComponent;

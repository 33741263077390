import { updateFavoriteProviderGames } from '@store/reducers/game-provider/dispatchers';
import { GameProviderService } from './GameService';
import { IProviderGame, ProviderGamesType } from '@types';

export type GamesRequestOptions = {
	providerGamesType?: ProviderGamesType;
	gameId: number;
};

type GetProviderGames = (params: GamesRequestOptions) => Promise<IProviderGame[]>;

export const setFavoriteGames: GetProviderGames = async (params) => {
	const { status, data } = await GameProviderService.setFavoriteGames(params);

	if (status === 201) {
		params?.providerGamesType &&
			(await updateFavoriteProviderGames({
				providerGamesType: params?.providerGamesType,
				gameId: params?.gameId
			}));

		return data;
	}

	return data;
};

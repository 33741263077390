import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

// props in ms
// TODOдать локализацию
export const getFormattedDuration = (ms: number): string => {
	const duration = dayjs.duration(ms);
	const days = duration.days();
	const hours = duration.hours();
	const minutes = duration.minutes();
	const seconds = duration.seconds();

	const daysText = days > 0 ? `${days}д` : '';
	const hoursText = hours > 0 ? `${hours}ч` : '';
	const minutesText = minutes > 0 ? `${minutes}м` : '';
	const secondsText = seconds > 0 ? `${seconds}с` : '';

	return `${daysText} ${hoursText} ${minutesText} ${secondsText}`;
};

import React, { useRef, useState } from 'react';
import s from './style.module.scss';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import flags from 'react-phone-number-input/flags';
import ru from 'react-phone-number-input/locale/ru.json';
import en from 'react-phone-number-input/locale/en.json';
import { CheckSvg, ShevronSvg } from '@components/svg';
import { useLocales, useOutsideAlerter } from '@lib/hooks';
import { SearchInput } from '../search-input';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

type CountrySelectP = {
	className?: string;
	selectedIndex: any;
	onChange: (index: string) => void;
	isDisabled?: boolean;
};

export const CountrySelect = ({
	className,
	selectedIndex,
	onChange,
	isDisabled
}: CountrySelectP) => {
	const [isOptionsActive, setIsOptionsActive] = useState(false);
	const [searchValue, setSearchValue] = useState<string>('');

	const { i18n } = useTranslation();

	const searchInputHandler = (text: string | number) => {
		setSearchValue(text?.toString());
	};

	const { localizeText } = useLocales({
		path: 'components/common/modals',
		node: 'AuthModal'
	});

	const changeSelectedItem = (index: string) => {
		if (isDisabled) return;

		setIsOptionsActive(false);
		onChange(index);
		setSearchValue('');
	};

	const dropdown = useRef(null);
	const optionToggle = () => {
		if (!isDisabled) {
			setIsOptionsActive(!isOptionsActive);
			setSearchValue('');
		}
	};

	useOutsideAlerter(dropdown, isOptionsActive && !isDisabled, optionToggle);

	//@ts-ignore
	const Flag = flags[selectedIndex];

	const switchLang = (lang: string) => {
		switch (lang) {
			case 'en':
				return en;
			case 'ru':
				return ru;
			case 'bn':
				return en;
			case 'hi':
				return en;
			default:
				return en;
		}
	};

	const renderList = () => {
		return getCountries()?.map((e) => {
			return {
				key: e,
				name: switchLang(i18n.language)[e],
				phoneCode: `+${getCountryCallingCode(e)}`
			};
		});
	};

	return (
		<div
			ref={dropdown}
			// className={`${s.countrySelect} ${className} ${isDisabled ? s.disabled : ''}`}
			className={cn(s.countrySelect, className, { [s.disabled]: isDisabled })}
		>
			<div
				className={s.countrySelectControl}
				onClick={optionToggle}
			>
				<div
					className={
						s.countrySelectArrow +
						(isOptionsActive ? ` ${s.countrySelectArrowActive}` : '')
					}
				>
					<ShevronSvg />
				</div>
				<div className={s.countrySelectIcon}>
					{Flag && selectedIndex ? <Flag title={selectedIndex} /> : ''}
				</div>
				<div className={s.countrySelectValue}>
					<div className={s.countrySelectValueText}>
						+{getCountryCallingCode(selectedIndex)}
					</div>
				</div>
			</div>

			<div
				className={
					s.countrySelectOptions +
					(isOptionsActive ? ` ${s.countrySelectOptionsActive}` : '')
				}
			>
				<SearchInput
					containerClassName={s.serachInputContainer}
					placeholder={localizeText('search')}
					type='text'
					value={searchValue}
					onChange={searchInputHandler}
					onCloseHandler={optionToggle}
					rippleAnimation
					isDisabled={isDisabled}
				/>

				<div className={s.listItems}>
					{renderList()
						?.filter((o) => o?.name?.toLowerCase()?.includes(searchValue.toLowerCase()))
						.map((option) => {
							const { key, phoneCode, name } = option;
							const FlagDropDown = flags[key];

							return (
								<div
									className={
										s.countrySelectOption +
										(selectedIndex === option?.key
											? ` ${s.countrySelectOptionSelected}`
											: '')
									}
									key={key}
									onClick={() => changeSelectedItem(key)}
								>
									<div className={s.countrySelectOptionLeft}>
										<div className={s.countrySelectIcon}>
											{FlagDropDown && option ? <FlagDropDown title={key} /> : ''}
										</div>
										<div className={s.countrySelectOptionNumber}>{phoneCode}</div>
									</div>
									<div className={s.countrySelectOptionSeparator}></div>

									<div className={s.countrySelectOptionLabel}>{name}</div>
									{selectedIndex === option?.key && (
										<div className={s.countrySelectOptionCheck}>
											<CheckSvg />
										</div>
									)}
								</div>
							);
						})}
				</div>
			</div>
		</div>
	);
};

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M6.76 5.634a.75.75 0 0 1 .8-.632l6.25.5a.75.75 0 1 1-.12 1.496l-5.563-.445-.068.39c-.394 2.265-.958 5.507-.365 8.374.34 1.647 1.055 3.104 2.366 4.157 1.203.966 2.984 1.653 5.624 1.761.127-6.433 1.849-10.677 3.42-13.15l-.7-.091a.75.75 0 1 1 .193-1.488l1.926.25a.75.75 0 0 1 .498 1.201c-1.562 2.03-3.845 6.45-3.845 14.043a.75.75 0 0 1-.75.75c-3.285 0-5.652-.778-7.306-2.107-1.655-1.33-2.505-3.139-2.895-5.022-.657-3.179-.028-6.76.366-8.998.064-.369.123-.701.168-.99Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M17.002.943a.75.75 0 0 1 .247.593L17 6.786a.75.75 0 1 1-1.498-.072l.206-4.333-11.45 1.761c.084 3.445.871 7.107 3.383 11.217a.75.75 0 0 1-1.28.782C3.528 11.508 2.75 7.368 2.75 3.5c0-.37.27-.685.636-.741l13-2a.75.75 0 0 1 .616.184Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='M13.614 14.917a4.874 4.874 0 0 1-1.223.583 1.47 1.47 0 0 1-1.468-.363 5.123 5.123 0 0 1-.837-1.115c-.312-.566-.664-1.495-.382-2.548a1.419 1.419 0 0 1 2.79.41 1.42 1.42 0 0 1 2.62 1.04c-.27 1.01-.969 1.64-1.5 1.993Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		{...props}
	>
		<path
			fill='#A0A4AD'
			data-id='changed-color'
			fillRule='evenodd'
			d='M6.527 5.953c.04-.402.44-.645.833-.552.393.093.63.488.609.892a4.037 4.037 0 1 0 1.643-3.047c-.326.239-.786.259-1.08-.018-.294-.278-.31-.745.004-1a5.5 5.5 0 1 1-2.009 3.725ZM12 15.25a.75.75 0 0 1 .75.75v4.75a.75.75 0 0 1-1.5 0V16a.75.75 0 0 1 .75-.75ZM2.83 11.929a.75.75 0 0 1 .747-.679h1.75a.75.75 0 0 1 0 1.5H4.285c-.03 1.568.284 3.583.984 5.276.824 1.988 2.035 3.224 3.558 3.224a.75.75 0 0 1 0 1.5c-2.478 0-4.059-2.014-4.943-4.15-.902-2.177-1.232-4.8-1.054-6.671ZM20.997 11.929a.75.75 0 0 0-.747-.679H18.5a.75.75 0 0 0 0 1.5h1.041c.031 1.568-.283 3.583-.984 5.276-.823 1.988-2.034 3.224-3.557 3.224a.75.75 0 0 0 0 1.5c2.477 0 4.058-2.014 4.943-4.15.901-2.177 1.232-4.8 1.054-6.671Z'
			clipRule='evenodd'
		/>
		<path
			fill='#A0A4AD'
			data-id='changed-color'
			fillRule='evenodd'
			d='M10.093 1.506a.75.75 0 0 1 .651.837 2.92 2.92 0 0 0 .007.828c.045.261.126.421.204.505.055.06.147.127.374.121.258-.006.679-.11 1.312-.455a.75.75 0 0 1 1.106.59c.037.404.14.903.48 1.3.313.367.936.768 2.273.768a.75.75 0 0 1 0 1.5c-1.663 0-2.749-.516-3.414-1.294a3.362 3.362 0 0 1-.631-1.133c-.388.14-.75.215-1.089.224-.62.015-1.137-.2-1.508-.598-.35-.375-.513-.85-.586-1.276a4.414 4.414 0 0 1-.016-1.266.75.75 0 0 1 .837-.651Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='M6 13.836a1.75 1.75 0 0 1 2.244-1.678l2.968.873a.75.75 0 1 1-.424 1.438l-2.967-.872a.25.25 0 0 0-.321.24v.715a.25.25 0 0 0 .295.246l1.57-.286a.75.75 0 1 1 .27 1.476l-1.572.286A1.75 1.75 0 0 1 6 14.552v-.716ZM18 13.836a1.75 1.75 0 0 0-2.244-1.678l-2.968.873a.75.75 0 1 0 .424 1.438l2.968-.872a.25.25 0 0 1 .32.24v.715a.25.25 0 0 1-.295.246l-1.57-.286a.75.75 0 1 0-.27 1.476l1.572.286A1.75 1.75 0 0 0 18 14.552v-.716Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			d='M7.5 3.25a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0Z'
		/>
	</svg>
);

export default SvgComponent;

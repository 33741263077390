import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		{...props}
	>
		<path
			fill='#A0A4AD'
			data-id='changed-color'
			fillRule='evenodd'
			d='M12 19.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Zm0 1.5a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='M12 6.25a.75.75 0 0 1 .75.75v5.75a.75.75 0 0 1-1.5 0V7a.75.75 0 0 1 .75-.75Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			d='M13 16a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z'
		/>
	</svg>
);

export default SvgComponent;

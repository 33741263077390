import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M1.999 3.987c.627-.774 1.603-1.237 2.83-1.237 2.508 0 4.277 1.637 5.38 3.674 1.1 2.037 1.62 4.602 1.62 6.826 0 2.742-.673 4.796-1.762 6.182C8.972 20.826 7.487 21.5 6 21.5A.75.75 0 0 1 6 20c1.013 0 2.068-.451 2.888-1.495.826-1.051 1.442-2.747 1.442-5.255 0-2.026-.48-4.336-1.441-6.112-.96-1.775-2.316-2.888-4.06-2.888-.83 0-1.356.3-1.665.682-.321.395-.472.957-.394 1.572.145 1.145 1.094 2.434 3.06 2.698V7.25a.75.75 0 0 1 1.5 0V9.5c0 .674-.558 1.286-1.306 1.23-2.853-.216-4.497-2.095-4.742-4.038-.121-.957.1-1.945.717-2.705ZM22.081 3.987c-.627-.774-1.603-1.237-2.831-1.237-2.507 0-4.277 1.637-5.379 3.674-1.101 2.037-1.621 4.602-1.621 6.826 0 2.742.674 4.796 1.763 6.182 1.095 1.394 2.58 2.068 4.067 2.068a.75.75 0 0 0 0-1.5c-1.013 0-2.068-.451-2.888-1.495-.826-1.051-1.442-2.747-1.442-5.255 0-2.026.48-4.336 1.44-6.112.961-1.775 2.317-2.888 4.06-2.888.83 0 1.356.3 1.666.682.32.395.472.957.394 1.572-.145 1.145-1.094 2.434-3.06 2.698V7.25a.75.75 0 0 0-1.5 0V9.5c0 .674.558 1.286 1.306 1.23 2.853-.216 4.496-2.095 4.742-4.038.121-.957-.1-1.945-.717-2.705Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M6.75 12.75A.75.75 0 0 1 7.5 12h9a.75.75 0 0 1 0 1.5h-9a.75.75 0 0 1-.75-.75Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='M12 18.5a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-1.5 0v-2a.75.75 0 0 1 .75-.75ZM12 2.927l-.668 1.336L12 5.71l.668-1.447L12 2.927ZM10.882 1.81c.46-.921 1.775-.921 2.236 0l.945 1.89c.17.34.176.739.017 1.083l-.945 2.049c-.447.968-1.823.968-2.27 0L9.92 4.782A1.25 1.25 0 0 1 9.937 3.7l.945-1.891Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

import { BottomMenuKeys, RegisterPageTypesE } from '@types';
import { underscoreToHyphen } from '@utils';

export interface IRoute {
	link: string;
	key: string;
}

type EnumRegisterPageKeys = keyof typeof RegisterPageTypesE;
type EnumRegisterPageKeysFields = { [key in EnumRegisterPageKeys]?: IRoute };

export interface IPathsConfig extends EnumRegisterPageKeysFields {
	home: IRoute & {
		tabs: {
			[BottomMenuKeys.live_dealers]: {
				path: string;
				link: string;
			};
			[BottomMenuKeys.crash]: {
				path: string;
				link: string;
			};
			[BottomMenuKeys.tv_games]: {
				path: string;
				link: string;
			};
			[BottomMenuKeys.casino]: {
				path: string;
				link: string;
			};
		};
	};
	providers: {
		init: IRoute;
		// current: IRoute
	};
	providerGame: IRoute;

	payment: IRoute;
	gameSearch: IRoute;
	referral: IRoute;
	telegram_bonus: IRoute;
	cashback_bonus: IRoute;
	levels: IRoute;
	bonus: IRoute;
	transaction: IRoute;

	paymentRedirect: IRoute;

	profile: IRoute;

	bgaming: IRoute;

	information: IRoute;
	pay_for_bugs: IRoute;

	politics_terms: IRoute;
	politics_privacy_policy: IRoute;
	politics_self_policy: IRoute;
	politics_aml_kyc_policy: IRoute;
	politics_terms_and_conditions: IRoute;
}

export const pathsConfig: IPathsConfig = {
	home: {
		key: 'home',
		link: '/',

		tabs: {
			[BottomMenuKeys.live_dealers]: {
				path: `/${underscoreToHyphen(BottomMenuKeys.live_dealers)}/:category?`,
				link: `/${underscoreToHyphen(BottomMenuKeys.live_dealers)}`
			},
			[BottomMenuKeys.crash]: {
				path: `/${underscoreToHyphen(BottomMenuKeys.casino)}/${underscoreToHyphen(BottomMenuKeys.crash)}`,
				link: `/${underscoreToHyphen(BottomMenuKeys.casino)}/${underscoreToHyphen(BottomMenuKeys.crash)}`
			},
			[BottomMenuKeys.tv_games]: {
				path: `/${underscoreToHyphen(BottomMenuKeys.tv_games)}/:category?`,
				link: `/${underscoreToHyphen(BottomMenuKeys.tv_games)}`
			},
			[BottomMenuKeys.casino]: {
				path: `/${underscoreToHyphen(BottomMenuKeys.casino)}/:category?`,
				link: `/${underscoreToHyphen(BottomMenuKeys.casino)}`
			}
		}
	},

	gameSearch: {
		key: 'gameSearch',
		link: '/search'
	},

	paymentRedirect: {
		key: 'paymentRedirect',
		link: '/redirect'
	},

	providers: {
		init: {
			key: 'providers',
			link: '/providers'
		}
	},

	providerGame: {
		key: 'currentInitedGame',
		link: '/games/provider/:gameName'
	},

	payment: {
		key: 'payment',
		link: '/payment'
	},

	referral: {
		key: 'referral',
		link: '/referral'
	},

	telegram_bonus: {
		key: 'telegram-bonus',
		link: '/telegram-bonus'
	},

	cashback_bonus: {
		key: 'cashback-bonus',
		link: '/cashback-bonus'
	},

	bgaming: {
		key: 'bgaming',
		link: '/bgaming-prizes'
	},

	levels: {
		key: 'levels',
		link: '/levels'
	},

	bonus: { key: 'bonus', link: '/all-bonuses' },

	transaction: { key: 'transaction', link: '/transaction' },

	profile: { key: 'profile', link: '/profile' },

	information: { key: 'information', link: '/information' },

	pay_for_bugs: { key: 'pay_for_bugs', link: '/pay-for-bugs' },

	politics_terms: {
		key: 'politics_terms',
		link: '/politics-terms'
	},
	politics_privacy_policy: {
		key: 'politics_privacy_policy',
		link: '/politics-privacy-policy'
	},
	politics_self_policy: {
		key: 'politics_self_policy',
		link: '/politics-self-policy'
	},
	politics_aml_kyc_policy: {
		key: 'politics_aml_kyc_policy',
		link: '/politics-aml-kyc-policy'
	},
	politics_terms_and_conditions: {
		key: 'politics_terms_and_conditions',
		link: '/politics-terms-and-conditions'
	}
};

(Object.keys(RegisterPageTypesE) as Array<keyof typeof RegisterPageTypesE>).map((key) => {
	pathsConfig[key] = {
		key: RegisterPageTypesE[key],
		link: `/${RegisterPageTypesE[key]}`
	};
});

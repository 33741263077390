import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M10.524 1.396a.75.75 0 0 1 .847-.639l8.562 1.198a2.75 2.75 0 0 1 2.343 3.104l-1.734 12.398a.75.75 0 1 1-1.485-.208L20.79 4.851a1.25 1.25 0 0 0-1.065-1.41l-8.563-1.198a.75.75 0 0 1-.639-.847Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M5.94 6a2.75 2.75 0 0 1 2.75-2.75h6.646A2.75 2.75 0 0 1 18.086 6v10.519a2.75 2.75 0 0 1-2.75 2.75H8.69a2.75 2.75 0 0 1-2.75-2.75V6Zm2.75-1.25C8 4.75 7.44 5.31 7.44 6v10.519c0 .69.56 1.25 1.25 1.25h6.646c.69 0 1.25-.56 1.25-1.25V6c0-.69-.56-1.25-1.25-1.25H8.69Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M4.297 8.4a1.25 1.25 0 0 0-.986 1.467l2.023 10.322a1.25 1.25 0 0 0 1.468.986l5.69-1.116a.75.75 0 0 1 .289 1.472l-5.69 1.116a2.75 2.75 0 0 1-3.229-2.17L1.84 10.155a2.75 2.75 0 0 1 2.169-3.227l.537-.106a.75.75 0 1 1 .289 1.472l-.537.105Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='M12.083 6.755a.75.75 0 0 1 .662.828c-.082.74-.515 2.313-1.674 3.485.269.325.558.716.818 1.14.44.716.861 1.632.861 2.542a.75.75 0 0 1-1.5 0c0-.49-.245-1.116-.64-1.758a8.48 8.48 0 0 0-1.14-1.462.75.75 0 0 1 .094-1.14c1.16-.828 1.62-2.335 1.69-2.973a.75.75 0 0 1 .829-.662Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='M11.917 6.755a.75.75 0 0 0-.662.828c.082.74.515 2.313 1.674 3.485a10.1 10.1 0 0 0-.818 1.14c-.44.716-.861 1.632-.861 2.542a.75.75 0 0 0 1.5 0c0-.49.245-1.116.64-1.758a8.488 8.488 0 0 1 1.14-1.462.75.75 0 0 0-.094-1.14c-1.16-.828-1.62-2.335-1.69-2.973a.75.75 0 0 0-.829-.662Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		viewBox='0 0 24 24'
		fill='none'
		{...props}
	>
		<path
			fill='gold'
			d='M13.605 2.689c-.609-1.405-2.601-1.405-3.21 0L8.388 7.313a.25.25 0 0 1-.206.15l-5.018.478c-1.524.145-2.14 2.04-.992 3.054l3.779 3.336a.25.25 0 0 1 .078.242l-1.096 4.92c-.333 1.495 1.28 2.666 2.598 1.888l4.34-2.563a.25.25 0 0 1 .255 0l4.34 2.563c1.32.778 2.932-.393 2.599-1.888l-1.096-4.92a.25.25 0 0 1 .078-.242l3.779-3.336c1.148-1.014.532-2.909-.992-3.054l-5.019-.478a.25.25 0 0 1-.205-.15L13.605 2.69Z'
		/>
	</svg>
);

export default SvgComponent;

import {
	InitCryptoInvoicePA,
	InitP2pInvoicePA,
	InitPaymentPA,
	IPaymentInitialState,
	PaymentMethodType,
	PaymentType,
	SetIframePaymentPA,
	SetInvoiceActiveCryptoCurrencyPA,
	SetInvoiceValuePA,
	SetPaymentMethodsInvoicePA,
	SetPaymentMethodsWithdrawPA,
	SetPaymentMethodTypePA,
	SetPaymentProcessingStatusPA,
	SetQrPaymentInfoTypePA,
	SetSelectedPaymentMethodIdPA,
	SetUserConfirmedPaymentPA,
	SetWithdrawalActiveCryptoCurrencyPA,
	SetWithdrawalCardNumberPA,
	SetWithdrawalCommissionPA,
	SetWithdrawalCommissionPendingPA,
	SetWithdrawalCryptoAddressPA,
	SetWithdrawalValuePA
} from '@types';

export const initPayment = (state: IPaymentInitialState, action: InitPaymentPA) => {
	state.paymentType = action.payload;

	if (action?.payload === PaymentType.withdrawal) {
		state.selectedTypePaymentMethods = state.paymentMethodsWithdraw;
	} else if (action?.payload === PaymentType.invoice) {
		state.selectedTypePaymentMethods = state.paymentMethodsInvoice;
	} else {
		state.selectedTypePaymentMethods = [];
	}
};

export const setPaymentMethodType = (
	state: IPaymentInitialState,
	action: SetPaymentMethodTypePA
) => {
	state.paymentMethodType = action.payload;
};

export const setQrPaymentInfo = (
	state: IPaymentInitialState,
	action: SetQrPaymentInfoTypePA
) => {
	state.qrPaymentInfo = action.payload;
};

export const setUserConfirmedPayment = (
	state: IPaymentInitialState,
	action: SetUserConfirmedPaymentPA
) => {
	state.userConfirmedPayment = action.payload;
};

export const setInvoiceValue = (
	state: IPaymentInitialState,
	action: SetInvoiceValuePA
) => {
	state.invoiceValue = action.payload;
};

export const setInvoiceActiveCryptoCurrency = (
	state: IPaymentInitialState,
	action: SetInvoiceActiveCryptoCurrencyPA
) => {
	state.invoiceActiveCryptoCurrency = action.payload;
};

export const setWithdrawalValue = (
	state: IPaymentInitialState,
	action: SetWithdrawalValuePA
) => {
	state.withdrawalValue = action.payload;
};

export const setWithdrawalCardNumber = (
	state: IPaymentInitialState,
	action: SetWithdrawalCardNumberPA
) => {
	state.withdrawalCardNumber = action.payload;
};

export const setWithdrawalCryptoAddress = (
	state: IPaymentInitialState,
	action: SetWithdrawalCryptoAddressPA
) => {
	state.withdrawalCryptoAddress = action.payload;
};

export const setWithdrawalActiveCryptoCurrency = (
	state: IPaymentInitialState,
	action: SetWithdrawalActiveCryptoCurrencyPA
) => {
	state.withdrawalActiveCryptoCurrency = action.payload;
};

export const setWithdrawalCommission = (
	state: IPaymentInitialState,
	action: SetWithdrawalCommissionPA
) => {
	state.withdrawalCommission.commission = action.payload.commission;
	state.withdrawalCommission.payout = action.payload.payout;
};

export const setWithdrawalCommissionPending = (
	state: IPaymentInitialState,
	action: SetWithdrawalCommissionPendingPA
) => {
	state.withdrawalCommission.isPending = action.payload;
};

export const setPaymentProcessingStatus = (
	state: IPaymentInitialState,
	action: SetPaymentProcessingStatusPA
) => {
	state.isPaymentProcessing = action.payload;
};

export const finishWithdrawal = (state: IPaymentInitialState) => {
	state.isRequestToWithdrawalSuccess = true;
};

export const finishStopWithdrawal = (state: IPaymentInitialState) => {
	state.isRequestToWithdrawalSuccess = false;
};

export const initP2pInvoice = (state: IPaymentInitialState, action: InitP2pInvoicePA) => {
	state.p2p = action.payload;
};

export const initCryptoInvoice = (
	state: IPaymentInitialState,
	action: InitCryptoInvoicePA
) => {
	state.cryptoInvoiceDetails = action.payload;
};

export const setIframePayment = (
	state: IPaymentInitialState,
	action: SetIframePaymentPA
) => {
	state.iframePayment = action.payload;
};

export const setPaymentMethodsWithdraw = (
	state: IPaymentInitialState,
	action: SetPaymentMethodsWithdrawPA
) => {
	state.paymentMethodsWithdraw = action.payload;
};

export const setPaymentMethodsInvoice = (
	state: IPaymentInitialState,
	action: SetPaymentMethodsInvoicePA
) => {
	state.paymentMethodsInvoice = action.payload;
};

export const setSelectedPaymentMethodId = (
	state: IPaymentInitialState,
	action: SetSelectedPaymentMethodIdPA
) => {
	state.selectedPaymentMethodId = action.payload;
};

export const clearPayment = (state: IPaymentInitialState) => {
	state.paymentType = null;
	state.paymentMethodType = PaymentMethodType.fiat;
	state.invoiceValue = '';
	state.withdrawalValue = '';
	state.withdrawalCardNumber = '';
	state.isRequestToWithdrawalSuccess = false;
	state.isPaymentProcessing = false;
	state.paymentMethodsInvoice = [];
	state.paymentMethodsWithdraw = [];
	state.selectedPaymentMethodId = null;
};

import { createSlice } from '@reduxjs/toolkit';
import { IGameProviderInitialState } from '@types';
import * as reducers from './reducers';

export const initialState: IGameProviderInitialState = {
	providerGames: [],
	lastSeenGames: [],
	foundProviderGames: [],
	favoriteProviderGames: [],
	pageProviderGames: [],
	localGames: [],
	providers: [],
	gameCategories: [],
	currentInitedGame: null,
	currentGameCategory: null,
	currentGameProvider: null,
	gameCount: 0
};

export const { actions, reducer } = createSlice({
	name: 'gameProvider',
	initialState,
	reducers
});

import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M14.25 4a.25.25 0 0 0-.25.25v5.5c0 .138.112.25.25.25h5.5a.25.25 0 0 0 .25-.25V7a3 3 0 0 0-3-3h-2.75ZM17 2.5A4.5 4.5 0 0 1 21.5 7v4.05a.45.45 0 0 1-.45.45h-7.2a1.35 1.35 0 0 1-1.35-1.35V3.4a.9.9 0 0 1 .9-.9H17ZM9.75 20a.25.25 0 0 0 .25-.25v-5.5a.25.25 0 0 0-.25-.25h-5.5a.25.25 0 0 0-.25.25V17a3 3 0 0 0 3 3h2.75ZM7 21.5A4.5 4.5 0 0 1 2.5 17v-4.05a.45.45 0 0 1 .45-.45h7.2c.746 0 1.35.604 1.35 1.35v7.2a.45.45 0 0 1-.45.45H7ZM14 19.75c0 .138.112.25.25.25H17a3 3 0 0 0 3-3v-2.75a.25.25 0 0 0-.25-.25h-5.5a.25.25 0 0 0-.25.25v5.5Zm3 1.75a4.5 4.5 0 0 0 4.5-4.5v-4.05a.45.45 0 0 0-.45-.45h-7.2a1.35 1.35 0 0 0-1.35 1.35v7.2c0 .248.201.45.45.45H17Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='M10 4.25A.25.25 0 0 0 9.75 4H7a3 3 0 0 0-3 3v2.75c0 .138.112.25.25.25H9.7a.3.3 0 0 0 .3-.3V4.25ZM7 2.5A4.5 4.5 0 0 0 2.5 7v4.05c0 .248.201.45.45.45H9.7a1.8 1.8 0 0 0 1.8-1.8V2.95a.45.45 0 0 0-.45-.45H7Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={16}
		height={16}
		fill='none'
		{...props}
	>
		<rect
			width={16}
			height={16}
			fill='#E8ECE9'
			rx={8}
		/>
		<path
			fill='#6D7380'
			fillRule='evenodd'
			d='M5.039 5.039a.417.417 0 0 1 .589 0L8 7.411l2.372-2.372a.417.417 0 0 1 .59.59L8.588 8l2.372 2.372a.417.417 0 1 1-.589.59L8 8.59l-2.372 2.372a.417.417 0 0 1-.59-.59L7.412 8 5.039 5.628a.417.417 0 0 1 0-.589Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

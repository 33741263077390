import { UserService } from '@lib/api/user/UserService';
import { initUser } from '@store/reducers/user/dispatchers';
import { auth } from '@store/reducers/auth/dispathcers';
import {
	getAllRMWBonus,
	getBonusBalances,
	getFreeSpinsQty,
	getSocialBonusTasks
} from '../bonus';
import { getUsersLevels } from './getUsersLevels';

export const getCurrentUserData = async (successFunc?: () => void) => {
	try {
		const { status, data } = await UserService.getCurrentUserData();

		//todo оптимизировать получение данных о юзере при логине/реге
		if (status === 200) {
			await getAllRMWBonus(data?.currency);
			await getUsersLevels(data?.currency);
			await getFreeSpinsQty();
			await getBonusBalances();
			await getSocialBonusTasks();

			window?.gtag('set', { user_id: data?.id });
			window?.ym(97429474, 'setUserID', data?.id.toString());
			window?.ym(97429474, 'userParams', {
				UserID: data?.id
			});

			initUser(data);
			auth(true);

			successFunc && successFunc();
		}
	} catch (e) {
		console.log(e);
	}
};

import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={48}
		height={48}
		fill='none'
		{...props}
	>
		<g clipPath='url(#a)'>
			<rect
				width={48}
				height={48}
				fill='#090F1E'
				rx={12.571}
			/>
			<rect
				width={48}
				height={48}
				fill='#090F1E'
				rx={4}
			/>
			<path
				fill='red'
				d='M38.175 19.551s2.036-1.276 4.129-4.551c.543 3.069 1.531 4.86 1.531 4.86l-1.085-.041c-3.644 10.091-16.282 9.185-16.282 9.185v-.062c6.222-.474 10.583-3.954 12.929-9.39h-1.222Z'
			/>
			<path
				fill='red'
				d='M40.723 28.915H36.46l-1.27-2.341 3.236-1.627 2.296 3.968ZM36.994 22.498a15.962 15.962 0 0 1-2.18 2.711l-2.54-4.814H36l.993 2.103Z'
			/>
			<path
				fill='#231F20'
				d='M38.736 25.481c-.102.17-.408.647-.95 1.236v.001c-.422.459-.987.986-1.709 1.49l-.477-.88a11.85 11.85 0 0 0 .242-.108l.002-.001.073-.033.002-.001c.107-.05.214-.1.318-.153l.122-.06a13.778 13.778 0 0 0 1.481-.867l.063-.042.107-.074.013-.009a9.833 9.833 0 0 0 .395-.287l.135-.103.06-.047a1.574 1.574 0 0 0 .054-.042l.055-.044.014.024Z'
				opacity={0.4}
			/>
			<path
				fill='#fff'
				d='m33.174 23.402-.106.241h.002l-.447 1.018c-.258.587-1.052 1.063-1.773 1.063h-3.937l-.992 2.261h-2.948l1.817-4.139c.049-.112.2-.203.338-.203h4.992l.333-.76h-5.241l.794-1.81h5.94l1.228 2.329ZM17.835 21.073c-.447 0-.938.295-1.098.659l-.505 1.152h5.1l-1.445 3.291h-2.15l1.112-2.531h-2.95l-1.716 3.91h.016c-.039.247.152.432.478.432h6.678c.325 0 .679-.186.857-.433h.017l.052-.117.003-.006 2.39-5.444c.22-.503-.102-.91-.72-.91h-6.119v-.003ZM16.1 21.073H6.795L6 22.883h9.298l.802-1.81ZM11.837 23.643H8.849l-1.914 4.342h2.972l1.93-4.342Z'
			/>
		</g>
		<defs>
			<clipPath id='a'>
				<rect
					width={48}
					height={48}
					fill='#fff'
					rx={12.571}
				/>
			</clipPath>
		</defs>
	</svg>
);

export default SvgComponent;

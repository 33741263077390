import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={20}
		height={20}
		fill='none'
		{...props}
	>
		<path
			fill='#fff'
			d='M6.97 16.108H3.46c-1.695 0-2.415-2.159-1.059-3.176a2.292 2.292 0 0 0 .917-1.834V8.056C3.317 4.438 6.24.917 10 .917s6.683 3.521 6.683 7.139V9.85a5.403 5.403 0 0 0-1.5-.263V8.056c0-2.935-2.39-5.639-5.183-5.639-2.793 0-5.183 2.704-5.183 5.639v3.042A3.792 3.792 0 0 1 3.3 14.132a.264.264 0 0 0 .159.476h6.138a5.492 5.492 0 0 0 .1 1.5H8.475a1.532 1.532 0 0 0 1.865 1.656c.271.456.608.87.997 1.227a3.032 3.032 0 0 1-4.366-2.883Z'
		/>
		<path
			fill='#fff'
			fillRule='evenodd'
			d='M19.167 15a4.167 4.167 0 1 1-8.334 0 4.167 4.167 0 0 1 8.334 0Zm-1.544-1.676a.625.625 0 0 1-.03.883l-2.976 2.778a.625.625 0 0 1-.853 0l-1.19-1.111a.625.625 0 1 1 .852-.914l.764.713 2.55-2.38a.625.625 0 0 1 .884.03Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

import { isTapX } from '@config/global';
import { AnalyticsEventType } from '@types';
import { sendAnalyticsHandler } from '@utils';

const openCarrotChat = async () => {
	sendAnalyticsHandler(AnalyticsEventType?.menu_click_support);

	if (isTapX) {
		window.location.href = 'mailto:info@tapxgames.net';

		return;
	}

	//@ts-ignore
	window?.webim?.api?.chat.start();
};

export default openCarrotChat;

import React, { useState } from 'react';
import s from './style.module.scss';
import cn from 'classnames';
import { EyeOff, EyeOn } from '@components/svg';
import { handleKeyDown } from '@utils';

type InputMode =
	| 'none'
	| 'text'
	| 'tel'
	| 'url'
	| 'email'
	| 'numeric'
	| 'decimal'
	| 'search';

type InputP = {
	name: string;
	type: string;
	value?: string;
	onChange?: (e: React.ChangeEvent<any>) => void;
	isDisabled?: boolean;
	className?: string;
	label?: string;
	error?: boolean;
	inputMode?: InputMode;
	onEnterDownHandler?: () => void;
	pattern?: string;
	inputRef?: any;
	onBlur?: (e: React.ChangeEvent<any>) => void;
	onFocus?: (e: React.ChangeEvent<any>) => void;
	placeholder?: string;
};

export const Input = (props: InputP) => {
	const {
		name,
		value,
		type,
		onChange,
		isDisabled = false,
		label = '',
		error,
		inputMode,
		onEnterDownHandler,
		pattern,
		inputRef,
		onBlur,
		onFocus,
		placeholder
	} = props;

	const [isShowPass, setIsShowPass] = useState(false);

	const toggleIsShow = () => setIsShowPass(!isShowPass);

	const renderPassBtns = () => {
		return (
			<div
				onClick={toggleIsShow}
				className={s.passBtn}
			>
				{isShowPass ? <EyeOn /> : <EyeOff />}
			</div>
		);
	};

	return (
		<div
			className={cn(s['form-input'], { [s.error]: error, [s.placeholder]: placeholder })}
		>
			<input
				ref={inputRef}
				pattern={pattern}
				inputMode={inputMode || undefined}
				value={value}
				type={isShowPass ? 'text' : type}
				placeholder={placeholder || label}
				id={name}
				disabled={isDisabled}
				onChange={onChange}
				onBlur={onBlur}
				onFocus={onFocus}
				onKeyDown={
					isDisabled
						? undefined
						: onEnterDownHandler
							? handleKeyDown('Enter', onEnterDownHandler)
							: undefined
				}
			/>
			<label htmlFor={name}>{label}</label>

			{type === 'password' ? renderPassBtns() : null}
		</div>
	);
};

import { BonusService } from './BonusService';
import { setCashbackInfo } from '@store/reducers/bonus/dispatchers';

export const getCurrentWeekCashbackAmount = async () => {
	const { status, data } = await BonusService.getCurrentWeekCashbackAmount();

	if (status === 200) {
		setCashbackInfo(data);
	}
};

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		viewBox='0 0 24 24'
		{...props}
	>
		<path
			fill='#A0A4AD'
			fillRule='evenodd'
			d='M8.616 19.384a1.25 1.25 0 0 1 0-1.768L14.232 12 8.616 6.384a1.25 1.25 0 1 1 1.768-1.768l6.5 6.5a1.25 1.25 0 0 1 0 1.768l-6.5 6.5a1.25 1.25 0 0 1-1.768 0Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

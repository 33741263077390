import { bindActions } from '@utils';
import { actions } from './reducer';

export const {
	setAllLevels,
	setAllRMWBonus,
	setTelegramCode,
	clearBonus,
	setSocialBonusTask,
	setPromocodeStatus,
	setPromocodeInputValue,
	setPromocodeUsingBan,
	setReferralLink,
	setReferralRewards,
	setReferralsQtyAndPendingRewards,
	setPremiumSubscriptionConditions,
	setFreeSpinsInfo,
	setCashbackInfo,
	usedPastWeekCashback,
	setBonusGameOpen,
	setBonusBalances,
	deleteBonusBalances
} = bindActions(actions);

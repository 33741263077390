import {
	IGameProviderInitialState,
	ProviderGamesType,
	SetCurrentGameCategoryPA,
	SetCurrentGameProviderPA,
	SetCurrentProviderGamePA,
	SetGameCategoriesPA,
	SetGameCountPA,
	SetLocalGamesPA,
	SetProviderFavoriteGamesPA,
	SetProviderGamesPA,
	SetProvidersPA
} from '@types';

// eslint-disable-next-line max-len
export const setProviderGames = (
	state: IGameProviderInitialState,
	{ payload: { providerGamesType, providerGames } }: SetProviderGamesPA
) => {
	state[providerGamesType] = providerGames;
};

// eslint-disable-next-line max-len
export const addProviderGames = (
	state: IGameProviderInitialState,
	{ payload: { providerGamesType, providerGames } }: SetProviderGamesPA
) => {
	state[providerGamesType] = [...state[providerGamesType], ...providerGames];
};

export const updateFavoriteProviderGames = (
	state: IGameProviderInitialState,
	{ payload: { providerGamesType, gameId } }: SetProviderFavoriteGamesPA
) => {
	const newState = state[providerGamesType].map((game) => {
		if (game?.id === gameId) {
			return { ...game, isFavourite: true };
		}

		return game;
	});

	state[providerGamesType] = newState;
};

export const deleteFavoriteProviderGamesDispatcher = (
	state: IGameProviderInitialState,
	{ payload: { providerGamesType, gameId } }: SetProviderFavoriteGamesPA
) => {
	state[providerGamesType] = state[providerGamesType].map((game) => {
		if (game?.id === gameId) {
			return { ...game, isFavourite: false };
		}

		return game;
	});

	state[ProviderGamesType.favoriteProviderGames] = state[
		ProviderGamesType.favoriteProviderGames
	].filter((game) => game?.id !== gameId);
};

export const setLocalGames = (
	state: IGameProviderInitialState,
	action: SetLocalGamesPA
) => {
	state.localGames = action.payload;
};

export const setProviders = (
	state: IGameProviderInitialState,
	action: SetProvidersPA
) => {
	state.providers = action.payload;
};

export const setCurrentInitedGame = (
	state: IGameProviderInitialState,
	action: SetCurrentProviderGamePA
) => {
	state.currentInitedGame = action.payload;
};

export const clearCurrentInitedGame = (state: IGameProviderInitialState) => {
	state.currentInitedGame = null;
};

export const setGameCategories = (
	state: IGameProviderInitialState,
	action: SetGameCategoriesPA
) => {
	state.gameCategories = action.payload;
};

export const setGameCount = (
	state: IGameProviderInitialState,
	action: SetGameCountPA
) => {
	state.gameCount = action.payload;
};

export const setCurrentGameCategory = (
	state: IGameProviderInitialState,
	action: SetCurrentGameCategoryPA
) => {
	state.currentGameCategory = action.payload;
};

export const setCurrentGameProvider = (
	state: IGameProviderInitialState,
	action: SetCurrentGameProviderPA
) => {
	state.currentGameProvider = action.payload;
};

import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		{...props}
	>
		<path
			fill='#A0A4AD'
			fillRule='evenodd'
			data-id='changed-color'
			d='M19.799 8.669 4.856 7.36a.5.5 0 0 0-.542.455l-.567 6.475a.5.5 0 0 0 .455.542l14.943 1.307a.5.5 0 0 0 .541-.455l.567-6.475a.5.5 0 0 0-.454-.541ZM4.986 5.867A2 2 0 0 0 2.82 7.685l-.567 6.475a2 2 0 0 0 1.818 2.167l14.943 1.307a2 2 0 0 0 2.167-1.818l.566-6.475a2 2 0 0 0-1.818-2.167L4.986 5.867Z'
			clipRule='evenodd'
		/>
		<path
			fill='#A0A4AD'
			fillRule='evenodd'
			data-id='changed-color'
			d='M7.62 4.249a.761.761 0 0 0-.88.625.75.75 0 0 1-1.48-.247 2.261 2.261 0 0 1 2.616-1.856l9.843 1.703A2.75 2.75 0 0 1 20 7.184v.566a.75.75 0 0 1-1.5 0v-.566a1.25 1.25 0 0 0-1.037-1.232L7.621 4.25ZM16.63 19.502a.761.761 0 0 0 .88-.625.75.75 0 1 1 1.48.247 2.261 2.261 0 0 1-2.616 1.856L6.53 19.277a2.75 2.75 0 0 1-2.28-2.71V16a.75.75 0 0 1 1.5 0v.567a1.25 1.25 0 0 0 1.036 1.232l9.843 1.703Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			d='M11.512 14.087a4.38 4.38 0 0 1-1.027-.213 2.961 2.961 0 0 1-.796-.39l.556-1.008c.193.135.41.25.652.346.246.091.487.147.723.168.138.012.247.013.326.003a.426.426 0 0 0 .184-.063.176.176 0 0 0 .066-.124c.006-.077-.031-.142-.112-.194a1.301 1.301 0 0 0-.333-.14 12.31 12.31 0 0 0-.449-.137 4.995 4.995 0 0 1-.494-.185 2.048 2.048 0 0 1-.444-.266 1.08 1.08 0 0 1-.303-.396 1.177 1.177 0 0 1-.078-.584c.023-.265.117-.498.282-.701.17-.207.407-.362.711-.467.309-.104.682-.137 1.122-.098.289.025.57.08.846.166.275.085.52.201.732.347l-.524 1.005a3.252 3.252 0 0 0-.596-.292 2.2 2.2 0 0 0-.566-.13 1.191 1.191 0 0 0-.333.009.382.382 0 0 0-.186.082.21.21 0 0 0-.06.13c-.007.073.03.136.113.188a1.5 1.5 0 0 0 .326.133l.456.132c.168.048.333.107.494.179.162.071.308.16.438.265.134.106.238.238.31.396.072.158.098.35.079.573-.023.26-.12.494-.289.7-.165.203-.4.358-.705.467-.304.105-.678.138-1.121.099Zm-.349.609.07-.793.86.075-.07.793-.86-.075Zm.433-4.942.07-.794.86.076-.07.793-.86-.075Z'
		/>
	</svg>
);

export default SvgComponent;

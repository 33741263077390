import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={61}
		height={56}
		fill='none'
		{...props}
	>
		<path
			fill='var(--main-accent)'
			d='M40.685 19.992S47.758 15.787 55.033 5c1.886 10.108 5.321 16.01 5.321 16.01l-3.772-.136C43.92 54.116 0 51.131 0 51.131v-.204c21.623-1.56 36.779-13.025 44.929-30.934h-4.244Z'
		/>
		<path
			fill='var(--main-accent)'
			d='M49.538 50.837h-14.81l-4.417-7.712 11.25-5.36 7.977 13.072ZM36.58 29.7c-2.416 3.547-4.97 6.488-7.577 8.93l-8.827-15.857H33.13l3.45 6.928Z'
		/>
	</svg>
);

export default SvgComponent;

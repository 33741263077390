import { setAuthToken } from '@lib/browser-storage';
import { getCurrentUserData } from '.';
import { getFreeSpinsQty } from '../bonus';
import { UserService } from './UserService';
import { ErrorToast } from '@components/common/global-settings';
import toast from 'react-hot-toast';

export const confirmEmail = async () => {
	await UserService.confirmEmail();
};

export const confirmEmailCode = async (
	token: string,
	successFunc?: (success: boolean) => void
) => {
	try {
		const { data } = await UserService.confirmEmailCode(token);

		if (data.accessToken) {
			await setAuthToken(data.accessToken);
			await getFreeSpinsQty();
		}

		await getCurrentUserData();

		successFunc && successFunc(true);
	} catch {
		toast((t) => ErrorToast({ t: t, text: 'something_wrong' }));

		successFunc && successFunc(false);
	}
};

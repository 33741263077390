import { BonusService } from './BonusService';
import { getBonusBalances } from './getBonusBalances';

export const setBonusBalancesCancel = async () => {
	const { data } = await BonusService.setBonusBalancesCancel();

	if (data) {
		await getBonusBalances();
	}
};

import { instance } from '@lib/api';
import {
	AxiosResponsePromise,
	CommonResponse,
	Currencies,
	DeviceType,
	FilterRequestType,
	IGameCategory,
	IProvider,
	IProviderGame,
	ProviderGamesType
} from '@types';

type Games = (params: {
	limit: number;
	page: number;
	filter?: any;
	s?: any;
	sortByFavourite?: boolean;
}) => AxiosResponsePromise<CommonResponse<{ data: Array<IProviderGame> }>>;
// eslint-disable-next-line max-len
type GameCategories = (
	params: { limit: number; page: number; filter?: any } | URLSearchParams
) => AxiosResponsePromise<CommonResponse<{ data: Array<IGameCategory> }>>;

type GameCount = () => AxiosResponsePromise<number>;

type Providers = (params: {
	limit: number;
	page: number;
	filter?: FilterRequestType;
}) => AxiosResponsePromise<CommonResponse<{ data: Array<IProvider> }>>;
type InitGame = (params: {
	gameId: number;
	isDemo?: boolean;
	returnUrl?: string;
	locale?: string;
	deviceType?: DeviceType;
	currency?: Currencies;
}) => AxiosResponsePromise<{ url: string }>;

type SetFavoriteGames = (params: {
	gameId: number;
	providerGamesType?: ProviderGamesType;
}) => AxiosResponsePromise<any>;

// TODOвынести в отдельный модуль
interface IGameProviderService {
	requestGames: Games;
	initGame: InitGame;
	getGameCategories: GameCategories;
	getProviders: Providers;
	setFavoriteGames: SetFavoriteGames;
	deleteFavoriteGames: SetFavoriteGames;
	gameCount: GameCount;
}

enum GameProviderEndpoints {
	games = '/games',
	initGame = '/games/init',
	gameCategories = '/categories',
	gameCount = 'games/count/active',
	providers = '/providers'
}

export const GameProviderService: IGameProviderService = {
	// TODOвынести params выше
	requestGames: async (params) =>
		await instance.get(GameProviderEndpoints.games, { params }),
	initGame: async (params) => await instance.post(GameProviderEndpoints.initGame, params),
	gameCount: async () => await instance.get(GameProviderEndpoints.gameCount),
	getGameCategories: async (params) =>
		await instance.get(GameProviderEndpoints.gameCategories, { params }),
	getProviders: async (params) =>
		await instance.get(GameProviderEndpoints.providers, { params }),
	setFavoriteGames: async (params) =>
		await instance.post(`/games/${params?.gameId}/favourite`),
	deleteFavoriteGames: async (params) =>
		await instance.delete(`/games/${params?.gameId}/favourite`)
};

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={20}
		height={20}
		fill='none'
		{...props}
	>
		<path
			fill='#fff'
			fillRule='evenodd'
			d='M18.237 4.263a1.042 1.042 0 0 1 0 1.473l-10 10a1.042 1.042 0 0 1-1.474 0l-5-5a1.042 1.042 0 1 1 1.474-1.473L7.5 13.527l9.264-9.264a1.042 1.042 0 0 1 1.473 0Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

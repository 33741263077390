import React, { useEffect, useState } from 'react';
import { useLocales } from '@lib/hooks';
import s from './style.module.scss';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { userSelectors } from '@store/reducers/user/selectors';
import { getCountries } from 'react-phone-number-input';
import { CountrySelect } from '../country-select';
import { getPhoneNumberByRegion } from '@utils/phoneNumberUtil';
import { CountryCode } from 'libphonenumber-js';

type PropsType = {
	onNumberChange?: (country: string) => void;
	setCountryCode?: (country: string) => void;
	error?: boolean;
	onFocus?: () => void;
	onBlur?: (isValid: boolean) => void;
	initValue?: string;
	isDisabled?: boolean;
};

export const PhoneInput = (props: PropsType) => {
	const { onNumberChange, onFocus, initValue, setCountryCode, error, isDisabled } = props;

	const { locationData } = useSelector(userSelectors.userInfo);

	const { maskedNumberWithoutCode } = getPhoneNumberByRegion(
		locationData?.countryCode || getCountries()[0]
	);

	const [phonePlaceholderByCode, setPhonePlaceholderByCode] = useState(
		maskedNumberWithoutCode
	);

	const [selectedItem, setSelectedItem] = useState(
		locationData?.countryCode || getCountries()[0]
	);

	const [phoneNumber, setPhoneNumber] = useState<any>('');
	const [isValid, setIsValid] = useState<boolean>(true);
	const [firstInit, setIsFirstInit] = useState<boolean>(true);

	const handleCountryChange = (index: string) => {
		setPhonePlaceholderByCode(getPhoneNumberByRegion(index).maskedNumberWithoutCode);
		setSelectedItem(index);
		setPhoneNumber('');
		setCountryCode && setCountryCode(index);
	};

	const handlePhoneNumberChange = (value: string) => {
		const formattedValue = value.replace(/[^0-9]/g, '');

		setPhoneNumber(formattedValue);
	};

	const { localizeText } = useLocales({
		path: 'components/common/modals',
		node: 'AuthModal'
	});

	useEffect(() => {
		const countries = getCountries();

		if (locationData) {
			if (locationData) {
				setSelectedItem((locationData?.countryCode as CountryCode) || countries[0]);
			} else {
				setSelectedItem(countries[0]);
			}
		} else {
			setSelectedItem(countries[0]);
		}
	}, []);

	useEffect(() => {
		onNumberChange && onNumberChange(phoneNumber);
		setIsValid(true);
	}, [phoneNumber]);

	useEffect(() => {
		if (firstInit && initValue) {
			setPhoneNumber(initValue);
		}

		setIsFirstInit(false);
	}, [initValue]);

	// Функция для получения маски для выбранной страны

	return (
		<>
			<div
				className={cn(s.phoneInput, {
					[s.error]: error || (phoneNumber && !isValid)
				})}
			>
				<div className={s.phoneInputTitle}>{localizeText('phone')}</div>
				<CountrySelect
					selectedIndex={selectedItem}
					className={s.phoneInputCountrySelect}
					onChange={(index) => {
						handleCountryChange(index);
					}}
					isDisabled={isDisabled}
				/>
				<input
					disabled={isDisabled}
					type='tel'
					name='phone'
					placeholder={phonePlaceholderByCode}
					className={s.phoneInputText}
					value={phoneNumber}
					onChange={(e) => handlePhoneNumberChange(e.target.value)}
					onFocus={onFocus}
				/>
				<div className={s.phoneInputSeparator}></div>
			</div>
		</>
	);
};

import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

export const getPhoneNumberByRegion = (
	regionCode: string
): {
	maskedNumberWithoutCode?: string;
	maskedNumberWithCode?: string;
	nationalPrefix?: number;
} => {
	const phoneUtil = PhoneNumberUtil.getInstance();
	let exampleNumber: string | undefined;

	try {
		const number = phoneUtil.getExampleNumberForType(regionCode, 1);

		exampleNumber = phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);

		const nationalPrefix = phoneUtil.getCountryCodeForRegion(regionCode);

		const numberWithoutCode = exampleNumber.replace(`+${nationalPrefix} `, '');

		const maskedNumberWithoutCode = numberWithoutCode.replace(/[0-9]/g, 'X');
		const maskedNumberWithCode = `+${nationalPrefix} ${maskedNumberWithoutCode}`;

		return { maskedNumberWithoutCode, maskedNumberWithCode, nationalPrefix };
	} catch (error: any) {
		throw new Error(`Не удалось получить номер для региона ${regionCode}: ${error}`);
	}
};

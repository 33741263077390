import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			fill={props.fill || '#A0A4AD'}
			d='M6.476 12.931c-.374-.188-.834-.04-.981.352a6.949 6.949 0 1 0 7.07-4.483c-.418-.034-.749.319-.737.737.012.419.362.743.778.79a5.432 5.432 0 1 1-5.748 3.644c.135-.396-.009-.85-.382-1.04ZM18.219 10.347c.052.408.427.701.821.586a4.717 4.717 0 1 0-5.896-3.36c.102.398.546.571.923.408.377-.163.541-.6.469-1.005a3.229 3.229 0 1 1 4.309 2.455c-.385.144-.678.509-.626.916Z'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M3.696 5.924a.75.75 0 0 1 .963.444l.33.892a.75.75 0 0 1 .031.406l-1.06 5.328a.75.75 0 0 1 .419 1.437l-1.158.416a.75.75 0 0 1-.989-.852L3.508 7.58l-.256-.694a.75.75 0 0 1 .444-.963ZM5.362 5.31a.75.75 0 0 0-.444.963l.329.892a.75.75 0 0 0 .239.33l4.248 3.353-2.052.756a.75.75 0 0 0 .519 1.408l3.307-1.22a.75.75 0 0 0 .206-1.292L6.58 6.448l-.256-.694a.75.75 0 0 0-.963-.444ZM12.649 13.095a.75.75 0 0 1-.415.976l-2.242.906.53 2.992h1.431a.75.75 0 0 1 0 1.5h-2.06a.75.75 0 0 1-.738-.619l-.746-4.21a.75.75 0 0 1 .457-.825l2.806-1.135a.75.75 0 0 1 .977.415Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M11.305 13.095a.75.75 0 0 0 .414.976l2.242.906-.53 2.992H12a.75.75 0 0 0 0 1.5h2.06a.75.75 0 0 0 .738-.619l.746-4.21a.75.75 0 0 0-.457-.825l-2.806-1.135a.75.75 0 0 0-.976.415Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M17.991 12.968a.75.75 0 0 1-.316 1.012l-2.508 1.314a.75.75 0 1 1-.696-1.329l2.508-1.313a.75.75 0 0 1 1.012.316ZM13.45 18.094a.75.75 0 0 1 1.06-.02l1.656 1.595a.75.75 0 1 1-1.04 1.08l-1.656-1.594a.75.75 0 0 1-.02-1.06ZM10.529 18.025c.3.286.311.76.025 1.06l-1.873 1.966a.75.75 0 1 1-1.086-1.034l1.873-1.966a.75.75 0 0 1 1.06-.026Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			d='M6.118 4.909A1.794 1.794 0 1 0 2.751 6.15l3.367-1.241Z'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='M17.148 4.33a.75.75 0 0 1 .856-.626c.552.085 2.031.561 2.32 2.191a.75.75 0 0 1-1.476.263c-.118-.662-.734-.92-1.073-.972a.75.75 0 0 1-.627-.856Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={20}
		height={20}
		fill='none'
		{...props}
	>
		<path
			fill='#fff'
			d='M8.334 6.458c0-.46.373-.833.833-.833h5a.833.833 0 0 1 0 1.667h-5a.833.833 0 0 1-.833-.834ZM8.334 10c0-.46.373-.834.833-.834h5a.833.833 0 0 1 0 1.667h-5A.833.833 0 0 1 8.334 10ZM8.334 13.541c0-.46.373-.833.833-.833h5a.833.833 0 0 1 0 1.667h-5a.833.833 0 0 1-.833-.834ZM6.042 5.479a.98.98 0 0 0 0 1.958h.008a.98.98 0 0 0 0-1.958h-.008ZM6.042 9.02a.98.98 0 0 0 0 1.959h.008a.98.98 0 0 0 0-1.958h-.008ZM6.042 12.562a.98.98 0 0 0 0 1.959h.008a.98.98 0 0 0 0-1.959h-.008Z'
		/>
		<path
			fill='#fff'
			fillRule='evenodd'
			d='M1.667 6.667a5 5 0 0 1 5-5h6.667a5 5 0 0 1 5 5v6.666a5 5 0 0 1-5 5H6.667a5 5 0 0 1-5-5V6.666Zm5-3.334a3.333 3.333 0 0 0-3.333 3.333v6.667a3.333 3.333 0 0 0 3.333 3.333h6.667a3.333 3.333 0 0 0 3.333-3.333V6.666a3.333 3.333 0 0 0-3.333-3.333H6.667Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

export enum Languages {
	ru = 'ru',
	bn = 'bn',
	en = 'en',
	pt = 'pt',
	hi = 'hi'
}

export enum CountryCodes {
	IN = 'IN'
}

export enum LanguagesTopXGo {
	en = 'en',
	pt = 'pt'
}

export enum CurrencyView {
	icon = 'icon',
	short = 'short',
	full = 'full',
	abbr = 'abbr'
}

export type LocalizeText = (key: string, values?: Record<string, unknown>) => string;

export type UseLocales = (opts: {
	path: string;
	node: string;
	useSuspense?: boolean;
}) => {
	localizeText: LocalizeText;
};

import { GameProviderService } from './GameService';
import { setCurrentInitedGame } from '@store/reducers/game-provider/dispatchers';
import { Currencies, DeviceType } from '@types';
import { simplifyNameIntoRoute } from '@utils';

export const initGame = async (params: {
	gameName: string;
	gameId: number;
	isDemo?: boolean;
	isFavorite?: boolean;
	locale: string;
	deviceType: DeviceType;
	currency: Currencies;
	isFreezeBalancePlay?: boolean;
}) => {
	const res = await GameProviderService.initGame({
		gameId: params.gameId,
		isDemo: params?.isDemo,
		locale: params?.locale,
		deviceType: params?.deviceType,
		currency: params?.currency
	});

	if (res.status === 201)
		setCurrentInitedGame({
			gameId: params?.gameId,
			name: params.gameName,
			iframeSrc: res.data?.url,
			isFavorite: params?.isFavorite,
			clientRoute: `/games/provider/${simplifyNameIntoRoute(params.gameName)}`,
			isFreezeBalancePlay: params?.isFreezeBalancePlay
		});
};

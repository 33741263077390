import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={20}
		height={20}
		fill='none'
		{...props}
	>
		<path
			fill='#6D7380'
			fillRule='evenodd'
			d='M6.97 16.108H3.46c-1.695 0-2.415-2.159-1.059-3.176a2.292 2.292 0 0 0 .917-1.834V8.056C3.317 4.438 6.24.917 10 .917s6.683 3.521 6.683 7.139v3.042c0 .722.34 1.401.916 1.834 1.357 1.017.637 3.176-1.058 3.176h-3.515a3.032 3.032 0 1 1-6.055 0ZM4.818 8.056c0-2.935 2.39-5.639 5.183-5.639 2.793 0 5.182 2.704 5.182 5.639v3.042c0 1.194.563 2.318 1.517 3.034a.264.264 0 0 1-.158.476H3.459a.264.264 0 0 1-.159-.476 3.792 3.792 0 0 0 1.517-3.034V8.056Zm3.658 8.052a1.532 1.532 0 1 0 3.046 0H8.476Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

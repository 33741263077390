import * as React from 'react';
import { SVGProps } from 'react';

export const SearchSvg = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={20}
		height={20}
		viewBox='0 0 20 20'
		fill='none'
		{...props}
	>
		<path
			fill='#A0A4AD'
			fillRule='evenodd'
			d='M0 8.8a8.8 8.8 0 1 1 15.69 5.475l4.017 4.018a1 1 0 0 1-1.414 1.414l-4.018-4.018A8.8 8.8 0 0 1 0 8.8ZM8.8 2a6.8 6.8 0 1 0 0 13.6A6.8 6.8 0 0 0 8.8 2Z'
			clipRule='evenodd'
		/>
	</svg>
);

import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={20}
		height={20}
		viewBox='0 0 20 20'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<rect
			width='20'
			height='20'
			rx='10'
			fill='url(#paint0_linear_1461_189728)'
		/>
		<path
			d='M11.0898 5.43945V14H9.67773V7.11523L7.58594 7.82422V6.6582L10.9199 5.43945H11.0898Z'
			fill='#172032'
		/>
		<defs>
			<linearGradient
				id='paint0_linear_1461_189728'
				x1='10'
				y1='0'
				x2='10'
				y2='20'
				gradientUnits='userSpaceOnUse'
			>
				<stop stopColor='white' />
				<stop
					offset='1'
					stopColor='#CBCBCB'
				/>
			</linearGradient>
		</defs>
	</svg>
);

export default SvgComponent;

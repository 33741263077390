import React from 'react';
import { Toast } from 'react-hot-toast';
import { useLocales } from '@lib/hooks';
import { NotificattionContainer } from '../NotificattionContainer';
import s from './style.module.scss';
import { SuccessPopUpIcon } from '@components/svg';

type PropsType = {
	title?: string;
	text?: string;
	translated?: boolean;
	t: Toast;
};

export const SuccessToast = (props: PropsType) => {
	const { title, t, translated, text } = props;

	const { localizeText } = useLocales({
		path: 'global',
		node: 'notifications'
	});

	return (
		<NotificattionContainer t={t}>
			<div className={s.container}>
				<SuccessPopUpIcon className={s.icon} />

				<div className={s.titleBlock}>
					<h3 className={s.textTitle}>{title ? title : localizeText('success')}</h3>

					<p className={s.text}>{translated ? text : localizeText(text || '')}</p>
				</div>
			</div>
		</NotificattionContainer>
	);
};

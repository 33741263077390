import React, { CSSProperties } from 'react';
import s from './style.module.scss';
import cn from 'classnames';

type PreloaderP = {
	className?: string;
	isWhite?: boolean;
	styles?: CSSProperties;
};

export const Preloader = (props: PreloaderP) => {
	const { className = '', isWhite = false, styles } = props;

	return (
		<span
			style={styles}
			className={cn(s.preloader, { [s.preloaderWhite]: isWhite }, className)}
		/>
	);
};

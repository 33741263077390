interface IGlobalConfig {
	isProd: boolean;
}

export const globalConfig: IGlobalConfig = {
	isProd: process.env.NODE_ENV === 'production'
};

export const TelegramLink = 'https://t.me/official_TopX_support';
export const TelegramLinkBot = 'https://t.me/official_TopX_support_bot';

export const isTopXGo = process.env.REACT_APP_SPECIFIC_CONFIG === 'top-x-go';

export const topXGoFooterText =
	'top-x-go.com is operated by Topxgo N.V. with a registered address Hanchi Snoa 19 Trias Building and registration number 165952, which is the Holder of E-gaming License No 8048/JAZ2024-008 issued by Antillephone N.V.';

export const isTapX = process.env.REACT_APP_SPECIFIC_CONFIG === 'tapxgames';

export const APP_TITLE = isTapX ? 'TapX' : isTopXGo ? 'TopX' : 'TopX';
export const APP_EMAIL = isTapX
	? 'info@tapxgames.net'
	: isTopXGo
		? 'info@top-x-go.com'
		: '';

export const APP_COMPANY = isTapX ? '1WIN' : isTopXGo ? 'Topxgo' : 'Topxgo';

export const APP_PROD_LINK = isTapX
	? 'https://tapxgames.net/'
	: isTopXGo
		? 'https://top-x-go.com/'
		: 'https://topx.one/';
export const APP_PROD_LINK_SHORT = isTapX
	? 'tapxgames.net'
	: isTopXGo
		? 'top-x-go.com'
		: 'topx.one';

export const FINGERPRINT_API_KEY = 'bFbPundTYsbQT5OHutGU';
export const FINGERPRINT_ENDPOINT = 'https://metrics.topx.one';
export const FINGERPRINT_SCRIPT_URL_PATTERN =
	'https://metrics.topx.one/web/v<version>/<apiKey>/loader_v<loaderVersion>.js';

export const DEFAULT_LANG = 'en';
export const SUPPORTED_LANGS = ['en', 'bn', 'pt', 'ru', 'hi'];

export const EMAIL_ENDS = ['@gmail.com', '@yahoo.com', '@outlook.com', '@icloud.com'];

export const ANDROID_APP_LINK = process.env.REACT_APP_ANDROID_APP_LINK;

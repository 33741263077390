import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={132}
		height={48}
		fill='none'
		{...props}
	>
		<g filter='url(#bgaming-a)'>
			<rect
				width={105}
				height={21.223}
				x={13.5}
				y={11}
				fill='url(#bgaming-b)'
				rx={7.73}
				shapeRendering='crispEdges'
			/>
			<rect
				width={103.712}
				height={19.934}
				x={14.144}
				y={11.644}
				stroke='#fff'
				strokeOpacity={0.24}
				strokeWidth={1.288}
				rx={7.086}
				shapeRendering='crispEdges'
			/>
			<g clipPath='url(#bgaming-c)'>
				<path
					fill='#FFCC29'
					d='M45.653 13.577h-.679v15.91h.68v-15.91ZM58.894 13.577h-.747v15.91h.747v-15.91ZM111.13 29.51h.747V13.577h-.747V29.51ZM98.615 13.577h-.747v15.91h.747v-15.91ZM72.134 13.577h-.724v15.91h.724v-15.91ZM85.374 13.577h-.747v15.91h.747v-15.91Z'
				/>
				<path
					fill='#000'
					d='M51.22 16.248 47.94 26.84h1.516l.77-2.67h3.395l.77 2.67h1.493l-3.236-10.592H51.22Zm-.678 6.767 1.335-4.617h.045l1.313 4.617h-2.693ZM38.93 22.585v-1.132h3.283V25.3a3.378 3.378 0 0 1-1.177 1.154c-.634.385-1.358.52-2.083.52-.995 0-1.991-.317-2.693-1.018-.679-.657-1.018-1.652-1.018-2.897v-3.1c0-1.223.34-2.174.973-2.83.656-.702 1.584-1.041 2.557-1.041.928 0 1.811.204 2.49.837.634.589.928 1.426.95 2.286v.046h-1.425a2.176 2.176 0 0 0-.52-1.517c-.363-.385-.906-.52-1.472-.52-.588 0-1.154.248-1.516.724-.385.475-.544 1.131-.544 2.037v3.168c0 .906.204 1.585.612 2.06.407.475.995.724 1.63.724.407 0 .837-.068 1.176-.271.272-.159.453-.408.589-.68v-2.398H38.93ZM67.403 16.248h1.947V26.84h-1.494v-4.074l.136-4.074h-.046l-2.308 8.148h-.996l-2.309-8.148h-.022l.113 4.074v4.074h-1.471V16.248h1.9l2.287 8.374 2.263-8.374ZM93.657 16.248h1.472V26.84h-1.472l-4.006-7.877h-.067v7.877h-1.472V16.248h1.472l4.005 7.876.068-.023v-7.853ZM75.868 16.248h5.002v1.154h-1.765v8.26h1.765v1.178h-5.002v-1.2h1.766v-8.26h-1.766v-1.132Z'
				/>
				<path
					fill='#FFCC29'
					d='M20.62 29.51h11.136a.667.667 0 0 0 .68-.679V14.256a.667.667 0 0 0-.68-.68H20.621a.667.667 0 0 0-.68.68v14.553c0 .384.318.701.68.701Z'
				/>
				<path
					fill='#000'
					d='M29.538 22.2c-.271-.453-.724-.747-1.244-.86.43-.181.792-.475 1.018-.883.226-.407.34-.882.34-1.335 0-.86-.272-1.652-.928-2.15-.657-.498-1.426-.724-2.332-.724H23.02v10.614h3.802c.815 0 1.63-.271 2.241-.814.588-.498.883-1.29.883-2.309 0-.611-.136-1.086-.408-1.539Zm-4.549-4.3h1.041c.453 0 .77.09 1.041.362.272.271.362.679.34 1.064 0 .362-.068.747-.34 1.018a1.26 1.26 0 0 1-.95.385h-1.154v-2.83h.022Zm2.4 6.948c-.25.272-.657.34-1.02.34h-1.357v-3.033h1.516c.385 0 .747.113.95.407.227.34.272.747.272 1.154-.022.408-.09.838-.362 1.132ZM105.088 22.585v-1.132h3.281v3.848a3.377 3.377 0 0 1-1.176 1.154c-.634.385-1.358.52-2.083.52-.996 0-1.991-.316-2.693-1.018-.679-.656-1.019-1.652-1.019-2.897v-3.123c0-1.223.34-2.173.974-2.83.656-.701 1.584-1.04 2.557-1.04.928 0 1.811.203 2.49.837.634.588.928 1.426.95 2.286v.045h-1.425a2.18 2.18 0 0 0-.521-1.516c-.362-.385-.905-.52-1.471-.52-.589 0-1.154.248-1.517.723-.384.476-.543 1.132-.543 2.037v3.169c0 .905.204 1.584.611 2.06.408.475.996.724 1.63.724.407 0 .837-.068 1.177-.272.271-.158.452-.407.588-.679v-2.376h-1.81Z'
				/>
			</g>
		</g>
		<defs>
			<linearGradient
				id='bgaming-b'
				x1={40.907}
				x2={50.502}
				y1={11}
				y2={25.765}
				gradientUnits='userSpaceOnUse'
			>
				<stop stopColor='#FFFCEF' />
				<stop
					offset={1}
					stopColor='#E6E6E6'
				/>
			</linearGradient>
			<clipPath id='bgaming-c'>
				<path
					fill='#fff'
					d='M19.942 13.577h92.117v16.07H19.942z'
				/>
			</clipPath>
			<filter
				id='bgaming-a'
				width={130.767}
				height={46.989}
				x={0.617}
				y={0.693}
				colorInterpolationFilters='sRGB'
				filterUnits='userSpaceOnUse'
			>
				<feFlood
					floodOpacity={0}
					result='BackgroundImageFix'
				/>
				<feColorMatrix
					in='SourceAlpha'
					result='hardAlpha'
					values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
				/>
				<feOffset dy={2.577} />
				<feGaussianBlur stdDeviation={6.442} />
				<feComposite
					in2='hardAlpha'
					operator='out'
				/>
				<feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.29 0' />
				<feBlend
					in2='BackgroundImageFix'
					result='effect1_dropShadow_3881_88653'
				/>
				<feBlend
					in='SourceGraphic'
					in2='effect1_dropShadow_3881_88653'
					result='shape'
				/>
			</filter>
		</defs>
	</svg>
);

export default SvgComponent;

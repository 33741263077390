import cn from 'classnames';
import s from './style.module.scss';
import React from 'react';

type PropsTypes = {
	isTouched: boolean;
	error: string | string[] | undefined;
	className?: string;
};

export default function FormError(props: PropsTypes) {
	const { className, error, isTouched } = props;

	return isTouched && !!error ? (
		<span className={cn(className, s.error)}>{error}</span>
	) : (
		<></>
	);
}

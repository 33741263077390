import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={20}
		height={20}
		fill='none'
		{...props}
	>
		<path
			fill='#F0F0F0'
			d='M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10Z'
		/>
		<path
			fill='#FF9811'
			d='M10 0A10 10 0 0 0 .992 5.652h18.016A10 10 0 0 0 10 0Z'
		/>
		<path
			fill='#6DA544'
			d='M10 20a10 10 0 0 0 9.008-5.652H.992A10 10 0 0 0 10 20Z'
		/>
		<path
			fill='#0052B4'
			d='M10 13.479a3.478 3.478 0 1 0 0-6.957 3.478 3.478 0 0 0 0 6.957Z'
		/>
		<path
			fill='#F0F0F0'
			d='M10 12.174a2.174 2.174 0 1 0 0-4.348 2.174 2.174 0 0 0 0 4.348Z'
		/>
		<path
			fill='#0052B4'
			d='m10 7.317.67 1.521 1.653-.18L11.341 10l.982 1.341-1.652-.18L10 12.683l-.67-1.52-1.653.18L8.659 10l-.982-1.341 1.652.18L10 7.316Z'
		/>
	</svg>
);

export default SvgComponent;

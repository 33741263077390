import * as React from 'react';
import { SVGProps } from 'react';

export const TelegramNewSvg = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={21}
		height={20}
		fill='none'
		viewBox='0 0 21 20'
		{...props}
	>
		<path
			fill='#C8DAEA'
			d='M8.752 15.582c-.447 0-.37-.18-.525-.63l-1.314-4.584 10.115-6.36'
		/>
		<path
			fill='#A9C9DD'
			d='M8.754 15.582c.345 0 .497-.167.69-.365l1.839-1.896-2.295-1.466'
		/>
		<path
			fill='inherit'
			d='m8.987 11.854 5.56 4.354c.633.37 1.092.178 1.25-.625l2.262-11.3c.232-.984-.354-1.431-.96-1.14L3.81 8.574c-.907.385-.901.922-.165 1.161l3.41 1.128 7.894-5.278c.372-.24.715-.111.434.153'
		/>
	</svg>
);

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={20}
		height={20}
		fill='none'
		{...props}
	>
		<path
			fill='#F0F0F0'
			d='M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10Z'
		/>
		<path
			fill='#0052B4'
			d='M2.067 3.912a9.963 9.963 0 0 0-1.723 3.48h5.203l-3.48-3.48ZM19.655 7.392a9.964 9.964 0 0 0-1.722-3.48l-3.48 3.48h5.203ZM.344 12.609a9.965 9.965 0 0 0 1.723 3.48l3.48-3.48H.344ZM16.088 2.067a9.964 9.964 0 0 0-3.48-1.722v5.202l3.48-3.48ZM3.912 17.933a9.964 9.964 0 0 0 3.48 1.723v-5.202l-3.48 3.48ZM7.391.345a9.965 9.965 0 0 0-3.48 1.722l3.48 3.48V.345ZM12.609 19.656a9.964 9.964 0 0 0 3.48-1.723l-3.48-3.48v5.203ZM14.453 12.609l3.48 3.48a9.963 9.963 0 0 0 1.723-3.48h-5.203Z'
		/>
		<path
			fill='#D80027'
			d='M19.915 8.696h-8.61V.085a10.1 10.1 0 0 0-2.61 0v8.61H.086a10.1 10.1 0 0 0 0 2.61h8.61v8.61a10.114 10.114 0 0 0 2.61 0v-8.61h8.61a10.115 10.115 0 0 0 0-2.61Z'
		/>
		<path
			fill='#D80027'
			d='m12.609 12.609 4.462 4.462c.205-.205.401-.42.588-.642l-3.82-3.82h-1.23ZM7.391 12.609 2.93 17.07c.205.205.42.401.642.588l3.82-3.82v-1.23ZM7.391 7.391 2.93 2.93c-.205.205-.401.42-.588.642l3.82 3.82h1.23ZM12.609 7.391 17.07 2.93c-.205-.205-.42-.4-.642-.588l-3.82 3.82v1.23Z'
		/>
	</svg>
);

export default SvgComponent;

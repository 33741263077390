import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';

import { metaConfig } from '@config/meta';
import { instance } from '@lib/api';

export enum AntiSpamModules {
	promocode = 'promocode'
}

interface ILocalStorageAntiSpamModule {
	iterationsCount: number;
	unblockingDate?: number;
}

type AntiSpamMonitor = (
	antiSpamModule: AntiSpamModules
) => Promise<{ isForbidden: boolean; remainedBlockedTime?: number }>;

export const antiSpamMonitor: AntiSpamMonitor = async (antiSpamModule) => {
	const { antiSpamMonitorConditions } = metaConfig;
	const currentConditions = antiSpamMonitorConditions[antiSpamModule];
	const module = localStorage.getItem(antiSpamModule);
	const { data: currentTime } = await instance.get('/date');

	if (isNull(module)) {
		const newModule: ILocalStorageAntiSpamModule = {
			iterationsCount: 1
		};

		localStorage.setItem(antiSpamModule, JSON.stringify(newModule));

		return { isForbidden: false };
	}

	const currentModule: ILocalStorageAntiSpamModule = JSON.parse(module);
	const currentUnblockingDate = currentModule.unblockingDate; // Date in s

	currentModule.iterationsCount = currentModule.iterationsCount + 1;

	// бан по времени уже был
	if (!isUndefined(currentUnblockingDate)) {
		const remainedBlockedTime = currentUnblockingDate - currentTime;

		// бан по времени действует сейчас
		if (remainedBlockedTime > 0) {
			return {
				isForbidden: true,
				remainedBlockedTime
			};
		}

		delete currentModule.unblockingDate;

		localStorage.setItem(antiSpamModule, JSON.stringify(currentModule));

		return { isForbidden: false };
	}

	const newForbiddenTime = currentConditions.find(
		(condition) => condition.iterationsCount === currentModule.iterationsCount
	)?.forbiddenTime;

	if (!isUndefined(newForbiddenTime)) {
		currentModule.unblockingDate = currentTime + newForbiddenTime;

		localStorage.setItem(antiSpamModule, JSON.stringify(currentModule));

		return {
			isForbidden: isUndefined(currentModule.unblockingDate)
				? false
				: currentModule.unblockingDate > 0,
			remainedBlockedTime: newForbiddenTime
		};
	}

	delete currentModule.unblockingDate;

	localStorage.setItem(antiSpamModule, JSON.stringify(currentModule));

	return { isForbidden: false };
};

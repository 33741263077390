import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={8}
		height={8}
		fill='none'
		{...props}
	>
		<path
			fill='gold'
			d='M4.535.896a.583.583 0 0 0-1.07 0l-.669 1.542a.083.083 0 0 1-.068.05l-1.673.159a.583.583 0 0 0-.33 1.018l1.259 1.112c.023.02.033.051.026.08l-.365 1.64a.583.583 0 0 0 .866.63l1.447-.854a.083.083 0 0 1 .084 0l1.447.854c.44.26.977-.131.866-.63l-.365-1.64a.083.083 0 0 1 .026-.08l1.26-1.112a.583.583 0 0 0-.331-1.018l-1.673-.16a.083.083 0 0 1-.068-.05L4.535.898Z'
		/>
	</svg>
);

export default SvgComponent;

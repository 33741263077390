import { setEditingSettings } from '@store/reducers/user/dispatchers';
import { UserService } from '@lib/api/user/UserService';
import { getCurrentUserData } from '@lib/api/user/getCurrentUserData';
import { Settings } from '@types';
import { setAuthToken } from '@lib/browser-storage';
import { setAuthType } from '@store/reducers/auth/dispathcers';
import { ErrorToast, SuccessToast } from '@components/common/global-settings';
import toast from 'react-hot-toast';

export const updateCurrentUserData = async (
	setting: Settings,
	value: string,
	successFunc?: (onlyLoader?: boolean) => void
) => {
	try {
		const { status, data } = await UserService.updateCurrentUserData(setting, value);

		if (data && data?.accessToken) {
			setAuthType(null);
			await setAuthToken(data.accessToken);
		}

		if (status === 200) {
			await getCurrentUserData();
			setEditingSettings({ setting, rule: 'remove' });

			successFunc && successFunc();

			!successFunc && toast((t) => SuccessToast({ t: t, text: 'changed_successfuly' }));
		}
	} catch (e: any) {
		if (e?.response?.data?.message === 'PHONE_IS_BUSY') {
			toast((t) => ErrorToast({ t: t, text: 'already_used_phone' }));
		} else if (e?.response?.data?.message === 'EMAIL_IS_BUSY') {
			toast((t) => ErrorToast({ t: t, text: 'already_used_email' }));
		} else if (e?.response?.data?.message) {
			if (e?.response?.data?.message?.length > 0) {
				const phoneError = e?.response?.data?.message?.find(
					(e: any) => e?.property === 'phone'
				);

				if (phoneError) {
					toast((t) => ErrorToast({ t: t, text: 'invalid_phone' }));
				}
			}
		} else {
			toast((t) => ErrorToast({ t: t, text: 'save_fail' }));
		}
	}
};

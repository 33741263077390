import React from 'react';
import { useLocales } from '@lib/hooks';
import { getImageByURL } from '@utils';
import s from './style.module.scss';

export const SearchNothingFind = () => {
	const { localizeText } = useLocales({
		path: 'components/common/elements',
		node: 'SearchNothingFind'
	});

	return (
		<div className={s.wrapper}>
			<img
				className={s.wrapperImage}
				src={getImageByURL('../../../../../assets/img/common/nothing-find-slot.webp')}
			/>

			<span className={s.wrapperText}>{localizeText('nothing_find')}</span>
		</div>
	);
};

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M19.25 4.684a1.75 1.75 0 0 0-2.355-1.642l-3.404 1.254a.75.75 0 1 0 .518 1.408l3.405-1.254a.25.25 0 0 1 .336.234v14.722a.25.25 0 0 1-.322.24l-3.713-1.114a.75.75 0 0 0-.43 1.436l3.712 1.114a1.75 1.75 0 0 0 2.253-1.676V4.684ZM5.25 4.684a1.75 1.75 0 0 1 2.355-1.642l3.404 1.254a.75.75 0 1 1-.518 1.408L7.086 4.45a.25.25 0 0 0-.336.234v14.722a.25.25 0 0 0 .322.24l3.712-1.114a.75.75 0 0 1 .431 1.436l-3.712 1.114a1.75 1.75 0 0 1-2.253-1.676V4.684Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M2.75 7A2.75 2.75 0 0 1 5.5 4.25h.25a.75.75 0 0 1 0 1.5H5.5c-.69 0-1.25.56-1.25 1.25v10.5c0 .69.56 1.25 1.25 1.25h.25a.75.75 0 0 1 0 1.5H5.5a2.75 2.75 0 0 1-2.75-2.75V7ZM21.5 7a2.75 2.75 0 0 0-2.75-2.75h-.25a.75.75 0 0 0 0 1.5h.25c.69 0 1.25.56 1.25 1.25v10.5c0 .69-.56 1.25-1.25 1.25h-.25a.75.75 0 0 0 0 1.5h.25a2.75 2.75 0 0 0 2.75-2.75V7Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='M12 7.25a.75.75 0 0 1 .75.75v7.5a.75.75 0 0 1-1.5 0V8a.75.75 0 0 1 .75-.75Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

import { bindActions } from '@utils';
import { actions } from './reducer';

export const {
	initPayment,
	setPaymentMethodType,
	setInvoiceValue,
	setInvoiceActiveCryptoCurrency,
	setWithdrawalValue,
	setWithdrawalCommission,
	setWithdrawalCryptoAddress,
	setWithdrawalActiveCryptoCurrency,
	setWithdrawalCommissionPending,
	clearPayment,
	setWithdrawalCardNumber,
	finishWithdrawal,
	finishStopWithdrawal,
	setPaymentProcessingStatus,
	initP2pInvoice,
	initCryptoInvoice,
	setIframePayment,
	setPaymentMethodsInvoice,
	setPaymentMethodsWithdraw,
	setSelectedPaymentMethodId,
	setUserConfirmedPayment,
	setQrPaymentInfo
} = bindActions(actions);

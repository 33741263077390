import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={16}
		height={16}
		{...props}
		viewBox='0 0 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.11872 3.88128C4.77701 3.53957 4.22299 3.53957 3.88128 3.88128C3.53957 4.22299 3.53957 4.77701 3.88128 5.11872L6.76256 8L3.88128 10.8813C3.53957 11.223 3.53957 11.777 3.88128 12.1187C4.22299 12.4604 4.77701 12.4604 5.11872 12.1187L8 9.23744L10.8813 12.1187C11.223 12.4604 11.777 12.4604 12.1187 12.1187C12.4604 11.777 12.4604 11.223 12.1187 10.8813L9.23744 8L12.1187 5.11872C12.4604 4.77701 12.4604 4.22299 12.1187 3.88128C11.777 3.53957 11.223 3.53957 10.8813 3.88128L8 6.76256L5.11872 3.88128Z'
			fill='#6D7380'
		/>
	</svg>
);

export default SvgComponent;

import { UserService } from '@lib/api/user/UserService';
import { cookies } from '@utils';
import { changeCurrencyTimezoneLocale, getUsersLevels } from '.';
import { Currencies } from '@types';
import { setActiveCurrency, setLocationData } from '@store/reducers/user/dispatchers';
import { getAllRMWBonus } from '../bonus';

export const getCurrentUserLocation = async (
	isTelegram?: boolean,
	isAuthed?: boolean
) => {
	const location = cookies?.getCookie('user_location_cookie');

	if (location) {
		setLocationData(JSON?.parse(location));

		if (!isAuthed) {
			await getAllRMWBonus(JSON?.parse(location)?.currency);
			await getUsersLevels(JSON?.parse(location)?.currency);

			setActiveCurrency(JSON?.parse(location)?.currency);
		}
	}

	if (!location) {
		const { status, data } = await UserService.getCurrentUserLocation();

		if (status === 200 || status === 304) {
			if (isTelegram) {
				cookies.setCookie('user_location_cookie', JSON.stringify(data), 10000);
				cookies.setCookie('user_telegram_cookie', 'true', 10000);

				const currency =
					data?.currency === Currencies.bdt || data?.currency === Currencies?.inr
						? data?.currency
						: Currencies.inr;

				changeCurrencyTimezoneLocale({ currency: currency, timezone: data?.timezone });

				setLocationData(data);
			} else {
				cookies.setCookie('user_location_cookie', JSON.stringify(data), 10);
				if (!isAuthed) {
					setActiveCurrency(data?.currency);
					await getAllRMWBonus(data?.currency);
					await getUsersLevels(data?.currency);
				}

				setLocationData(data);
			}
		}
	}
};

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M10.395 2.065a2.25 2.25 0 0 1 3.21 0l.946.963a.75.75 0 0 0 .66.214l1.326-.223a2.25 2.25 0 0 1 2.599 1.894l.106.729a.75.75 0 0 1-1.484.217l-.107-.73a.75.75 0 0 0-.866-.63l-1.326.222a2.25 2.25 0 0 1-1.979-.642l-.945-.963a.75.75 0 0 0-1.07 0l-.945.963a2.25 2.25 0 0 1-1.978.642L7.21 4.498a.75.75 0 0 0-.866.63l-.199 1.334a2.25 2.25 0 0 1-1.222 1.682l-1.208.602a.75.75 0 0 0-.33 1.018l.623 1.196a2.25 2.25 0 0 1 0 2.08l-.624 1.196a.75.75 0 0 0 .331 1.018l1.208.602a2.25 2.25 0 0 1 1.222 1.682l.199 1.335a.75.75 0 0 0 .866.63l1.33-.224a2.25 2.25 0 0 1 1.979.643l.945.962c.294.3.776.3 1.07 0l.945-.962a2.25 2.25 0 0 1 1.979-.643l1.33.223a.75.75 0 0 0 .866-.629l.199-1.335a2.25 2.25 0 0 1 1.222-1.682l1.208-.602a.75.75 0 0 0 .33-1.018l-.623-1.196a2.25 2.25 0 0 1 0-2.08l.628-1.205a.75.75 0 0 0-.326-1.016l-.632-.32a.75.75 0 0 1 .678-1.338l.632.32a2.25 2.25 0 0 1 .979 3.047l-.628 1.205a.75.75 0 0 0 0 .694l.623 1.196a2.25 2.25 0 0 1-.992 3.054l-1.208.602a.75.75 0 0 0-.408.56l-.198 1.335a2.25 2.25 0 0 1-2.598 1.887l-1.33-.223a.75.75 0 0 0-.66.214l-.945.963a2.25 2.25 0 0 1-3.211 0l-.946-.963a.75.75 0 0 0-.66-.214l-1.33.223a2.25 2.25 0 0 1-2.598-1.887l-.198-1.335a.75.75 0 0 0-.408-.56l-1.208-.602a2.25 2.25 0 0 1-.992-3.054l.624-1.196a.75.75 0 0 0 0-.694l-.624-1.196a2.25 2.25 0 0 1 .992-3.054L4.255 6.8a.75.75 0 0 0 .408-.56l.198-1.335A2.25 2.25 0 0 1 7.46 3.02l1.33.223a.75.75 0 0 0 .66-.214l.946-.963Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			d='M6.15 13.423a.15.15 0 0 1-.15-.15V10.15a.15.15 0 0 1 .15-.15h.728a.15.15 0 0 1 .116.054l1.503 1.799a.14.14 0 1 1-.248.09V10.15A.15.15 0 0 1 8.4 10h.825a.15.15 0 0 1 .15.15v3.123a.15.15 0 0 1-.15.15h-.729a.15.15 0 0 1-.115-.054l-1.503-1.8a.14.14 0 1 1 .248-.089v1.793a.15.15 0 0 1-.15.15H6.15ZM10.986 11.421a.15.15 0 0 1 .15-.15h1.226a.15.15 0 0 1 .15.15v.532a.15.15 0 0 1-.15.15h-1.226a.15.15 0 0 1-.15-.15v-.532Zm.078.982c0 .082.068.15.15.15h1.412a.15.15 0 0 1 .15.15v.57a.15.15 0 0 1-.15.15H10.08a.15.15 0 0 1-.15-.15V10.15a.15.15 0 0 1 .15-.15h2.482a.15.15 0 0 1 .15.15v.57a.15.15 0 0 1-.15.15h-1.348a.15.15 0 0 0-.15.15v1.383ZM14.147 13.423a.15.15 0 0 1-.143-.104l-.999-3.123a.15.15 0 0 1 .143-.196h.872a.15.15 0 0 1 .143.106l.793 2.59a.15.15 0 0 1-.143.194h-.187a.15.15 0 0 1-.143-.197l.841-2.59a.15.15 0 0 1 .143-.103h.84a.15.15 0 0 1 .145.107l.784 2.59a.15.15 0 0 1-.144.193h-.164a.15.15 0 0 1-.143-.196l.828-2.59a.15.15 0 0 1 .143-.104h.78a.15.15 0 0 1 .143.196l-1 3.123a.15.15 0 0 1-.142.104h-1.016a.15.15 0 0 1-.144-.107l-.634-2.102a.123.123 0 1 1 .234.002l-.669 2.102a.15.15 0 0 1-.143.105h-1.018Z'
		/>
	</svg>
);

export default SvgComponent;

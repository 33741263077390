import { BonusService } from '@lib/api/bonus/BonusService';
import { setSocialBonusTask } from '@store/reducers/bonus/dispatchers';
import { SocialBonusTaskType } from '@types';
import { completeSocialBonusTask } from '.';
import { ErrorToast, SuccessToast } from '@components/common/global-settings';
import toast from 'react-hot-toast';

export const getSocialBonusTasks = async (
	checkData?: boolean,
	checkType?: SocialBonusTaskType.telegram_ref_bot,
	successFunc?: () => void
) => {
	const { status, data } = await BonusService.getSocialBonusTasks();

	if (status === 200) {
		if (checkData) {
			const findedtask = data.find((e) => e?.task.type === checkType);

			if (findedtask) {
				if (findedtask.isEligible) {
					completeSocialBonusTask(findedtask?.task.id);
					toast((t) => SuccessToast({ t: t, text: 'telegram_bonus_success' }));

					successFunc && successFunc();
				} else {
					toast((t) => ErrorToast({ t: t, text: 'telegram_bonus_error' }));
				}
			}
		}

		setSocialBonusTask(data);
	}
};

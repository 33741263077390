import {
	AxiosResponsePromise,
	Currencies,
	IUserInitialState,
	Settings,
	Socials
} from '@types';
import { instance } from '@lib/api';

type CheckUserDataAvailability = (
	setting: Settings,
	value: string
) => AxiosResponsePromise<{ status: boolean }>;
type GetCurrentUserData = () => AxiosResponsePromise<
	Omit<IUserInitialState, 'editingSettings' | 'updMeta' | 'socialsLinked'>
>;

type GetCurrentUserLocation = () => AxiosResponsePromise<any>;
// TODOубрать any
type UpdateCurrentUserData = (
	setting: Settings,
	value: string
) => AxiosResponsePromise<any>;
type UpdateCurrentUserImage = (formData: FormData) => AxiosResponsePromise<void>;
// TODOубрать any
type GetCurrentMediaLinkedUser = (social: Socials) => AxiosResponsePromise<any>;
// TODOубрать any
type ConfirmEmail = () => AxiosResponsePromise<any>;
type ConfirmPhone = () => AxiosResponsePromise<any>;
type ChangeCurrencyTimezoneLocale = (params: {
	currency?: Currencies;
	timezone?: string;
	locale?: string;
}) => AxiosResponsePromise<any>;
// TODOубрать any
type GetAlternativeBalance = () => AxiosResponsePromise<any>;
type getUsersLevels = (currency: Currencies) => AxiosResponsePromise<any>;

type EmailConfirmCode = (token: string) => AxiosResponsePromise<any>;

type ConfirmPhoneCode = (token: string) => AxiosResponsePromise<any>;

type ChangePassword = (data: {
	newPassword: string;
	oldPassword: string;
}) => AxiosResponsePromise<any>;

interface IUserService {
	checkUserDataAvailability: CheckUserDataAvailability;
	getCurrentUserData: GetCurrentUserData;
	getCurrentUserLocation: GetCurrentUserLocation;
	updateCurrentUserData: UpdateCurrentUserData;
	updateCurrentUserImage: UpdateCurrentUserImage;
	getCurrentMediaLinkedUser: GetCurrentMediaLinkedUser;
	confirmEmail: ConfirmEmail;
	confirmPhone: ConfirmPhone;
	changeCurrencyTimezoneLocale: ChangeCurrencyTimezoneLocale;
	getAlternativeBalance: GetAlternativeBalance;
	getUsersLevels: getUsersLevels;
	confirmEmailCode: EmailConfirmCode;
	confirmPhoneCode: ConfirmPhoneCode;

	changePassword: ChangePassword;
}

enum UserEndpoints {
	userDataAvailability = '/user/check',
	currentUserData = '/user/data',
	updateCurrentUserData = '/user',
	updateCurrentUserImage = '/user/image',
	getCurrentMediaLinkedUser = '/auth/social-networks',
	confirmEmail = '/auth/verify',
	confirmPhone = '/auth/verify-phone',
	changeCurrencyTimezoneLocale = '/user',
	getAlternativeBalance = '/user/balances',
	getCurrentUserLocation = '/user/location',

	getUsersLevels = '/user-level',

	confirmEmailCode = '/auth/confirm',
	confirmPhoneCode = '/auth/confirm-phone',

	changePassword = '/user/password'
}

export const UserService: IUserService = {
	checkUserDataAvailability: async (setting, value) =>
		await instance.get(`${UserEndpoints.userDataAvailability}-${setting}`, {
			params: { [setting]: value }
		}),
	getCurrentUserData: async () => await instance.get(UserEndpoints.currentUserData),
	updateCurrentUserData: async (setting, value) =>
		await instance.patch(UserEndpoints.updateCurrentUserData, { [setting]: value }),
	updateCurrentUserImage: async (formData) =>
		await instance.patch(UserEndpoints.updateCurrentUserImage, formData),
	getCurrentMediaLinkedUser: async (social) =>
		await instance.get(`${UserEndpoints.getCurrentMediaLinkedUser}/${social}`),

	confirmEmail: async () => await instance.post(UserEndpoints.confirmEmail),

	confirmEmailCode: async (token) =>
		await instance.post(UserEndpoints.confirmEmailCode, { token }),

	confirmPhone: async () => await instance.post(UserEndpoints.confirmPhone),
	confirmPhoneCode: async (token) =>
		await instance.post(UserEndpoints.confirmPhoneCode, { token }),

	changeCurrencyTimezoneLocale: async	 (params: {
		currency?: Currencies;
		timezone?: string;
		locale?: string;
	}) => await instance.patch(UserEndpoints.changeCurrencyTimezoneLocale, params),
	getAlternativeBalance: async () =>
		await instance.get(UserEndpoints.getAlternativeBalance),

	getCurrentUserLocation: async () =>
		await instance.get(UserEndpoints.getCurrentUserLocation),

	getUsersLevels: async (currency: Currencies) =>
		await instance.get(UserEndpoints.getUsersLevels, { params: { currency } }),

	changePassword: async (data: { newPassword: string; oldPassword: string }) =>
		await instance.patch(UserEndpoints.changePassword, data)
};

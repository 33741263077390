import * as React from 'react';
import { SVGProps } from 'react';

export const TopXHeaderLogo = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={95}
		height={40}
		viewBox='0 0 95 40'
		fill='none'
		{...props}
	>
		<path
			fill='red'
			d='M80.438 10.709S85.526 7.705 90.76 0c1.356 7.22 3.828 11.436 3.828 11.436l-2.714-.097C82.764 35.083 51.17 32.95 51.17 32.95v-.146c15.555-1.114 26.458-9.303 32.321-22.096h-3.053Z'
		/>
		<path
			fill='red'
			d='M86.807 32.74H76.153l-3.177-5.508 8.092-3.828 5.739 9.337ZM77.485 17.643c-1.738 2.533-3.576 4.634-5.45 6.378l-6.35-11.326h9.318l2.482 4.948Z'
		/>
		<path
			fill='#231F20'
			d='M81.841 24.663c-.256.4-1.022 1.522-2.374 2.908l-.004.002a25.592 25.592 0 0 1-4.27 3.503L74 29.01a29.616 29.616 0 0 0 .604-.255c.002 0 .005 0 .007-.003a15.207 15.207 0 0 0 .187-.08 33.625 33.625 0 0 0 1.1-.5 35.017 35.017 0 0 0 3.703-2.04l.002-.001a8.48 8.48 0 0 0 .154-.1c.09-.056.18-.114.27-.173l.03-.02.03-.02c.16-.105.32-.212.477-.32a25.781 25.781 0 0 0 .82-.58c.051-.036.101-.072.15-.11l.1-.074a9.193 9.193 0 0 0 .172-.127l.035.057Z'
			opacity={0.4}
		/>
		<path
			fill='#fff'
			d='m67.935 19.768-.265.567h.005l-1.117 2.395c-.644 1.382-2.63 2.502-4.433 2.502h-9.842l-2.48 5.32h-7.37l4.541-9.739c.123-.264.502-.477.845-.477H60.3l.835-1.787H48.029l1.987-4.26h14.847l3.072 5.479ZM29.588 14.29c-1.117 0-2.346.693-2.745 1.549l-1.263 2.71h12.748l-3.611 7.744h-5.373l2.777-5.956h-7.375l-4.29 9.199h.042c-.098.583.38 1.017 1.194 1.017h16.695c.814 0 1.698-.435 2.144-1.017h.042l.13-.276.007-.015 5.974-12.807c.552-1.184-.254-2.144-1.8-2.144H29.587v-.005ZM25.25 14.29H1.987L0 18.55h23.244l2.006-4.26ZM14.592 20.335H7.123L2.34 30.553h7.43l4.823-10.216Z'
		/>
	</svg>
);

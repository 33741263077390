import { pathsConfig } from '@config/paths';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export function useHideNavElements(): boolean {
	const { pathname } = useLocation();

	const [matches, setMatches] = useState<boolean>(false);

	const checkMatched = (p: string) => {
		if (p.includes('/games/provider')) {
			return true;
		}

		if (p.includes(pathsConfig?.payment.link)) {
			return true;
		}

		if (p.includes(pathsConfig?.pay_for_bugs.link)) {
			return true;
		}

		if (p.includes(pathsConfig?.telegram_bonus.link)) {
			return true;
		}

		if (p.includes(pathsConfig?.levels.link)) {
			return true;
		}

		if (p.includes(pathsConfig?.referral.link)) {
			return true;
		}

		if (p.includes(pathsConfig?.bonus.link)) {
			return true;
		}

		switch (p) {
			case pathsConfig?.providers.init.link:
				return true;
			case pathsConfig?.gameSearch.link:
				return true;
			case pathsConfig?.payment.link:
				return true;
			case pathsConfig?.pay_for_bugs.link:
				return true;
			case pathsConfig?.bonus.link:
				return true;
			case pathsConfig?.telegram_bonus.link:
				return true;
			case pathsConfig?.referral.link:
				return true;
			default:
				return false;
		}
	};

	useEffect(() => {
		if (matches) {
			setTimeout(() => setMatches(checkMatched(pathname)), 50);
		} else {
			setMatches(checkMatched(pathname));
		}
	}, [pathname]);

	return matches;
}

import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='m4.065 9.766.196 1.395a.5.5 0 1 1-.99.139l-.197-1.395a1 1 0 0 1 .851-1.13l3.506-.492c.593-.084 1.07.263 1.324.661s.365.958.128 1.46c-.65 1.383-1.226 3.422-1.034 5.875.053.687-.417 1.345-1.138 1.446-.676.095-1.358-.358-1.428-1.097-.122-1.295-.075-3.25.46-5.171l-.314.044a.5.5 0 0 1-.139-.99l1.099-.154a.5.5 0 0 1 .54.662c-.702 1.982-.78 4.149-.65 5.515.01.113.122.225.293.2.159-.022.295-.177.28-.377-.208-2.652.413-4.864 1.126-6.378.059-.124.048-.319-.066-.496-.112-.176-.248-.223-.341-.21l-3.506.493ZM16.074 13.065l-.196 1.395a.5.5 0 0 1-.99-.14l.195-1.394a1 1 0 0 1 1.13-.851l3.506.492c.592.083.955.548 1.09 1.001.134.452.086 1.021-.28 1.44-1.006 1.148-2.121 2.95-2.613 5.361-.138.675-.772 1.177-1.492 1.076-.676-.095-1.207-.718-1.07-1.448.239-1.279.823-3.145 1.867-4.844l-.314-.044a.5.5 0 1 1 .14-.99l1.098.154a.5.5 0 0 1 .338.786c-1.222 1.711-1.894 3.773-2.146 5.122-.021.112.055.25.226.274.159.022.333-.09.373-.286.532-2.607 1.738-4.562 2.841-5.82.09-.104.133-.294.073-.495-.06-.2-.177-.283-.27-.296l-3.506-.493Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='M8.5 3.25a.25.25 0 0 0-.25.25v1.72a.75.75 0 1 1-1.5 0V3.5c0-.966.784-1.75 1.75-1.75h7.841c.846 0 1.447.597 1.734 1.182.288.586.387 1.401-.03 2.077-1.839 2.975-4.244 8.346-4.664 13.097-.102 1.152-1.042 2.144-2.277 2.144-1.165 0-2.198-.943-2.053-2.218.189-1.66.672-4.185 1.342-6.666.52-1.926 1.169-3.882 1.905-5.396H9.534a.75.75 0 1 1 0-1.5h4.034a.75.75 0 0 1 .633 1.152c-.871 1.37-1.699 3.693-2.359 6.135-.655 2.424-1.12 4.874-1.3 6.444-.03.27.173.549.563.549.38 0 .742-.319.783-.776.448-5.066 2.973-10.666 4.881-13.754.068-.11.096-.352-.04-.626-.135-.277-.31-.344-.387-.344H8.5Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

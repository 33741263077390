import { bindActions } from '@utils';
import { actions } from './reducer';

export const {
	setIsGamesSearching,
	setIsFullScreenGaming,
	setIsSidebarOpened,
	setActiveLastWinsStatisticsName,
	setModalType,
	setModalSocialBonusTaskType,
	setIsMobile,
	setIsTablet,
	setMobileHeight,
	setIsLiveChatOpened,
	setIsSidebarDesktopOpened,
	toggleIs404Page,
	setIsSidebarTabletOpened,
	setIsUpdateGameProviders,
	setIsUpdateGamesList,
	setPlatform,
	setIsAndroidBanner
} = bindActions(actions);

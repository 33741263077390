import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={20}
		height={20}
		fill='none'
		{...props}
	>
		<path
			fill='#fff'
			d='M5.632.834c-.67 0-1.224 0-1.675.037-.468.038-.899.12-1.303.326-.628.32-1.138.83-1.457 1.457-.206.404-.288.835-.327 1.303-.037.451-.037 1.005-.037 1.676v8.735c0 .671 0 1.225.037 1.676.039.468.12.899.327 1.303.32.627.83 1.137 1.457 1.457.404.206.835.288 1.303.327.451.036 1.005.036 1.675.036h2.903c.67 0 1.224 0 1.675-.037.468-.038.899-.12 1.303-.326a3.334 3.334 0 0 0 1.457-1.457c.206-.404.288-.835.327-1.303.037-.451.037-1.005.037-1.676v-.617a.833.833 0 1 0-1.667 0v.583c0 .714 0 1.199-.031 1.574-.03.365-.084.552-.15.683-.16.313-.416.568-.73.728-.13.066-.316.12-.682.15-.375.031-.86.032-1.574.032H5.667c-.714 0-1.2-.001-1.574-.032-.366-.03-.552-.084-.683-.15a1.667 1.667 0 0 1-.728-.728c-.067-.13-.12-.318-.15-.683-.031-.375-.032-.86-.032-1.574V5.667c0-.713 0-1.199.031-1.574.03-.365.084-.552.15-.682.16-.314.416-.569.73-.729.13-.066.316-.12.682-.15.375-.03.86-.031 1.574-.031H8.5c.714 0 1.2 0 1.574.031.366.03.553.084.683.15.313.16.568.415.728.729.067.13.12.317.15.682.031.375.032.86.032 1.574v.584a.833.833 0 0 0 1.667 0v-.618c0-.67 0-1.225-.037-1.676-.039-.468-.12-.899-.327-1.303a3.333 3.333 0 0 0-1.457-1.457C11.11.991 10.678.91 10.21.871 9.759.834 9.205.834 8.535.834H5.632Z'
		/>
		<path
			fill='#fff'
			d='M16.423 6.078a.833.833 0 0 0-1.179 1.179l1.911 1.91H5.833a.833.833 0 0 0 0 1.667h11.322l-1.91 1.91a.833.833 0 0 0 1.178 1.18l3.333-3.334a.833.833 0 0 0 0-1.179l-3.333-3.333Z'
		/>
	</svg>
);

export default SvgComponent;

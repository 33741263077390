const hostNameArr = window?.location?.hostname?.split('.');
const hostName =
	process.env.NODE_ENV === 'development'
		? 'localhost'
		: `.${
				hostNameArr?.length > 2
					? hostNameArr?.slice(1)?.join('.')
					: hostNameArr?.join('.')
		  }`;

const domain = `domain=${hostName}`;

function setCookie(name: string, value: string, days: number) {
	let expires = '';

	if (days) {
		const date = new Date();

		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

		expires = '; expires=' + (days === 0 ? days : date.toUTCString());
	}

	document.cookie = name + '=' + (value || '') + expires + `; path=/; ${domain}`;
}

function getCookie(name: string) {
	const nameEQ = name + '=';
	const ca = document.cookie.split(';');

	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];

		while (c.charAt(0) == ' ') c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
	}

	return null;
}

function eraseCookie(name: string) {
	document.cookie = name + `=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; ${domain}`;
}

export default {
	setCookie,
	getCookie,
	eraseCookie
};

import React, { ReactNode, useEffect, useState } from 'react';
import { CheckSvg } from '@components/svg';
import s from './style.module.scss';
import cn from 'classnames';

type AcceptCheckboxP = {
	onChange?: (isAccepted: boolean) => void;
	className?: string;
	initialValue?: boolean;
	children?: ReactNode;
	isDefault?: boolean;
};

export const AcceptCheckbox = (props: AcceptCheckboxP) => {
	const {
		onChange: handler,
		className = '',
		initialValue = false,
		children,
		isDefault = false
	} = props;

	const switchCheckbox = () => {
		setCheckboxStatus(!isAccepted);
		handler && handler(!isAccepted);
	};

	const [isAccepted, setCheckboxStatus] = useState(initialValue);

	useEffect(() => {
		if (initialValue && initialValue !== isAccepted) {
			setCheckboxStatus(initialValue);
		}
	}, [initialValue]);

	return (
		<div className={cn(s.accept, className)}>
			<span
				className={cn(s.acceptMarker, {
					[s.acceptMarkerActive]: isAccepted,
					[s.default]: isDefault
				})}
				onClick={switchCheckbox}
			>
				{isAccepted && <CheckSvg />}
			</span>

			<div className={s.acceptLabel}>{children}</div>
		</div>
	);
};

export function millisecondsUntilNextFriday() {
	const now = new Date();
	const currentDay = now.getUTCDay();
	const daysUntilFriday = currentDay < 5 ? 5 - currentDay : 12 - currentDay;

	const nextFriday = new Date(now);

	nextFriday.setUTCDate(now.getUTCDate() + daysUntilFriday);
	nextFriday.setHours(23, 0, 0, 0);

	const todayIsFriday = now?.getDay() === 5;
	const todayFriday = new Date(now);

	todayFriday.setHours(23, 0, 0, 0);

	const millisecondsUntilNextFriday = todayIsFriday
		? todayFriday.getTime() - now.getTime()
		: nextFriday.getTime() - now.getTime();

	return millisecondsUntilNextFriday;
}

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M5.054 7.13c.052-.414.464-.666.87-.57.407.097.653.504.613.92a5.488 5.488 0 1 0 2.027-3.759c-.326.262-.801.28-1.105-.006-.304-.287-.32-.769-.002-1.04A7 7 0 1 1 5.054 7.13Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M10.093 1.506a.75.75 0 0 1 .651.837c-.063.506.097 1.199.457 1.579.16.169.358.278.627.299.283.022.71-.05 1.313-.38a.75.75 0 0 1 1.106.591c.024.268.247.899.802 1.372.512.437 1.368.792 2.782.465a.75.75 0 1 1 .338 1.462c-1.836.423-3.188-.014-4.093-.785A4.015 4.015 0 0 1 13.02 5.53c-.46.155-.897.218-1.31.186a2.422 2.422 0 0 1-1.6-.764c-.744-.786-.959-1.968-.855-2.796a.75.75 0 0 1 .837-.651ZM12 17.75a.75.75 0 0 1 .75.75V22a.75.75 0 0 1-1.5 0v-3.5a.75.75 0 0 1 .75-.75ZM7.478 15.068a.75.75 0 0 1-.546.91c-.494.123-.984.602-1.41 1.355a7.842 7.842 0 0 0-.788 2.074.75.75 0 0 1-1.467-.314 9.337 9.337 0 0 1 .95-2.5c.485-.856 1.245-1.794 2.351-2.07a.75.75 0 0 1 .91.545ZM16.773 15.068a.75.75 0 0 0 .545.91c.494.123.984.602 1.411 1.355.411.724.675 1.55.788 2.074a.75.75 0 0 0 1.466-.314 9.334 9.334 0 0 0-.95-2.5c-.485-.856-1.245-1.794-2.35-2.07a.75.75 0 0 0-.91.545Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='M9.222 15.284a.75.75 0 0 0-.444 1.432L9 16l-.222.716.003.001.005.002.017.005a6.968 6.968 0 0 0 .278.078c.186.05.448.116.76.182.616.13 1.454.266 2.284.266.83 0 1.668-.136 2.285-.266a14.461 14.461 0 0 0 1.038-.26l.017-.005.005-.002h.001l-.22-.717.221.716a.75.75 0 0 0-.444-1.432h-.002l-.01.003-.047.014-.189.053c-.164.044-.4.103-.68.162-.568.12-1.292.234-1.975.234s-1.407-.114-1.975-.234a12.993 12.993 0 0 1-.868-.215l-.047-.014-.011-.003h-.002Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

import { UserService } from '@lib/api/user/UserService';
import { setUsersLevels } from '@store/reducers/user/dispatchers';
import { Currencies } from '@types';

export const getUsersLevels = async (currency: Currencies) => {
	try {
		const { status, data } = await UserService.getUsersLevels(currency);

		if (status === 200) {
			setUsersLevels(data);
		}
	} catch (e) {
		console.log(e);
	}
};

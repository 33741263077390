import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={20}
		height={20}
		viewBox='0 0 20 20'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<rect
			width='20'
			height='20'
			rx='10'
			fill='url(#paint0_linear_1461_189737)'
		/>
		<path
			d='M12.9531 12.875V14H7.23438V13.0332L10.0117 10.0039C10.3164 9.66016 10.5566 9.36328 10.7324 9.11328C10.9082 8.86328 11.0312 8.63867 11.1016 8.43945C11.1758 8.23633 11.2129 8.03906 11.2129 7.84766C11.2129 7.57812 11.1621 7.3418 11.0605 7.13867C10.9629 6.93164 10.8184 6.76953 10.627 6.65234C10.4355 6.53125 10.2031 6.4707 9.92969 6.4707C9.61328 6.4707 9.34766 6.53906 9.13281 6.67578C8.91797 6.8125 8.75586 7.00195 8.64648 7.24414C8.53711 7.48242 8.48242 7.75586 8.48242 8.06445H7.07031C7.07031 7.56836 7.18359 7.11523 7.41016 6.70508C7.63672 6.29102 7.96484 5.96289 8.39453 5.7207C8.82422 5.47461 9.3418 5.35156 9.94727 5.35156C10.5176 5.35156 11.002 5.44727 11.4004 5.63867C11.7988 5.83008 12.1016 6.10156 12.3086 6.45312C12.5195 6.80469 12.625 7.2207 12.625 7.70117C12.625 7.9668 12.582 8.23047 12.4961 8.49219C12.4102 8.75391 12.2871 9.01562 12.127 9.27734C11.9707 9.53516 11.7852 9.79492 11.5703 10.0566C11.3555 10.3145 11.1191 10.5762 10.8613 10.8418L9.01562 12.875H12.9531Z'
			fill='#172032'
		/>
		<defs>
			<linearGradient
				id='paint0_linear_1461_189737'
				x1='10'
				y1='0'
				x2='10'
				y2='20'
				gradientUnits='userSpaceOnUse'
			>
				<stop stopColor='white' />
				<stop
					offset='1'
					stopColor='#CBCBCB'
				/>
			</linearGradient>
		</defs>
	</svg>
);

export default SvgComponent;

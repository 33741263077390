import React from 'react';
import { SVGProps } from 'react';
import RuFlag from './RuFlag';
import BnFlag from './BnFlag';
import HiFlag from './HiFlag';
import EnFlag from './EnFlag';
import PtFlag from './PtFlag';
import { Languages, LanguagesTopXGo } from '@types';

type FlagComponentsType = {
	ru: (props: SVGProps<SVGSVGElement>) => JSX.Element;
	hi: (props: SVGProps<SVGSVGElement>) => JSX.Element;
	en: (props: SVGProps<SVGSVGElement>) => JSX.Element;
	bn: (props: SVGProps<SVGSVGElement>) => JSX.Element;
	pt: (props: SVGProps<SVGSVGElement>) => JSX.Element;
};

const SpecificFlag: FlagComponentsType = {
	ru: RuFlag,
	hi: HiFlag,
	en: EnFlag,
	bn: BnFlag,
	pt: PtFlag
};

export const FlagComponents = ({ name }: { name: Languages | LanguagesTopXGo }) => {
	const Flag = SpecificFlag[name as keyof FlagComponentsType];

	return name in SpecificFlag ? <Flag /> : <></>;
};

import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={25}
		fill='none'
		{...props}
	>
		<rect
			width={24}
			height={24}
			fill='#2EDB73'
			rx={12}
		/>
		<g filter='url(#a)'>
			<path
				fill='#fff'
				fillRule='evenodd'
				d='M18.59 7.41a.833.833 0 0 1 0 1.18l-8 8a.833.833 0 0 1-1.18 0l-4-4a.833.833 0 0 1 1.18-1.18L10 14.823l7.41-7.411a.833.833 0 0 1 1.18 0Z'
				clipRule='evenodd'
			/>
		</g>
		<defs>
			<filter
				id='a'
				width={24}
				height={24}
				x={0}
				y={4}
				colorInterpolationFilters='sRGB'
				filterUnits='userSpaceOnUse'
			>
				<feFlood
					floodOpacity={0}
					result='BackgroundImageFix'
				/>
				<feColorMatrix
					in='SourceAlpha'
					result='hardAlpha'
					values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
				/>
				<feOffset dy={4} />
				<feGaussianBlur stdDeviation={2} />
				<feComposite
					in2='hardAlpha'
					operator='out'
				/>
				<feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
				<feBlend
					in2='BackgroundImageFix'
					result='effect1_dropShadow_1_5'
				/>
				<feBlend
					in='SourceGraphic'
					in2='effect1_dropShadow_1_5'
					result='shape'
				/>
			</filter>
		</defs>
	</svg>
);

export default SvgComponent;

import { createSlice } from '@reduxjs/toolkit';

import * as reducers from './reducers';
import { IAuthInitialState } from '@types';

export const initialState: IAuthInitialState = {
	authType: null,
	isAuthed: false,
	receivedCredentials: null,
	isResetPasswordMode: false,
	isAuthPending: false,
	authToken: null,
	nextAttemptResetPassword: null,
	isSendedConfirm: false
};

export const { actions, reducer } = createSlice({
	name: 'auth',
	initialState,
	reducers
});

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M11.197 8.6a.75.75 0 0 1-.445.963l-4.818 1.772a1.25 1.25 0 0 0-.65.549l-.889 1.538a.25.25 0 0 0 .092.341l1.082.625a.75.75 0 1 1-.75 1.3l-1.082-.626a1.75 1.75 0 0 1-.64-2.39l.887-1.538a2.75 2.75 0 0 1 1.432-1.206l4.818-1.772a.75.75 0 0 1 .963.445ZM15.129 10.87a.75.75 0 0 0-.612.867l.875 5.059a1.25 1.25 0 0 1-.15.838l-.888 1.538a.25.25 0 0 1-.341.091l-1.083-.625a.75.75 0 1 0-.75 1.3l1.083.624a1.75 1.75 0 0 0 2.39-.64l.888-1.538a2.75 2.75 0 0 0 .329-1.844l-.875-5.058a.75.75 0 0 0-.866-.612Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M14.382 2.535a2.25 2.25 0 0 1 2.649-.293l.344.198a.75.75 0 0 1-.75 1.3l-.344-.2a.75.75 0 0 0-.883.098l-1.71 1.575a4.247 4.247 0 0 0-.802 1.002l-3.503 6.067a2.25 2.25 0 0 1-1.017.923l-1.228.559a.25.25 0 0 0-.113.103l-.59 1.021 2.815 1.625a.75.75 0 1 1-.75 1.3l-3.464-2a.75.75 0 0 1-.275-1.025l.965-1.671a1.75 1.75 0 0 1 .79-.718l1.23-.56a.75.75 0 0 0 .338-.307l3.503-6.067a5.75 5.75 0 0 1 1.084-1.355l1.71-1.575Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M18.79 5.08a2.25 2.25 0 0 0-1.071-2.441l-.344-.199a.75.75 0 0 0-.75 1.3l.344.198a.75.75 0 0 1 .357.814l-.51 2.268c-.093.42-.25.822-.466 1.195l-3.503 6.067a2.25 2.25 0 0 0-.29 1.342l.13 1.343a.25.25 0 0 1-.033.15l-.59 1.021-2.814-1.625a.75.75 0 0 0-.75 1.3l3.464 2a.75.75 0 0 0 1.024-.275l.965-1.671a1.75 1.75 0 0 0 .226-1.044l-.13-1.343a.75.75 0 0 1 .097-.448l3.503-6.067c.291-.504.504-1.049.631-1.617l.51-2.268ZM4.07 16.986a.75.75 0 0 1 1.024-.274l6.062 3.5a.75.75 0 0 1-.75 1.299l-6.062-3.5a.75.75 0 0 1-.275-1.025Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='M12.894 6.819a.75.75 0 0 1 .839-.65l-.095.745.095-.744h.005l.008.002.027.004a4.651 4.651 0 0 1 .393.072c.217.049.559.139.838.3.279.16.528.412.678.575a5.445 5.445 0 0 1 .26.304l.016.022.006.007.001.002h.001l-.596.456.596-.455a.75.75 0 0 1-1.192.91l-.002-.002-.009-.012a3.85 3.85 0 0 0-.185-.216 1.736 1.736 0 0 0-.324-.292 1.738 1.738 0 0 0-.415-.135 3.982 3.982 0 0 0-.28-.052l-.015-.002h-.001m-.65-.84a.75.75 0 0 0 .65.84l-.65-.84Zm-6.625 10.86a.75.75 0 0 1 .275 1.025l-.625 1.082a.75.75 0 0 1-1.3-.75l.626-1.082a.75.75 0 0 1 1.024-.275Zm1.732 1a.75.75 0 0 1 .275 1.025l-.625 1.082a.75.75 0 0 1-1.3-.75l.626-1.082A.75.75 0 0 1 8 18.678Zm1.732 1a.75.75 0 0 1 .275 1.025l-.625 1.082a.75.75 0 1 1-1.3-.75l.626-1.082a.75.75 0 0 1 1.024-.275Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={25}
		height={24}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M8.636 17.857a.75.75 0 0 1 .257 1.029l-1.5 2.5a.75.75 0 1 1-1.286-.772l1.5-2.5a.75.75 0 0 1 1.029-.257ZM16.364 17.857a.75.75 0 0 0-.257 1.029l1.5 2.5a.75.75 0 1 0 1.286-.772l-1.5-2.5a.75.75 0 0 0-1.029-.257Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			d='M20.468 13.135c.4.107.641.52.501.911a9 9 0 1 1 .255-5.257c.102.403-.177.79-.587.859-.409.068-.792-.21-.9-.61a7.498 7.498 0 1 0-.225 4.617c.147-.388.555-.627.956-.52Z'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M12.5 4.75a.75.75 0 0 1 .75.75v1.25a.75.75 0 0 1-1.5 0V5.5a.75.75 0 0 1 .75-.75ZM12.5 1.298a.75.75 0 0 1 .75.75v.641a.75.75 0 0 1-1.5 0v-.641a.75.75 0 0 1 .75-.75ZM12.5 18.496a.75.75 0 0 1 .75.75v.642a.75.75 0 0 1-1.5 0v-.642a.75.75 0 0 1 .75-.75ZM19.338 4.13a.75.75 0 0 1 0 1.06l-.454.454a.75.75 0 0 1-1.06-1.06l.453-.454a.75.75 0 0 1 1.06 0ZM7.177 16.291a.75.75 0 0 1 0 1.06l-.454.455a.75.75 0 1 1-1.06-1.061l.453-.454a.75.75 0 0 1 1.06 0ZM4.972 10.968a.75.75 0 0 1-.75.75H3.58a.75.75 0 1 1 0-1.5h.642a.75.75 0 0 1 .75.75ZM19.338 17.806a.75.75 0 0 1-1.06 0l-.454-.454a.75.75 0 1 1 1.06-1.06l.454.453a.75.75 0 0 1 0 1.06ZM7.177 5.644a.75.75 0 0 1-1.061 0l-.454-.453a.75.75 0 0 1 1.061-1.06l.454.453a.75.75 0 0 1 0 1.06ZM12.5 15a.75.75 0 0 1 .75.75V17a.75.75 0 0 1-1.5 0v-1.25a.75.75 0 0 1 .75-.75ZM6 11.25a.75.75 0 0 1 .75-.75H8A.75.75 0 0 1 8 12H6.75a.75.75 0 0 1-.75-.75ZM7.904 15.846a.75.75 0 0 1 0-1.06l.884-.884a.75.75 0 0 1 1.06 1.06l-.883.884a.75.75 0 0 1-1.061 0ZM15.152 8.598a.75.75 0 0 1 0-1.06l.884-.884a.75.75 0 1 1 1.06 1.06l-.884.884a.75.75 0 0 1-1.06 0ZM17.096 15.846a.75.75 0 0 1-1.06 0l-.884-.884a.75.75 0 0 1 1.06-1.06l.884.884a.75.75 0 0 1 0 1.06ZM9.848 8.598a.75.75 0 0 1-1.06 0l-.884-.884a.75.75 0 0 1 1.06-1.06l.884.884a.75.75 0 0 1 0 1.06Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			d='M14.5 11.25a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='M16.25 11.25a.75.75 0 0 1 .75-.75h3.791a.75.75 0 0 1 0 1.5H17a.75.75 0 0 1-.75-.75Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

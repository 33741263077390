import * as React from 'react';
import { SVGProps } from 'react';

export const ShevronSvg = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={16}
		height={16}
		fill='none'
		viewBox='0 0 16 16'
		{...props}
	>
		<path
			fill={props?.fill || '#fff'}
			fillRule='evenodd'
			d='M3.078 5.744a.833.833 0 0 1 1.178 0L8 9.488l3.744-3.744a.833.833 0 0 1 1.179 1.179l-4.334 4.333a.833.833 0 0 1-1.178 0L3.078 6.923a.833.833 0 0 1 0-1.179Z'
			clipRule='evenodd'
		/>
	</svg>
);

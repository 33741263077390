import { AnalyticsEventType } from '@types';
import { sendAnalyticsHandler } from '@utils';
import { UserService } from './UserService';
import { getCurrentUserData } from '@lib/api/user/getCurrentUserData';

export const changePassword = async (
	data: {
		newPassword: string;
		oldPassword: string;
	},
	successFunc: () => void,
	disableLoader?: () => void
) => {
	try {
		const { status } = await UserService.changePassword(data);

		successFunc();

		if (status === 200) {
			sendAnalyticsHandler(AnalyticsEventType?.profile_change_password_success);
			await getCurrentUserData();
		}
	} catch (e) {
		disableLoader && disableLoader();
	}
};

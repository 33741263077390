import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import useBlocker from './useBlocker';

export default function useCallbackPrompt(
	when: boolean
): [showPrompt: boolean, confirmNavigation: () => void, cancelNavigation: () => void] {
	const navigate = useNavigate();
	const location = useLocation();
	const [showPrompt, setShowPrompt] = useState(false);
	const [lastLocation, setLastLocation] = useState<any>(null);
	const [confirmedNavigation, setConfirmedNavigation] = useState(false);

	const cancelNavigation = useCallback(() => {
		setShowPrompt(false);
		setLastLocation(null);
	}, []);

	const handleBlockedNavigation = useCallback(
		(nextLocation: { location: { pathname: string } }) => {
			if (!confirmedNavigation && nextLocation.location.pathname !== location.pathname) {
				setShowPrompt(true);
				setLastLocation(nextLocation);

				return false;
			}

			return true;
		},

		[confirmedNavigation, location]
	);

	const confirmNavigation = useCallback(() => {
		setShowPrompt(false);
		setConfirmedNavigation(true);
	}, []);

	useEffect(() => {
		if (confirmedNavigation && lastLocation) {
			navigate(lastLocation.location?.pathname);

			setConfirmedNavigation(false);
		}
	}, [confirmedNavigation, lastLocation]);

	useBlocker(handleBlockedNavigation, when);

	return [showPrompt, confirmNavigation, cancelNavigation];
}

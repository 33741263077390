import {
	ICommonUIInitialState,
	SetActiveLastWinsStatisticsNamePA,
	SetIsFullScreenGamingPA,
	SetIsGamesSearchingPA,
	SetIsLiveChatOpenedPA,
	SetIsMobilePA,
	SetIsSidebarHiddenPA,
	SetIsSidebarOpenedPA,
	SetIsTabletPA,
	SetIsUpdateGameProviders,
	SetIsUpdateGamesList,
	SetMobileHeightPA,
	SetModalSocialBonusTaskTypePA,
	SetModalTypePA,
	SetIsTelegramPA,
	PlatfromQueryE,
	SetIsAndroidBanner
} from '@types';

export const setIsGamesSearching = (
	state: ICommonUIInitialState,
	action: SetIsGamesSearchingPA
) => {
	state.isGamesSearching = action.payload;
};

export const setPlatform = (state: ICommonUIInitialState, action: SetIsTelegramPA) => {
	if (action?.payload === PlatfromQueryE.telegram) {
		state.isTelegram = true;
	}

	if (action?.payload === PlatfromQueryE.app) {
		state.isApp = true;
	}
};

export const setIsUpdateGameProviders = (
	state: ICommonUIInitialState,
	action: SetIsUpdateGameProviders
) => {
	state.isUpdateGameProviders = action.payload;
};

export const setIsAndroidBanner = (
	state: ICommonUIInitialState,
	action: SetIsAndroidBanner
) => {
	state.isAndroidBanner = action.payload;
};

export const setIsUpdateGamesList = (
	state: ICommonUIInitialState,
	action: SetIsUpdateGamesList
) => {
	state.isUpdateGamesList = action.payload;
};

export const setIsFullScreenGaming = (
	state: ICommonUIInitialState,
	action: SetIsFullScreenGamingPA
) => {
	state.isFullScreenGaming = action.payload;
};

export const setIsSidebarOpened = (
	state: ICommonUIInitialState,
	action: SetIsSidebarOpenedPA
) => {
	state.isSidebarOpened = action.payload;
};

export const setIsSidebarDesktopOpened = (
	state: ICommonUIInitialState,
	action: SetIsSidebarHiddenPA
) => {
	window.localStorage.setItem('isSidebarDesktopOpened', action.payload?.toString());
	state.isSidebarDesktopOpened = action.payload;
};

export const setIsSidebarTabletOpened = (
	state: ICommonUIInitialState,
	action: SetIsSidebarHiddenPA
) => {
	state.isSidebarTabletOpened = action.payload;
};

export const setActiveLastWinsStatisticsName = (
	state: ICommonUIInitialState,
	action: SetActiveLastWinsStatisticsNamePA
) => {
	state.activeLastWinsStatisticsName = action.payload;
};

export const setModalType = (state: ICommonUIInitialState, action: SetModalTypePA) => {
	state.modalType = action.payload;
};

export const setModalSocialBonusTaskType = (
	state: ICommonUIInitialState,
	action: SetModalSocialBonusTaskTypePA
) => {
	state.modalSocialBonusTaskType = action.payload;
};

export const setIsMobile = (state: ICommonUIInitialState, action: SetIsMobilePA) => {
	state.isMobile = action.payload;
};

export const setIsTablet = (state: ICommonUIInitialState, action: SetIsTabletPA) => {
	state.isTablet = action.payload;
};

export const setMobileHeight = (
	state: ICommonUIInitialState,
	action: SetMobileHeightPA
) => {
	state.mobileHeight = action.payload;
};

export const setIsLiveChatOpened = (
	state: ICommonUIInitialState,
	action: SetIsLiveChatOpenedPA
) => {
	state.isLiveChatOpened = action.payload;
};

export const toggleIs404Page = (state: ICommonUIInitialState) => {
	state.is404Page = !state.is404Page;
};

import { userSelectors } from '@store/reducers/user/selectors';
import { useSelector } from 'react-redux';
import React from 'react';
import { Toast } from 'react-hot-toast';
import { useLocales } from '@lib/hooks';
import { NotificattionContainer } from '../NotificattionContainer';
import { LottieComponent } from '@components/common/elements/lottie';
import { getImageByURL } from '@utils';
import s from './style.module.scss';

type PropsType = {
	level: number;
	t: Toast;
};

export const LevelUpToast = (props: PropsType) => {
	const { level, t } = props;
	const { userLevels } = useSelector(userSelectors.userInfo);

	const currentLevel = level ? userLevels?.find((e) => e?.id === level) : undefined;

	const { localizeText } = useLocales({
		path: 'global',
		node: 'notifications'
	});

	return (
		<>
			<NotificattionContainer t={t}>
				<>
					{currentLevel && currentLevel?.level && (
						<img
							className={s.levelImg}
							src={getImageByURL(
								`../../../../../../../assets/img/levels/level_${currentLevel?.level}.webp`
							)}
						/>
					)}

					<div>
						<div className={s.levelName}>{currentLevel?.name}</div>

						<h3 className={s.textTitle}>{localizeText('level_up_success')}</h3>
					</div>
				</>
			</NotificattionContainer>

			<div className={s.levelUp}>
				<LottieComponent
					path={'../../../../../../assets/lotties/fireworks.json'}
					style={{ width: '100%', height: '100%' }}
				/>
			</div>
		</>
	);
};

import * as React from 'react';
import { SVGProps } from 'react';
export const PersonSignInSvg = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={21}
		height={20}
		fill='none'
		{...props}
	>
		<path d="M14.25 5.41667C14.25 7.71785 12.5711 9.58333 10.5 9.58333C8.42893 9.58333 6.75 7.71785 6.75 5.41667C6.75 3.11548 8.42893 1.25 10.5 1.25C12.5711 1.25 14.25 3.11548 14.25 5.41667Z" fill="white"/>
		<path d="M18 15.8333C18 18.3646 14.1819 17.5 10.5 17.5C6.8181 17.5 3 18.3646 3 15.8333C3 13.302 6.8181 11.25 10.5 11.25C14.1819 11.25 18 13.302 18 15.8333Z" fill="white"/>
	</svg>
);

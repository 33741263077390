import { CategoriesKeys } from '@types';
import React, { SVGProps } from 'react';
import Baccara from './Baccara';
import BlackJack from './BlackJack';
import Books from './Books';
import Cards from './Cards';
import Classic from './Classic';
import Dices from './Dices';
import FirstPerson from './FirstPerson';
import GameShow from './GameShow';
import Loto from './Loto';
import New from './New';
import Others from './Others';
import Poker from './Poker';
import Roulet from './Roulet';
import Sport from './Sport';
import Wheel from './Wheel';
import Asia from './Asia';
import Bonus from './Bonus';
import Crash from './Crash';
import Egypt from './Egypt';
import Jackpot from './Jackpot';
import Myth from './Myth';
import Slots from './Slots';
import Unicorn from './Unicorn';
import MegaWays from './MegaWays';
import All from './All';
import TvGames from './TvGames';
import LiveDilers from './LiveDilers';
import { isTapX } from '@config/global';
import ScratchCards from './ScratchCards';
import Top from './Top';

type IconsType = Record<
	keyof typeof CategoriesKeys,
	(props: SVGProps<SVGSVGElement>) => JSX.Element
>;

const Icons: IconsType = {
	all: All,
	tv_games_sport: Sport,
	tv_games_craps: Dices,
	tv_games_card: Cards,
	tv_games_wheel: Wheel,
	tv_games_lotto: Loto,
	live_dealers_other: Others,
	live_dealers_firtsview: FirstPerson,
	live_dealers_baccarat: Baccara,
	live_dealers_poker: Poker,
	live_dealers_show: GameShow,
	live_dealers_blackjack: BlackJack,
	live_dealers_roulette: Roulet,
	live_dealers_new: New,
	megaways: MegaWays,
	classic: Classic,
	books: Books,
	asia: Asia,
	egypt: Egypt,
	fantasy: Unicorn,
	mifology: Myth,
	new: New,
	bonus: Bonus,
	jackpot: Jackpot,
	crash: Crash,
	card: Cards,
	roulette: Roulet,
	slots: Slots,
	quick_games: Crash,
	game_shows: TvGames,
	blackjack: BlackJack,
	live_casino: LiveDilers,
	scratch_cards: ScratchCards,
	lottery: Loto,
	top_games: Top,
	table_games: Dices
};

export const CategoriesIcons = ({ name }: { name: keyof typeof CategoriesKeys }) => {
	const Icon = name in Icons ? Icons[name as keyof IconsType] : All;

	return isTapX ? <Icon fill={'#fff'} /> : <Icon fill={'#A0A4AD'} />;
};

import React, { useState } from 'react';
import { ShevronSvg } from '@components/svg';
import s from './style.module.scss';
import cn from 'classnames';

type DropdownP = {
	title: string;
	component?: JSX.Element;
};

export const Dropdown = (props: DropdownP) => {
	const { title, component } = props;

	const [isOpened, setIsOpened] = useState(false);

	const toggleIsOpened = () => setIsOpened(!isOpened);

	return (
		<div className={s.Dropdown}>
			<div
				onClick={toggleIsOpened}
				className={s.DropdownHeader}
			>
				<div className={s.DropdownTitle}>{title}</div>
				<div className={cn(s.DropdownArrow, { [s.opened]: isOpened })}>
					<ShevronSvg />
				</div>
			</div>
			<div className={cn(s.hiddenBlock, { [s.opened]: isOpened })}>{component}</div>
		</div>
	);
};

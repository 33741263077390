import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M10.395 2.065a2.25 2.25 0 0 1 3.21 0l.946.963a.75.75 0 0 0 .66.214l1.326-.223a2.25 2.25 0 0 1 2.599 1.894l.106.729a.75.75 0 0 1-1.484.217l-.107-.73a.75.75 0 0 0-.866-.63l-1.326.222a2.25 2.25 0 0 1-1.979-.642l-.945-.963a.75.75 0 0 0-1.07 0l-.945.963a2.25 2.25 0 0 1-1.978.642L7.21 4.498a.75.75 0 0 0-.866.63l-.199 1.334a2.25 2.25 0 0 1-1.222 1.682l-1.208.602a.75.75 0 0 0-.33 1.018l.623 1.196a2.25 2.25 0 0 1 0 2.08l-.624 1.196a.75.75 0 0 0 .331 1.018l1.208.602a2.25 2.25 0 0 1 1.222 1.682l.199 1.335a.75.75 0 0 0 .866.63l1.33-.224a2.25 2.25 0 0 1 1.979.643l.945.962c.294.3.776.3 1.07 0l.945-.962a2.25 2.25 0 0 1 1.979-.643l1.33.223a.75.75 0 0 0 .866-.629l.199-1.335a2.25 2.25 0 0 1 1.222-1.682l1.208-.602a.75.75 0 0 0 .33-1.018l-.623-1.196a2.25 2.25 0 0 1 0-2.08l.628-1.205a.75.75 0 0 0-.326-1.016l-.632-.32a.75.75 0 0 1 .678-1.338l.632.32a2.25 2.25 0 0 1 .979 3.047l-.628 1.205a.75.75 0 0 0 0 .694l.623 1.196a2.25 2.25 0 0 1-.992 3.054l-1.208.602a.75.75 0 0 0-.408.56l-.198 1.335a2.25 2.25 0 0 1-2.598 1.887l-1.33-.223a.75.75 0 0 0-.66.214l-.945.963a2.25 2.25 0 0 1-3.211 0l-.946-.963a.75.75 0 0 0-.66-.214l-1.33.223a2.25 2.25 0 0 1-2.598-1.887l-.198-1.335a.75.75 0 0 0-.408-.56l-1.208-.602a2.25 2.25 0 0 1-.992-3.054l.624-1.196a.75.75 0 0 0 0-.694l-.624-1.196a2.25 2.25 0 0 1 .992-3.054L4.255 6.8a.75.75 0 0 0 .408-.56l.198-1.335A2.25 2.25 0 0 1 7.46 3.02l1.33.223a.75.75 0 0 0 .66-.214l.946-.963Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='m14.235 8.864 1.023 6.995a.75.75 0 0 0 1.484-.218L15.56 7.562c-.193-1.316-2.034-1.46-2.43-.192l-1.278 4.089-1.498-3.122c-.527-1.098-2.152-.871-2.359.328l-.984 5.708a.75.75 0 1 0 1.478.254l.851-4.935 1.435 2.988c.49 1.023 1.981.915 2.32-.168l1.14-3.648Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

import React, { useEffect, useState } from 'react';
import { formatNumber, renderCurrencySymbol, sendAnalyticsHandler } from '@utils';
import { AnalyticsEventType, Currencies } from '@types';
import { Ripple } from '../ripple-animation';
import s from './style.module.scss';
import cn from 'classnames';
import convertToUsers from '@utils/convertToUsers';

type PreloaderP = {
	countOfFs: number;
	amount: number;
	invoiceHandler: (value: string) => void;
	currency: Currencies;
};

export const PresetButton = (props: PreloaderP) => {
	const { countOfFs, amount, invoiceHandler, currency } = props;

	const [isClicked, setIsClicked] = useState(false);

	useEffect(() => {
		if (isClicked) {
			setTimeout(() => setIsClicked(false), 1000);
		}
	}, [isClicked]);

	return (
		<div
			className={cn(s.presetsBlock, {
				[s.border]: countOfFs > 0,
				[s.wasClick]: isClicked
			})}
			onClick={() => {
				invoiceHandler((amount / 100).toString());
				setIsClicked(true);

				sendAnalyticsHandler(AnalyticsEventType?.payment_click_choose_amount, {
					payment_amount: amount / 100
				});
			}}
		>
			<div className={s.bg} />
			<span className={s.option}>
				{renderCurrencySymbol(currency)} {formatNumber(convertToUsers(amount))}
				<Ripple />
			</span>
			{countOfFs > 0 && <div className={s.presetsDescr}>{countOfFs} FS</div>}
		</div>
	);
};

import React, { SVGProps } from 'react';
import { MenuSideBarNames } from '@types';
import BetsAndTrans from './BetsAndTrans';
import Bonuces from './Bonuces';
import Casino from './Casino';
import Promocode from './Promocode';
import Refferal from './Refferal';
import SportPoker from './SportPoker';
import Info from './Info';
import Settings from './Settings';
import TelegramBonus from './TelegramBonus';
import Cashback from './Cashback';
import Levels from './Levels';

type IconsType = Record<
	keyof typeof MenuSideBarNames,
	(props: SVGProps<SVGSVGElement>) => JSX.Element
>;

const Icons: IconsType = {
	bets_and_tarns: BetsAndTrans,
	bonuces: Bonuces,
	refferal: Refferal,
	promocode: Promocode,
	casino: Casino,
	// live_dilers: LiveDilers,
	// crash_games: CrashGames,
	// tv_games: TvGames,
	sportpoker: SportPoker,
	info: Info,
	settings: Settings,
	telegram_bonus: TelegramBonus,
	cashback: Cashback,
	levels: Levels
};

export const MenuSidebarIcons = ({ name }: { name: keyof typeof MenuSideBarNames }) => {
	const Icon = Icons[name as keyof IconsType];

	return <Icon />;
};

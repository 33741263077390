import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		{...props}
	>
		<path
			stroke='#A0A4AD'
			data-id='changed-color-stroke'
			strokeLinejoin='round'
			strokeWidth={1.5}
			d='m13.785 20.36-.47-.857a1.5 1.5 0 0 0-2.63 0l-.47.857a1.226 1.226 0 0 1-.738.595 1.165 1.165 0 0 1-.923-.127L6.447 19.55a1.272 1.272 0 0 1-.567-.776 1.335 1.335 0 0 1 .122-.97l.563-1.026a1.5 1.5 0 0 0-1.314-2.222H4.216c-.322 0-.632-.135-.86-.374A1.312 1.312 0 0 1 3 13.277v-2.556c0-.339.128-.664.356-.903.228-.24.538-.375.86-.375h1.035a1.5 1.5 0 0 0 1.314-2.222l-.563-1.026a1.322 1.322 0 0 1-.122-.97c.04-.163.112-.315.21-.448.097-.133.218-.245.357-.329l2.106-1.278c.28-.17.612-.215.923-.127.312.087.577.301.739.595l.47.858a1.5 1.5 0 0 0 2.63 0l.472-.858c.16-.293.426-.507.738-.595.31-.088.643-.042.922.127l2.108 1.278c.279.17.482.448.566.775.083.327.04.676-.121.97l-.566 1.028a1.5 1.5 0 0 0 1.315 2.222h1.035c.322 0 .632.135.86.375.228.24.356.564.356.903v2.556c0 .339-.128.664-.356.903-.228.24-.538.375-.86.375h-1.035a1.5 1.5 0 0 0-1.315 2.222l.564 1.027c.162.294.205.643.122.97a1.271 1.271 0 0 1-.567.776l-2.106 1.278c-.28.17-.611.215-.923.127a1.226 1.226 0 0 1-.739-.596Z'
		/>
		<circle
			cx={12}
			cy={12}
			r={2.25}
			stroke='var(--main-accent)'
			strokeWidth={1.5}
		/>
	</svg>
);

export default SvgComponent;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={25}
		height={24}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M3.937 11.21a2.75 2.75 0 0 1 3.196-2.217l6.113 1.107a2.75 2.75 0 0 1 2.216 3.196l-1.107 6.113a2.75 2.75 0 0 1-3.196 2.216L5.046 20.52a2.75 2.75 0 0 1-2.216-3.196l1.107-6.114Zm2.928-.74a1.25 1.25 0 0 0-1.452 1.007L4.306 17.59a1.25 1.25 0 0 0 1.007 1.453l6.114 1.106a1.25 1.25 0 0 0 1.452-1.007l1.107-6.113a1.25 1.25 0 0 0-1.008-1.453l-6.113-1.107Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M9.968 6.024a2.75 2.75 0 0 1 2.413-3.07l6.154-.712a2.75 2.75 0 0 1 3.048 2.415l.716 6.195a2.75 2.75 0 0 1-2.384 3.043l-2.862.365a.75.75 0 0 1-.19-1.488l2.862-.364a1.25 1.25 0 0 0 1.084-1.384l-.716-6.194a1.25 1.25 0 0 0-1.386-1.098l-6.154.711a1.25 1.25 0 0 0-1.097 1.396l.294 2.362a.75.75 0 0 1-1.489.185l-.293-2.362Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			d='M7.862 12.263a.75.75 0 1 1-1.476-.267.75.75 0 0 1 1.476.267ZM12.955 13.185a.75.75 0 1 1-1.476-.267.75.75 0 0 1 1.476.267ZM6.914 17.499a.75.75 0 1 1-1.476-.267.75.75 0 0 1 1.476.267ZM12.007 18.42a.75.75 0 1 1-1.476-.267.75.75 0 0 1 1.476.268ZM9.884 15.443a.75.75 0 1 1-1.476-.267.75.75 0 0 1 1.476.267ZM14.01 5.873a.75.75 0 1 1-1.49.172.75.75 0 0 1 1.49-.172ZM19.763 10.564a.75.75 0 1 1-1.49.172.75.75 0 0 1 1.49-.172ZM16.867 8.33a.75.75 0 1 1-1.49.172.75.75 0 0 1 1.49-.172Z'
		/>
	</svg>
);

export default SvgComponent;

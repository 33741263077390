import { useEffect } from 'react';

export default function useOutsideAlerter(ref: any, condition = true, func: () => void) {
	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (ref.current && !ref.current.contains(event.target) && condition) {
				if (func) {
					func();
				}
			}
			// event.stopPropagation()
		};

		condition && document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [ref, condition]);
}

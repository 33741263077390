import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={20}
		height={20}
		fill='none'
		{...props}
	>
		<path
			fill='#6D7380'
			d='M10.834 13.527a1.666 1.666 0 1 0-1.667 0V15a.833.833 0 1 0 1.667 0v-1.473Z'
		/>
		<path
			fill='#6D7380'
			fillRule='evenodd'
			d='M5.417 5.417a4.583 4.583 0 1 1 9.167 0V7.09c.228.006.436.015.626.03.469.038.9.12 1.304.327.627.32 1.137.83 1.456 1.456.206.405.289.835.327 1.304.037.45.037 1.004.037 1.675v2.07c0 .67 0 1.224-.037 1.675-.038.468-.12.899-.327 1.303a3.333 3.333 0 0 1-1.456 1.457c-.405.206-.835.288-1.304.326-.45.037-1.004.037-1.675.037h-7.07c-.67 0-1.224 0-1.675-.037-.468-.038-.899-.12-1.303-.326A3.333 3.333 0 0 1 2.03 16.93c-.206-.404-.288-.835-.326-1.303-.037-.451-.037-1.005-.037-1.676v-2.069c0-.67 0-1.224.037-1.675.038-.469.12-.9.326-1.304.32-.627.83-1.137 1.457-1.456.404-.206.835-.289 1.303-.327.19-.015.4-.024.627-.03V5.417Zm7.5 0v1.666H7.084V5.417a2.917 2.917 0 1 1 5.833 0ZM4.927 8.78c-.366.03-.553.084-.683.15-.314.16-.569.415-.729.73-.066.13-.12.316-.15.682-.03.375-.031.86-.031 1.574v2c0 .713 0 1.199.03 1.574.03.365.085.552.151.682.16.314.415.569.729.729.13.066.317.12.682.15.375.03.86.031 1.574.031h7c.714 0 1.2 0 1.575-.03.365-.03.552-.085.682-.151.314-.16.569-.415.728-.729.067-.13.12-.317.15-.682.031-.375.032-.86.032-1.574v-2c0-.714 0-1.2-.031-1.574-.03-.366-.084-.553-.15-.683a1.667 1.667 0 0 0-.729-.728c-.13-.067-.317-.12-.682-.15-.376-.031-.86-.032-1.575-.032h-7c-.713 0-1.199 0-1.574.031Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={20}
		height={20}
		fill='none'
		{...props}
	>
		<path
			fill='#fff'
			fillRule='evenodd'
			d='M12.82 3.846a1.042 1.042 0 0 1 0 1.473L8.14 10l4.68 4.68a1.042 1.042 0 0 1-1.473 1.474L5.93 10.736a1.042 1.042 0 0 1 0-1.473l5.417-5.417a1.042 1.042 0 0 1 1.473 0Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

const saveFieldsForProvider = (providerId: number, fields: Record<string, unknown>) => {
	const accountNum = {
		providerId: providerId,
		fields: fields
	};

	const walletsNumbers = window?.localStorage.getItem('wallets_numbers');

	if (walletsNumbers) {
		const json: Array<{ providerId: number; fields: Record<string, unknown> }> =
			JSON.parse(walletsNumbers);

		if (json && json?.length > 0) {
			const findWallet = json?.find((e) => e?.providerId === providerId);

			if (findWallet) {
				window?.localStorage.setItem(
					'wallets_numbers',
					JSON.stringify(
						json?.map((w) => {
							if (w.providerId === providerId) {
								return { ...w, fields: fields };
							}

							return w;
						})
					)
				);
			} else {
				window?.localStorage.setItem(
					'wallets_numbers',
					JSON.stringify([...json, accountNum])
				);
			}
		}
	} else {
		window?.localStorage.setItem('wallets_numbers', JSON.stringify([accountNum]));
	}
};

export default saveFieldsForProvider;

const sendAnalyticsHandler = (event: string, params?: Record<string, unknown>) => {
	if (window?.gtag) {
		window?.gtag('event', event, params);
	}

	if (window?.ym) {
		window?.ym(97429474, 'reachGoal', event, params);
	}
};

export default sendAnalyticsHandler;

import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';

import { LocalizeText, UseLocales } from '@types';

// path - путь до translation.json из корня языка
// node - поле первой вложенности translation.json (название самой старшей родительской компоненты, где идет локализация)
// useSuspense - todo дать коммент

export const useLocales: UseLocales = (opts) => {
	const { path, node = '', useSuspense = false } = opts;

	const { t } = useTranslation(`${path}/translation`, { useSuspense });

	const localizeText: LocalizeText = useCallback(
		(key: string, values?: Record<string, unknown>) => {
			const finalKey = isEmpty(node) ? key : `${node}.${key}`;

			return t(finalKey, values);
		},
		[t, node]
	);

	return { localizeText };
};

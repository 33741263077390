import Lottie from 'lottie-react';
import React, { useState, useEffect } from 'react';

export const LottieComponent = ({
	path,
	style
}: {
	path: string;
	style: React.CSSProperties;
}) => {
	const [animationData, setAnimationData] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			const correctPath = new URL(`${path}`, import.meta.url).href;

			const lottieJson = await fetch(correctPath)
				.then((data) => data.json())
				.catch((e) => console.error(e));

			setAnimationData(lottieJson);
		};

		fetchData();
	}, []);

	return (
		<Lottie
			animationData={animationData}
			loop={true}
			style={style}
		></Lottie>
	);
};

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M8.743 3.9a.75.75 0 0 1-.644.843l-2.112.282a.75.75 0 0 0-.631.914l1.374 5.89a.75.75 0 0 1-1.46.341L3.894 6.28a2.25 2.25 0 0 1 1.894-2.742L7.9 3.257a.75.75 0 0 1 .842.644ZM17.763 11.039a.75.75 0 0 1 .948.474l1.7 5.1a2.25 2.25 0 0 1-1.743 2.928l-2.538.448a.75.75 0 1 1-.26-1.478l2.537-.447a.75.75 0 0 0 .582-.976l-1.7-5.1a.75.75 0 0 1 .474-.95Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='m17.91 5.15-7.603-2.037a1 1 0 0 0-1.225.707L5.383 17.625a1 1 0 0 0 .707 1.225l7.603 2.037a1 1 0 0 0 1.225-.707l3.699-13.805a1 1 0 0 0-.707-1.225Zm-7.215-3.486a2.5 2.5 0 0 0-3.062 1.768L3.934 17.237A2.5 2.5 0 0 0 5.702 20.3l7.603 2.037a2.5 2.5 0 0 0 3.061-1.768l3.7-13.805a2.5 2.5 0 0 0-1.768-3.062l-7.603-2.037Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='M15.543 6.907a.893.893 0 0 0-1.755-.258c-.178.663.044 1.247.24 1.603.202.367.447.623.527.702a.924.924 0 0 0 .923.228c.108-.033.43-.141.77-.366.334-.223.773-.62.943-1.255a.893.893 0 0 0-1.648-.654ZM8.042 14.98c.338-.226.661-.335.77-.368.324-.099.679-.014.922.229.08.08.325.335.527.701.196.357.418.94.24 1.603a.893.893 0 0 1-1.755-.257.893.893 0 0 1-1.648-.655c.17-.635.61-1.032.944-1.254Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={20}
		height={21}
		fill='none'
		{...props}
	>
		<path
			fill='#6EA644'
			d='M20 10.5c0 5.523-4.477 10-10 10s-10-4.477-10-10S4.477.5 10 .5s10 4.477 10 10Z'
		/>
		<path
			fill='#FFDB44'
			d='m10.25 4.5 8.25 6-8.25 6-8.25-6 8.25-6Z'
		/>
		<path
			fill='#0051B5'
			d='M13.75 10.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z'
		/>
		<path
			fill='#fff'
			d='M7.087 9a3.486 3.486 0 0 0-.337 1.5c3.6-.6 5.688 1.167 6.281 2.125.355-.464.597-1.02.684-1.625-2-2-5.252-2.167-6.628-2Z'
		/>
	</svg>
);

export default SvgComponent;

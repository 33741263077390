import { createSlice } from '@reduxjs/toolkit';

import * as reducers from './reducers';

import { Currencies, IUserInitialState, Languages, Roles } from '@types';

const initialState: IUserInitialState = {
	id: 0,
	username: '',
	email: '',
	image: '',
	balance: 0,
	bonusBalance: 0,
	createdAt: '',
	userLevelId: 0,
	userLevelSum: 0,
	role: Roles?.player,
	phone: '',
	locale: '',
	telegramChannelBotLink: '',
	currency: Currencies.bdt,
	hasPassword: false,
	langCode: Languages.ru,
	isVerified: false,
	isPremium: false,
	referralCode: '',
	successInvoiceCount: 0,
	isPhoneVerified: false,
	passwordChangeAt: '',
	editingSettings: [],
	updMeta: {},
	socialsLinked: { vk: false, telegram: false },
	userLevels: [],
	isWithdrawEnabled: true,
	isReferralFroze: false,

	bonusBalanceEnableValue: 0,
	bonusBalanceMaxValue: 0,
	bonusBalanceWager: 0,
	bonusUpdateDate: null,
	locationData: null,

	depositWager: 0
};

export const { actions, reducer } = createSlice({
	name: 'user',
	initialState,
	reducers
});

import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		{...props}
	>
		<path
			fill='#A0A4AD'
			data-id='changed-color'
			fillRule='evenodd'
			d='M3.25 9.5A.75.75 0 0 1 4 8.75h4a.75.75 0 1 1 0 1.5H4.75v5A4.25 4.25 0 0 0 9 19.5h6a4.25 4.25 0 0 0 4.25-4.25v-5H12a.75.75 0 0 1 0-1.5h8a.75.75 0 0 1 .75.75v5.75A5.75 5.75 0 0 1 15 21H9a5.75 5.75 0 0 1-5.75-5.75V9.5ZM5 6.75a.75.75 0 0 0-.75.75.75.75 0 0 1-1.5 0A2.25 2.25 0 0 1 5 5.25h14a2.25 2.25 0 0 1 2.25 2.25.75.75 0 0 1-1.5 0 .75.75 0 0 0-.75-.75H5Z'
			clipRule='evenodd'
		/>
		<path
			fill='#A0A4AD'
			data-id='changed-color'
			fillRule='evenodd'
			d='M7.25 4.805c0-1.375 1.512-2.213 2.678-1.484l2.47 1.543a.75.75 0 1 1-.796 1.272l-2.47-1.543a.25.25 0 0 0-.382.212v.445a.75.75 0 0 1-1.5 0v-.445Z'
			clipRule='evenodd'
		/>
		<path
			fill='#A0A4AD'
			data-id='changed-color'
			fillRule='evenodd'
			d='M16.75 4.805c0-1.375-1.512-2.213-2.678-1.484l-2.47 1.543a.75.75 0 1 0 .796 1.272l2.47-1.543a.25.25 0 0 1 .382.212v.445a.75.75 0 0 0 1.5 0v-.445Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='M10 8.75a.75.75 0 0 1 .75.75v3.287l.915-.458a.75.75 0 0 1 .67 0l.915.457V11.25a.75.75 0 0 1 1.5 0V14a.75.75 0 0 1-1.085.67L12 13.839l-1.665.833A.75.75 0 0 1 9.25 14V9.5a.75.75 0 0 1 .75-.75Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

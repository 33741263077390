import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={25}
		viewBox='0 0 24 25'
		fill='none'
		{...props}
	>
		<rect
			width={24}
			height={24}
			y={0.5}
			fill='var(--main-accent)'
			rx={6}
		/>
		<path
			stroke='#fff'
			strokeLinecap='round'
			strokeWidth={1.5}
			d='m15 9.5-6 6m0-6 6 6'
		/>
	</svg>
);

export default SvgComponent;

import cn from 'classnames';
import React, { useState, useEffect } from 'react';
import s from './style.module.scss';

type PropsTypes = {
	isDefault: boolean;
	imageUrl: string;
};

const BackgroundImageWithFadeIn = (props: PropsTypes) => {
	const { imageUrl, isDefault } = props;

	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		const img = new Image();

		img.src = imageUrl;
		img.onload = () => setLoaded(true);
	}, [imageUrl]);

	return (
		<div className={s.wrapper}>
			<div
				className={cn(s.bgContainer, { [s.loaded]: loaded, [s.default]: isDefault })}
				style={{ backgroundImage: `url(${imageUrl})` }}
			/>
		</div>
	);
};

export default BackgroundImageWithFadeIn;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		{...props}
	>
		<path
			fill='#fff'
			fillRule='evenodd'
			d='M12.366 19.384a1.25 1.25 0 0 1 0-1.768L17.982 12l-5.616-5.616a1.25 1.25 0 0 1 1.768-1.768l6.5 6.5a1.25 1.25 0 0 1 0 1.768l-6.5 6.5a1.25 1.25 0 0 1-1.768 0Z'
			clipRule='evenodd'
		/>
		<path
			fill='#fff'
			fillRule='evenodd'
			d='M4 19.384a1.25 1.25 0 0 1 0-1.768L9.616 12 4 6.384a1.25 1.25 0 0 1 1.768-1.768l6.5 6.5a1.25 1.25 0 0 1 0 1.768l-6.5 6.5a1.25 1.25 0 0 1-1.768 0Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

import { getGamesByCategories, setGamesByCategories } from '@lib/browser-storage';
import { GameProviderService } from './GameService';
import {
	setProviderGames,
	addProviderGames
} from '@store/reducers/game-provider/dispatchers';
import { IProviderGame, ProviderGamesType } from '@types';

export type GamesRequestOptions = {
	limit: number;
	page: number;
	filter?: any;
	s?: any;
	sortByFavourite?: boolean;
};

type GetProviderGames = (
	params: GamesRequestOptions,
	isSet: boolean, // true - если необходимо перезаписать стейт, false - если добавляем
	providerGamesType: ProviderGamesType, // выбираем поле стейта, куда пойдут игры
	setPageCount?: (count: number) => void,
	setFullyLoaded?: (boolean: boolean) => void
) => Promise<IProviderGame[]>;

export const getGames: GetProviderGames = async (
	params,
	isSet,
	providerGamesType,
	setPageCount,
	setFullyLoaded
) => {
	const {
		status,
		data: { data: games, pageCount: pageCount, page: page }
	} = await GameProviderService.requestGames(params);

	const action = isSet ? setProviderGames : addProviderGames;

	if (status === 200) {
		setPageCount && setPageCount(pageCount);

		setFullyLoaded && setFullyLoaded(page >= pageCount);

		if (params?.filter) {
			const existingGames = getGamesByCategories(params?.filter?.toString());

			if (games?.length > 0) {
				if (existingGames && !isSet) {
					const existingGamesJSON: {
						page: number;
						games: IProviderGame[];
						isFullyLoaded: boolean;
					} = JSON.parse(existingGames);

					if (existingGamesJSON) {
						setGamesByCategories(
							params?.filter?.toString(),
							JSON.stringify({
								page,
								games: [...existingGamesJSON.games, ...games],
								isFullyLoaded: page >= pageCount
							})
						);
					}
				} else {
					setGamesByCategories(
						params?.filter?.toString(),
						JSON.stringify({ page, games, isFullyLoaded: page >= pageCount })
					);
				}
			}
		}

		await action({ providerGames: games, providerGamesType });

		return games;
	}

	return [];
};

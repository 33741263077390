import * as React from 'react';
import { SVGProps } from 'react';
export const GoTopArrowSvg = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={25}
		height={25}
		viewBox='0 0 20 20'
		fill='none'
		{...props}
	>
		<path
			fill='#A0A4AD'
			fillRule='evenodd'
			d='M16.153 9.695a1.042 1.042 0 0 1-1.473 0L10 5.015l-4.68 4.68a1.042 1.042 0 1 1-1.473-1.473l5.416-5.417a1.042 1.042 0 0 1 1.473 0l5.417 5.417a1.042 1.042 0 0 1 0 1.473Z'
			clipRule='evenodd'
		/>
		<path
			fill='#A0A4AD'
			fillRule='evenodd'
			d='M16.153 16.667a1.042 1.042 0 0 1-1.473 0L10 11.987l-4.68 4.68a1.042 1.042 0 1 1-1.473-1.474l5.416-5.416a1.042 1.042 0 0 1 1.473 0l5.417 5.417a1.042 1.042 0 0 1 0 1.473Z'
			clipRule='evenodd'
		/>
	</svg>
);

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		{...props}
	>
		<path
			fill='#A0A4AD'
			data-id='changed-color'
			fillRule='evenodd'
			d='M1.75 9.5A3.25 3.25 0 0 1 5 6.25h14a3.25 3.25 0 0 1 3.25 3.25v8A3.25 3.25 0 0 1 19 20.75h-6.25a.75.75 0 0 1 0-1.5H19a1.75 1.75 0 0 0 1.75-1.75v-8A1.75 1.75 0 0 0 19 7.75H5A1.75 1.75 0 0 0 3.25 9.5v8c0 .967.784 1.75 1.75 1.75h2.25a.75.75 0 0 1 0 1.5H5a3.25 3.25 0 0 1-3.25-3.25v-8Z'
			clipRule='evenodd'
		/>
		<path
			fill='#A0A4AD'
			data-id='changed-color'
			fillRule='evenodd'
			d='M18.25 6.135a2.25 2.25 0 0 0-2.676-2.21L6.61 5.655A1.37 1.37 0 0 0 5.5 7H4a2.87 2.87 0 0 1 2.326-2.818l8.963-1.73a3.75 3.75 0 0 1 4.461 3.683V6.5h-1.5v-.365ZM15.25 13.5a.75.75 0 0 1 .75-.75h2.5a.75.75 0 0 1 0 1.5H16a.75.75 0 0 1-.75-.75Z'
			clipRule='evenodd'
		/>
		<path
			fill='#FF2F2F'
			fillRule='evenodd'
			d='M7.625 13.125a2.25 2.25 0 0 0 2.25 2.25h.44l-.72-.72a.75.75 0 1 1 1.06-1.06l2 2a.75.75 0 0 1 0 1.06l-2 2a.75.75 0 1 1-1.06-1.06l.72-.72h-.44a3.75 3.75 0 0 1-3.75-3.75V11.5a.75.75 0 0 1 1.5 0v1.625Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

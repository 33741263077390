import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M9.284 6.635c-.64-1.2-.7-2.266-.408-2.971.27-.65.908-1.164 2.124-1.164h2A.75.75 0 0 0 13 1h-2c-1.708 0-2.968.78-3.51 2.09-.518 1.252-.303 2.806.477 4.264a.75.75 0 0 0 .013.023l.871 1.5a.75.75 0 0 0 1.297-.754l-.864-1.488Zm1.063 8.372a.75.75 0 0 1 .64.846 46.467 46.467 0 0 1-.706 3.945c-.08.351-.16.671-.238.952H15a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.67-1.085c.079-.16.263-.718.49-1.703.219-.954.463-2.245.681-3.815a.75.75 0 0 1 .846-.64Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M14.716 6.635c.64-1.2.7-2.266.408-2.971-.27-.65-.908-1.164-2.124-1.164A.75.75 0 1 1 13 1c1.708 0 2.968.78 3.51 2.09.518 1.252.303 2.806-.478 4.264a.907.907 0 0 1-.012.023l-.872 1.5a.75.75 0 0 1-1.297-.754l.865-1.488Zm-1.063 8.372a.75.75 0 0 0-.64.846c.223 1.61.475 2.943.706 3.945.222.97.44 1.696.61 2.037a.75.75 0 0 0 1.342-.67c-.08-.16-.264-.718-.49-1.703a44.984 44.984 0 0 1-.682-3.815.75.75 0 0 0-.846-.64Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='M4.75 10.059c0-.943.97-1.5 1.772-1.171 1.542.632 3.65 1.168 4.635 1.379a.75.75 0 1 1-.314 1.466c-.96-.205-2.973-.712-4.593-1.34v3.263c.709-.228 1.565-.44 2.372-.601.89-.178 1.783-.305 2.378-.305h2a.75.75 0 0 1 0 1.5h-2c-.441 0-1.215.102-2.085.276-.86.17-1.754.4-2.422.63-.784.27-1.743-.272-1.743-1.215v-3.882Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='M19.25 10.059c0-.943-.97-1.5-1.772-1.171-1.542.632-3.65 1.168-4.635 1.379a.75.75 0 1 0 .314 1.466c.96-.205 2.973-.712 4.593-1.34v3.263a22.37 22.37 0 0 0-2.371-.601c-.892-.178-1.784-.305-2.379-.305a.75.75 0 0 0 0 1.5c.441 0 1.215.102 2.085.276.86.17 1.753.4 2.422.63.784.27 1.743-.272 1.743-1.215v-3.882Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

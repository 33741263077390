import { setAuthToken } from '@lib/browser-storage';
import { AuthService } from '@lib/api/auth/AuthService';
import { getCurrentUserData } from '@lib/api/user';
import { setIsAuthPending } from '@store/reducers/auth/dispathcers';
import { getFreeSpinsQty } from '../bonus/getFreeSpinsQty';
import { AnalyticsEventType } from '@types';
import { sendAnalyticsHandler } from '@utils';

export const signIn = async (
	params: {
		email?: string;
		phone?: string;
		password?: string;
		isApp?: boolean;
	},
	setFieldError?: (field: string, error: string) => void,
	successFunc?: () => void,
	increaseAttemptsHandler?: () => void
) => {
	setIsAuthPending(true);

	try {
		const res = await AuthService.signIn(params);

		// TODOрефакт.
		if (res.status === 200) {
			sendAnalyticsHandler(AnalyticsEventType?.singin_success);
			if (res?.data?.accessToken) {
				await setAuthToken(res.data.accessToken);
				await getCurrentUserData(() => successFunc && successFunc());
				await getFreeSpinsQty();
			}
		}
	} catch (error: any) {
		increaseAttemptsHandler && increaseAttemptsHandler();
		if (
			error?.response?.status === 400 &&
			error?.response?.data?.message === 'User already exist'
		) {
			setFieldError && setFieldError('phone', 'already_used_phone');
			setFieldError && setFieldError('email', 'already_used_email');
		}

		if (
			error?.response?.status === 404 &&
			error?.response?.data?.message === 'USER_NOT_FOUND'
		) {
			setFieldError && setFieldError('phone', 'bad_password_account');
			setFieldError && setFieldError('email', 'bad_password_account');
			setFieldError && setFieldError('password', 'bad_password_account');
		}

		if (
			error?.response?.status === 422 &&
			error?.response?.data?.message[0]?.constraints?.isEmail === 'email must be an email'
		) {
			setFieldError && setFieldError('email', 'invalid_email');
		}

		if (
			error?.response?.status === 422 &&
			error?.response?.data?.message[0]?.constraints?.isPhoneNumber ===
				'phone must be a valid phone number'
		) {
			setFieldError && setFieldError('phone', 'invalid_phone');
		}

		sendAnalyticsHandler(AnalyticsEventType?.singin_error, {
			singin_error: error?.response?.data?.message?.toString(),
			singin_error_mail: params?.email,
			singin_error_phone: params?.phone
		});

		setIsAuthPending(false);
	}
};

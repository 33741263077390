import { bindActions } from '@utils';
import { actions } from './reducer';

export const {
	setAuthType,
	auth,
	setReceivedCredentials,
	setIsResetPasswordMode,
	setIsAuthPending,
	setAuthToken,
	setnNextAttemptResetPassword,
	setIsSendedConfirm
} = bindActions(actions);

import * as React from 'react';
import { SVGProps } from 'react';

export const CommentSvg = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={21}
		height={20}
		fill='none'
		{...props}
	>
		<path
			fill='#fff'
			fillRule='evenodd'
			d='M2.93 5.17c-.18.526-.18 1.177-.18 2.48v8.305c0 .652 0 .978.086 1.153a.833.833 0 0 0 1.015.42c.184-.063.415-.293.876-.754.374-.374.56-.561.767-.712a3.334 3.334 0 0 1 1.462-.606c.253-.039.517-.039 1.046-.039h5.015c1.303 0 1.955 0 2.48-.18a3.334 3.334 0 0 0 2.073-2.074c.18-.524.18-1.176.18-2.48V7.65c0-1.303 0-1.954-.18-2.48a3.333 3.333 0 0 0-2.074-2.073c-.524-.18-1.176-.18-2.48-.18H7.484c-1.303 0-1.954 0-2.48.18A3.333 3.333 0 0 0 2.93 5.17Zm3.57 5.247a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Zm5-1.25a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0Zm2.5 1.25a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Z'
			clipRule='evenodd'
		/>
	</svg>
);

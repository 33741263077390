import * as React from 'react';
import { SVGProps } from 'react';

export const CommentNewOnlineSvg = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={24}
		height={24}
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M2.5 8.99555C2.5 7.34482 2.5 6.51945 2.72764 5.85457C3.15031 4.62004 4.12004 3.65031 5.35457 3.22764C6.01945 3 6.84482 3 8.49555 3H15.5044C17.1552 3 17.9805 3 18.6454 3.22764C19.88 3.65031 20.8497 4.62004 21.2724 5.85457C21.5 6.51945 21.5 7.34482 21.5 8.99555V12.8378C21.5 13.2769 21.5 13.4964 21.436 13.6104C21.357 13.7512 21.2755 13.8131 21.1186 13.8514C20.9916 13.8825 20.7315 13.8099 20.2111 13.6649C19.8258 13.5574 19.4196 13.5 19 13.5C16.5147 13.5 14.5 15.5147 14.5 18C14.5 18.2739 14.5 18.4108 14.4766 18.4846C14.4213 18.6585 14.3252 18.7547 14.1513 18.8099C14.0775 18.8333 13.9646 18.8333 13.7389 18.8333H9.15237C8.4825 18.8333 8.14756 18.8333 7.82778 18.8826C7.15764 18.986 6.52225 19.2491 5.97534 19.6499C5.71435 19.8412 5.47749 20.0781 5.00382 20.5517C4.41969 21.1359 4.12761 21.4279 3.8941 21.507C3.39295 21.6767 2.84358 21.4492 2.60921 20.9748C2.5 20.7538 2.5 20.3407 2.5 19.5146V8.99555ZM8.83334 10.9167C8.83334 11.7911 8.12446 12.5 7.25001 12.5C6.37555 12.5 5.66667 11.7911 5.66667 10.9167C5.66667 10.0422 6.37555 9.33334 7.25001 9.33334C8.12446 9.33334 8.83334 10.0422 8.83334 10.9167ZM12 12.5C12.8744 12.5 13.5833 11.7911 13.5833 10.9167C13.5833 10.0422 12.8744 9.33334 12 9.33334C11.1255 9.33334 10.4167 10.0422 10.4167 10.9167C10.4167 11.7911 11.1255 12.5 12 12.5ZM18.3333 10.9167C18.3333 11.7911 17.6245 12.5 16.75 12.5C15.8756 12.5 15.1667 11.7911 15.1667 10.9167C15.1667 10.0422 15.8756 9.33334 16.75 9.33334C17.6245 9.33334 18.3333 10.0422 18.3333 10.9167Z'
			fill='inherit'
		/>
		<path
			d='M22 18C22 19.6569 20.6569 21 19 21C17.3431 21 16 19.6569 16 18C16 16.3431 17.3431 15 19 15C20.6569 15 22 16.3431 22 18Z'
			fill='inherit'
		/>
		<path
			d='M22 18C22 19.6569 20.6569 21 19 21C17.3431 21 16 19.6569 16 18C16 16.3431 17.3431 15 19 15C20.6569 15 22 16.3431 22 18Z'
			fill='#FF2400'
		/>
	</svg>
);

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M9.796 6.725a.75.75 0 0 1-.032 1.06c-.293.275-.433.42-.547.54l-.07.076c-.096.103-.202.217-.37.383-.497.49-1.112.863-1.69 1.17-.284.15-.572.29-.841.422l-.023.011c-.28.138-.539.265-.776.393-.496.267-.796.485-.947.685a.458.458 0 0 0-.098.213.625.625 0 0 0 .05.309c.244.649.482.908.647 1.017.149.099.333.139.644.106.237-.025.463-.078.742-.144.14-.033.293-.07.469-.107.474-.101 1.047-.193 1.657-.1.409.06.797.16 1.142.253l.192.053c.276.075.516.14.75.188.567.116.868.075 1.105-.103a.75.75 0 0 1 1.198.546c.277 3.87-2.223 6.59-3.968 8.334a.75.75 0 0 1-1.06-1.06c1.561-1.562 3.318-3.54 3.528-6.163a3.805 3.805 0 0 1-1.104-.084c-.286-.059-.584-.14-.861-.216l-.171-.046a8.717 8.717 0 0 0-.974-.22c-.337-.05-.696-.006-1.12.085-.101.021-.213.048-.333.076-.324.076-.7.165-1.035.2-.503.053-1.083.015-1.63-.348-.532-.352-.922-.939-1.222-1.74a2.11 2.11 0 0 1-.131-1.049c.05-.35.195-.65.385-.903.362-.48.92-.826 1.433-1.103.268-.144.552-.283.828-.419l.015-.007c.275-.135.544-.266.806-.405.54-.286 1-.577 1.34-.912.143-.142.226-.23.318-.33l.082-.088c.137-.145.299-.311.612-.606a.75.75 0 0 1 1.06.033ZM14.522 4.808a.75.75 0 0 1 .917-.533c1.64.435 3.137 1.429 4.19 3.624 1.025 2.139 1.62 5.394 1.62 10.351a.75.75 0 0 1-1.5 0c0-4.877-.592-7.865-1.473-9.702-.855-1.782-1.985-2.496-3.221-2.823a.75.75 0 0 1-.533-.917Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M13.772 7.568a.75.75 0 0 1 .91-.546 3.613 3.613 0 0 1 1.65.887c.48.455.855 1.06 1.149 1.82.579 1.5.893 3.731 1.018 6.992A.75.75 0 1 1 17 16.78c-.124-3.24-.435-5.258-.919-6.509-.237-.614-.503-1.01-.78-1.273a2.116 2.116 0 0 0-.983-.52.75.75 0 0 1-.546-.909Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='M6.088 1.648a.75.75 0 0 1 .907-.357l6.5 2.25a.75.75 0 1 1-.49 1.418l-1.968-.681L13.2 5.9a.75.75 0 0 1-.9 1.2l-6-4.5a.75.75 0 0 1-.212-.952Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

/* eslint-disable consistent-return */
import { useEffect } from 'react';
import type { Blocker, Transition } from 'history';
import history from './history';

export default function useBlocker(blocker: Blocker, when = true): void {
	useEffect(() => {
		if (!when) return;

		const unblock = history.block((tx: Transition) => {
			const autoUnblockingTx = {
				...tx,
				retry() {
					unblock();
					tx.retry();
				}
			};

			blocker(autoUnblockingTx);
		});

		return unblock;
	}, [blocker, when]);
}

import * as React from 'react';
import { SVGProps } from 'react';

export const FavoriteSvg = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={22}
		height={21}
		viewBox='0 0 22 21'
		fill='none'
		{...props}
	>
		<path
			fill='#A0A4AD'
			fillRule='evenodd'
			d='M9.395 1.689c.609-1.405 2.601-1.405 3.21 0l2.006 4.624a.25.25 0 0 0 .206.15l5.018.478c1.524.145 2.14 2.04.992 3.054l-3.779 3.336a.25.25 0 0 0-.078.242l1.096 4.92c.333 1.495-1.28 2.666-2.598 1.888l-4.34-2.563a.25.25 0 0 0-.255 0l-4.34 2.563c-1.32.778-2.932-.393-2.599-1.888l1.096-4.92a.25.25 0 0 0-.078-.242L1.173 9.995C.025 8.98.641 7.086 2.165 6.94l5.019-.478a.25.25 0 0 0 .205-.15L9.395 1.69ZM11 3.013 9.224 7.11a2.25 2.25 0 0 1-1.85 1.345l-4.445.423 3.347 2.955a2.25 2.25 0 0 1 .707 2.176l-.971 4.357 3.844-2.27a2.25 2.25 0 0 1 2.288 0l3.844 2.27-.97-4.357a2.25 2.25 0 0 1 .707-2.176l3.346-2.955-4.444-.423a2.25 2.25 0 0 1-1.85-1.345L11 3.013Z'
			clipRule='evenodd'
		/>
	</svg>
);

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M2.572 6.334c-.211.35-.065.799.3.986.364.186.808.04 1.022-.308a9.517 9.517 0 1 1-1.237 3.174c.078-.402-.15-.81-.545-.919a.704.704 0 0 0-.888.524 11 11 0 1 0 1.348-3.457Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M19.743 9.99c.103.394-.174.776-.577.841-.403.066-.778-.209-.89-.602a6.522 6.522 0 1 0-.042 3.687c.12-.39.502-.657.903-.582.401.075.67.462.558.855a8 8 0 1 1 .048-4.2Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M12 1.5a.75.75 0 0 1 .75.75V4.5a.75.75 0 0 1-1.5 0V2.25A.75.75 0 0 1 12 1.5ZM12 18.75a.75.75 0 0 1 .75.75v2.25a.75.75 0 0 1-1.5 0V19.5a.75.75 0 0 1 .75-.75ZM17.25 2.907a.75.75 0 0 1 .274 1.024L16.4 5.88a.75.75 0 1 1-1.299-.75l1.126-1.949a.75.75 0 0 1 1.024-.274ZM8.625 17.846A.75.75 0 0 1 8.9 18.87L7.775 20.82a.75.75 0 1 1-1.3-.75L7.6 18.12a.75.75 0 0 1 1.025-.274ZM21.093 6.75a.75.75 0 0 1-.274 1.025L18.87 8.9a.75.75 0 0 1-.75-1.3l1.949-1.125a.75.75 0 0 1 1.024.275ZM6.154 15.375a.75.75 0 0 1-.274 1.024L3.93 17.524a.75.75 0 0 1-.75-1.299L5.13 15.1a.75.75 0 0 1 1.024.275ZM5.25 12a.75.75 0 0 1-.75.75H2.25a.75.75 0 0 1 0-1.5H4.5a.75.75 0 0 1 .75.75ZM21.093 17.25a.75.75 0 0 1-1.024.274L18.12 16.4a.75.75 0 1 1 .75-1.299l1.949 1.126a.75.75 0 0 1 .274 1.024ZM17.25 21.093a.75.75 0 0 1-1.024-.274L15.1 18.87a.75.75 0 0 1 1.298-.75l1.125 1.949a.75.75 0 0 1-.274 1.024ZM8.625 6.154A.75.75 0 0 1 7.6 5.88L6.475 3.93a.75.75 0 1 1 1.3-.75L8.9 5.13a.75.75 0 0 1-.275 1.024Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='M12 6.75a.75.75 0 0 1 .75.75v3.625h3.625a.75.75 0 0 1 0 1.5H12.75v3.625a.75.75 0 0 1-1.5 0v-3.625H7.625a.75.75 0 0 1 0-1.5h3.625V7.5a.75.75 0 0 1 .75-.75Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			d='M14 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z'
		/>
	</svg>
);

export default SvgComponent;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={16}
		height={17}
		fill='none'
		{...props}
	>
		<path
			fill='#A0A4AD'
			d='M5.805 5.362a.667.667 0 1 0-.943.943L7.057 8.5l-2.195 2.195a.667.667 0 1 0 .943.943L8 9.443l2.195 2.195a.667.667 0 0 0 .943-.943L8.943 8.5l2.195-2.195a.667.667 0 0 0-.943-.943L8 7.557 5.805 5.362Z'
		/>
		<path
			fill='#A0A4AD'
			fillRule='evenodd'
			d='M8 1.167a7.333 7.333 0 1 0 0 14.666A7.333 7.333 0 0 0 8 1.167ZM2 8.5a6 6 0 1 1 12 0 6 6 0 0 1-12 0Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

import React, { useEffect, useRef, useState } from 'react';
import { useLocales, useOutsideAlerter } from '@lib/hooks';
import { CheckSvg, ShevronSvg } from '@components/svg';
import { isTapX, isTopXGo } from '@config/global';
import { userSelectors } from '@store/reducers/user/selectors';
import { useSelector } from 'react-redux';
import s from './style.module.scss';
import cn from 'classnames';

type CurrencySelectP = {
	className?: string;
	onChange: (currency: string) => void;
	analyticsFunc?: () => void;
	analyticsFuncChoose?: (val: string) => void;
};

export const CurrencySelect = ({
	className,
	onChange,
	analyticsFunc,
	analyticsFuncChoose
}: CurrencySelectP) => {
	const { localizeText } = useLocales({
		path: 'components/common/modals',
		node: 'AuthModal'
	});

	const { locationData } = useSelector(userSelectors.userInfo);

	const options =
		isTopXGo || isTapX
			? [{ value: 'BRL', description: localizeText('BRL') }]
			: [
					// { value: 'RUB', description: 'Российский рубль' },
					// { value: 'USD', description: 'Доллар США' },
					// { value: 'EUR', description: 'Евро' },
					{ value: 'BDT', description: localizeText('BDT') },
					{ value: 'INR', description: localizeText('INR') },
					{ value: 'BRL', description: localizeText('BRL') }
					// { value: 'BRL', description: 'Бразильский реал' }
				];

	const [isOptionsActive, setIsOptionsActive] = useState(false);

	const [selectedItem, setSelectedItem] = useState(options[0]?.value);

	const dropdown = useRef(null);

	useOutsideAlerter(dropdown, isOptionsActive, () =>
		setIsOptionsActive(!isOptionsActive)
	);

	const changeSelectedItem = (value: string) => {
		setIsOptionsActive(false);
		setSelectedItem(value);
	};

	useEffect(() => {
		if (locationData) {
			const item = options?.find((e) => e?.value === locationData?.currency);

			if (item) {
				setSelectedItem(item?.value);
			} else {
				setSelectedItem(options[0]?.value);
			}
		} else {
			setSelectedItem(options[0]?.value);
		}
	}, []);

	useEffect(() => {
		if (selectedItem) {
			onChange(selectedItem);
		}
	}, [selectedItem]);

	return (
		<div
			ref={dropdown}
			className={cn(s.currencySelect, className, {
				[s.currencySelectActive]: isOptionsActive
			})}
		>
			<div className={s.currencySelectTitle}>{localizeText('currency')}</div>
			<div
				className={s.currencySelectControl}
				onClick={() => {
					setIsOptionsActive(!isOptionsActive);

					analyticsFunc && analyticsFunc();
				}}
			>
				<div className={s.currencySelectValue}>
					<div className={s.currencySelectValueText}>{selectedItem}</div>
					<div className={s.currencySelectValueLabel}>
						{options?.find((e) => e?.value === selectedItem)?.description}
					</div>
				</div>
				<div
					className={cn(s.currencySelectArrow, {
						[s.currencySelectArrowActive]: isOptionsActive
					})}
				>
					<ShevronSvg />
				</div>
			</div>

			<div
				className={cn(s.currencySelectOptions, {
					[s.currencySelectOptionsActive]: isOptionsActive
				})}
			>
				{options.map((option) => (
					<div
						className={cn(s.currencySelectOption, {
							[s.currencySelectOptionSelected]: selectedItem === option?.value
						})}
						key={option?.value}
						onClick={() => {
							changeSelectedItem(option?.value);

							analyticsFuncChoose && analyticsFuncChoose(option?.value);
						}}
					>
						<div className={s.currencySelectOptionValue}>{option.value}</div>
						<div className={s.currencySelectOptionLabel}>{option.description}</div>
						{selectedItem === option?.value && (
							<div className={s.currencySelectOptionCheck}>
								<CheckSvg />
							</div>
						)}
					</div>
				))}
			</div>
		</div>
	);
};

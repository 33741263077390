import React, { useRef, useState } from 'react';
import s from './style.module.scss';
import cn from 'classnames';
import { Ripple } from '..';
import { SearchSvg, SubstractSvg } from '@components/svg';

type InputMode =
	| 'none'
	| 'text'
	| 'tel'
	| 'url'
	| 'email'
	| 'numeric'
	| 'decimal'
	| 'search';

type InputP = {
	name?: string;
	type: string;
	value: string;
	onChange: any;
	rippleAnimation?: boolean;
	placeholder?: string;
	isDisabled?: boolean;
	className?: string;
	containerClassName?: string;
	pattern?: string;
	inputMode?: InputMode;
	onCloseHandler?: () => void;
};

export const SearchInput = (props: InputP) => {
	const {
		name = undefined,
		value,
		type,
		onChange,
		isDisabled = false,
		rippleAnimation = false,
		placeholder = '',
		className = '',
		pattern = '',
		onCloseHandler,
		containerClassName = ''
	} = props;

	const [isFocused, setIsFocused] = useState(false);

	const input = useRef<any>(null);

	const onRippleClickHandler = () => {
		if (input) {
			input?.current?.focus();
		}
	};

	return (
		<div className={cn(s.inputContainer, containerClassName)}>
			<SearchSvg className={cn(s.searchImage, { [s.focused]: isFocused })} />

			<input
				ref={input}
				name={name}
				disabled={isDisabled}
				type={type}
				value={value}
				pattern={pattern}
				placeholder={placeholder}
				onChange={(e) => onChange(e.currentTarget.value, 'input')}
				className={cn(s.input, className, { [s.focused]: isFocused })}
				required={false}
				autoComplete='off'
				onFocus={() => setIsFocused(true)}
				onBlur={() => setIsFocused(false)}
			/>

			{value && (
				<button
					onClick={onCloseHandler}
					className={s.closeBtn}
					type='button'
				>
					<SubstractSvg />

					{rippleAnimation && <Ripple />}
				</button>
			)}

			{rippleAnimation && <Ripple handler={onRippleClickHandler} />}
		</div>
	);
};

import * as React from 'react';
import { SVGProps } from 'react';

export const CommentNewSvg = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={24}
		height={24}
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M2.72764 5.85457C2.5 6.51945 2.5 7.34482 2.5 8.99555V19.5146C2.5 20.3407 2.5 20.7538 2.60921 20.9748C2.84358 21.4492 3.39295 21.6767 3.8941 21.507C4.12761 21.4279 4.41968 21.1359 5.00382 20.5517C5.47749 20.0781 5.71435 19.8412 5.97534 19.6499C6.52225 19.2491 7.15764 18.986 7.82778 18.8826C8.14756 18.8333 8.4825 18.8333 9.15237 18.8333H15.5044C17.1552 18.8333 17.9805 18.8333 18.6454 18.6057C19.88 18.183 20.8497 17.2133 21.2724 15.9788C21.5 15.3139 21.5 14.4885 21.5 12.8378V8.99555C21.5 7.34482 21.5 6.51945 21.2724 5.85457C20.8497 4.62004 19.88 3.65031 18.6454 3.22764C17.9805 3 17.1552 3 15.5044 3H8.49555C6.84482 3 6.01945 3 5.35457 3.22764C4.12004 3.65031 3.15031 4.62004 2.72764 5.85457ZM7.25001 12.5C8.12446 12.5 8.83334 11.7911 8.83334 10.9167C8.83334 10.0422 8.12446 9.33334 7.25001 9.33334C6.37555 9.33334 5.66667 10.0422 5.66667 10.9167C5.66667 11.7911 6.37555 12.5 7.25001 12.5ZM13.5833 10.9167C13.5833 11.7911 12.8744 12.5 12 12.5C11.1255 12.5 10.4167 11.7911 10.4167 10.9167C10.4167 10.0422 11.1255 9.33334 12 9.33334C12.8744 9.33334 13.5833 10.0422 13.5833 10.9167ZM16.75 12.5C17.6245 12.5 18.3333 11.7911 18.3333 10.9167C18.3333 10.0422 17.6245 9.33334 16.75 9.33334C15.8756 9.33334 15.1667 10.0422 15.1667 10.9167C15.1667 11.7911 15.8756 12.5 16.75 12.5Z'
			fill='inherit'
		/>
	</svg>
);

import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={12}
		height={12}
		viewBox='0 0 12 12'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M0 0C6.59254 0.0834494 11.9166 5.40746 12 12V0H0Z'
			fill='#F4F4F4'
		/>
	</svg>
);

export default SvgComponent;
const renderCurrencySymbol = (name: string) => {
	switch (name?.toLowerCase()) {
		case 'eur':
			return '€';
		case 'uah':
			return '₴';
		case 'usd':
			return '$';
		case 'pln':
			return 'zł';
		case 'czk':
			return 'Kč';
		case 'rsd':
			return 'DIN.';
		case 'rub':
			return '₽';
		case 'bdt':
			return '৳';
		case 'inr':
			return '₹';
		case 'brl':
			return 'R$';
		default:
			return null;
	}
};

export default renderCurrencySymbol;

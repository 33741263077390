import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		{...props}
	>
		<path
			fill='#A0A4AD'
			data-id='changed-color'
			fillRule='evenodd'
			d='M9.243 3.9a.75.75 0 0 1-.644.843l-2.112.282a.75.75 0 0 0-.631.913L7.23 11.83a.75.75 0 0 1-1.46.341L4.394 6.28a2.25 2.25 0 0 1 1.894-2.742L8.4 3.256a.75.75 0 0 1 .843.644ZM17.262 11.038a.75.75 0 0 1 .95.474l1.7 5.101a2.25 2.25 0 0 1-1.744 2.927l-2.538.448a.75.75 0 0 1-.26-1.477l2.537-.448a.75.75 0 0 0 .581-.976l-1.7-5.1a.75.75 0 0 1 .474-.949Z'
			clipRule='evenodd'
		/>
		<path
			fill='#A0A4AD'
			data-id='changed-color'
			fillRule='evenodd'
			d='M17.271 5.848 10.51 4.037a1 1 0 0 0-1.225.707L6.021 16.926a1 1 0 0 0 .707 1.225l6.761 1.811a1 1 0 0 0 1.225-.707l3.264-12.182a1 1 0 0 0-.707-1.225Zm-6.373-3.26a2.5 2.5 0 0 0-3.062 1.768L4.572 16.538A2.5 2.5 0 0 0 6.34 19.6L13.1 21.41a2.5 2.5 0 0 0 3.062-1.767L19.427 7.46A2.5 2.5 0 0 0 17.66 4.4l-6.762-1.811Z'
			clipRule='evenodd'
		/>
		<path
			fill='#A0A4AD'
			data-id='changed-color'
			fillRule='evenodd'
			d='M14.083 7.123a.75.75 0 0 0-.919.53c-.206.77-.208 1.415-.075 1.983.13.559.38.996.593 1.34l.078.128c.388.625.565.911.413 1.478-.075.278-.176.414-.25.481a.504.504 0 0 1-.282.125c-.278.04-.652-.06-.973-.218a.75.75 0 0 0-1.057.478c-.511 1.91-.042 3.356.658 3.977a.75.75 0 1 0 .995-1.122c-.11-.099-.454-.632-.352-1.67.296.06.617.086.941.04a2 2 0 0 0 1.083-.503c.326-.3.553-.706.686-1.2.326-1.216-.19-2.033-.565-2.623l-.098-.157c-.193-.314-.337-.581-.41-.894-.07-.303-.085-.696.064-1.255a.75.75 0 0 0-.53-.918Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='M12.634 6.734a.75.75 0 0 1 .53.919c-.205.77-.526 1.33-.925 1.756-.392.418-.828.671-1.184.864l-.132.07c-.648.348-.944.508-1.096 1.074-.075.279-.055.447-.025.542.03.093.086.173.183.249.22.173.594.275.95.298a.75.75 0 0 1 .677.943c-.512 1.91-1.641 2.927-2.558 3.115a.75.75 0 0 1-.3-1.47c.144-.03.708-.32 1.14-1.27a2.734 2.734 0 0 1-.836-.436 2 2 0 0 1-.686-.977c-.133-.422-.127-.888.006-1.382.326-1.216 1.181-1.665 1.8-1.99l.164-.086c.324-.175.582-.335.802-.57.212-.226.422-.56.572-1.118a.75.75 0 0 1 .918-.53Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

import { DeviceType } from '@types';

export default function detectDeviceType() {
	const userAgent = navigator.userAgent.toLowerCase();
	const mobileKeywords = ['iphone', 'android', 'windows phone', 'blackberry', 'mobile'];

	for (const keyword of mobileKeywords) {
		if (userAgent.includes(keyword)) {
			return DeviceType.mobile;
		}
	}

	return DeviceType.desktop;
}

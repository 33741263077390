import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={20}
		height={20}
		viewBox='0 0 20 20'
		fill='none'
		{...props}
	>
		<path
			fill='#fff'
			fillRule='evenodd'
			d='M13.236 2.596a1.042 1.042 0 0 1 0 1.473L7.306 10l5.93 5.93a1.042 1.042 0 0 1-1.473 1.474l-6.666-6.667a1.042 1.042 0 0 1 0-1.473l6.666-6.667a1.042 1.042 0 0 1 1.473 0Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

import isEmpty from 'lodash/isEmpty';
import { SetFloatNumber } from '@types';

export const setFloatNumber: SetFloatNumber = (str) => {
	const onlyNumbersAndOneDot = str.replace(',', '.').replace(/[^.\d]+/g, '');
	const dividedNumber = onlyNumbersAndOneDot.split('.');

	const isHasAlreadyDot = onlyNumbersAndOneDot.includes('.');

	// до точки
	const leftPart = dividedNumber[0][0] === '0' ? '1' : dividedNumber[0];
	// после точки
	const rightPart = !isEmpty(dividedNumber[1]) ? dividedNumber[1].slice(0, 2) : '';

	return isHasAlreadyDot
		? isEmpty(leftPart)
			? '1.'
			: leftPart + '.' + rightPart
		: leftPart;
};

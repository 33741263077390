export enum RegisterPageTypesE {
	sign_up_1 = 'signup',
	sign_up_2 = 'signup2',
	sign_up_3 = 'signup3',
	sign_up_4 = 'signup4',
	sign_up_5 = 'signup5'
}

export enum RegisterPageBannerValue {
	MAIN = 'control',
	V1 = 'v1',
	V2 = 'v2'
}

export interface MetrikFlags {
	[key: string]: string[];
}

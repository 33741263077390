import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={20}
		height={20}
		fill='none'
		{...props}
	>
		<path
			fill='#496E2D'
			d='M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10Z'
		/>
		<path
			fill='#D80027'
			d='M7.826 14.348a4.348 4.348 0 1 0 0-8.696 4.348 4.348 0 0 0 0 8.696Z'
		/>
	</svg>
);

export default SvgComponent;

export enum AnalyticsEventType {
	header_click_registration = 'header_click_registration',
	provider_click = 'provider_click',
	click_info_icon = 'click_info_icon',
	payment_click_topup = 'payment_click_topup',
	new_password_error = 'new_password_error',
	click_card = 'click_card',
	click_card_play = 'click_card_play',
	advertising_click_banner = 'advertising_click_banner',
	registration_error = 'registration_error',
	singin_error = 'singin_error',
	replenishment_error = 'replenishment_error',
	main_menu_click = 'main_menu_click',
	filter_click_game_type = 'filter_click_game_type',
	popup_show = 'popup_show',
	popup_click_to_action = 'popup_click_to_action',
	all_bonuses_click_navigation = 'all_bonuses_click_navigation',
	all_bonuses_click_registration = 'all_bonuses_click_registration',
	transaction_click_tab = 'transaction_click_tab',
	language_choose = 'language_choose',
	search_result = 'search_result',
	providers_search = 'providers_search',
	registration_choose_currency = 'registration_choose_currency',
	payment_click_choose_method = 'payment_click_choose_method',
	payment_click_choose_amount = 'payment_click_choose_amount',
	payment_input_replenishment_amount = 'payment_input_replenishment_amount',
	payment_input_withdrawal_amount = 'payment_input_withdrawal_amount',
	information_open_block = 'information_open_block',
	information_download = 'information_download',
	click_card_demo = 'click_card_demo',
	click_card_favourite = 'click_card_favourite',
	popup_close = 'popup_close',
	advertising_show_banner = 'advertising_show_banner',
	header_click_payment = 'header_click_payment',
	header_click_singin = 'header_click_singin',
	balance_freespin = 'balance_freespin',
	balance_special_games = 'balance_special_games',
	registration_click_sinup = 'registration_click_sinup',
	singin_click_signin = 'singin_click_signin',
	singin_click_singup = 'singin_click_singup',
	new_password_click_to_send = 'new_password_click_to_send',
	new_password_success = 'new_password_success',
	registration_success = 'registration_success',
	singin_success = 'singin_success',
	header_click_balance = 'header_click_balance',
	replenishment_success = 'replenishment_success',
	withdrawal_success = 'withdrawal_success',
	withdrawal_error = 'withdrawal_error',
	referral_getlink = 'referral_getlink',
	referral_click_registration = 'referral_click_registration',
	referral_open_video = 'referral_open_video',
	referral_watch_video = 'referral_watch_video',
	referral_close_video = 'referral_close_video',
	referral_go_to_tg = 'referral_go_to_tg',
	all_bonuses_click_payment = 'all_bonuses_click_payment',
	all_bonuses_click_cashback = 'all_bonuses_click_cashback',
	all_bonuses_click_levels = 'all_bonuses_click_levels',
	all_bonuses_click_tg = 'all_bonuses_click_tg',
	all_bonuses_click_referall = 'all_bonuses_click_referall',
	header_click_providers = 'header_click_providers',
	header_click_favourite = 'header_click_favourite',
	levels_click_payment = 'levels_click_payment',
	levels_click_registration_main = 'levels_click_registration_main',
	levels_click_list = 'levels_click_list',
	level_click_list_close = 'level_click_list_close',
	levels_click_registration_cashback = 'levels_click_registration_cashback',
	click_support_choose_tg = 'click_support_choose_tg',
	tg_click_registration = 'tg_click_registration',
	tg_click_video = 'tg_click_video',
	tg_go_to_referral = 'tg_go_to_referral',
	tg_close_video = 'tg_close_video',
	tg_click_go_to_tg = 'tg_click_go_to_tg',
	transaction_click_more = 'transaction_click_more',
	language_click_change = 'language_click_change',
	promocode_input = 'promocode_input',
	promocode_click_activate = 'promocode_click_activate',
	promocode_activate_success = 'promocode_activate_success',
	promocode_activate_error = 'promocode_activate_error',
	click_support = 'click_support',
	providers_click = 'providers_click',
	registration_input_phonenumber = 'registration_input_phonenumber',
	registration_input_mail = 'registration_input_mail',
	registration_input_password = 'registration_input_password',
	registration_click_support = 'registration_click_support',
	registration_click_currency = 'registration_click_currency',
	registration_click_singin = 'registration_click_singin',
	payment_click_tab_replenishment = 'payment_click_tab_replenishment',
	payment_click_tab_withdrawal = 'payment_click_tab_withdrawal',
	payment_click_instruction = 'payment_click_instruction',
	payment_input_mail = 'payment_input_mail',
	payment_input_acc_number = 'payment_input_acc_number',
	singin_input_mail = 'singin_input_mail',
	singin_input_password = 'singin_input_password',
	singin_click_forgot_password = 'singin_click_forgot_password',
	cashback_go_to_casino = 'cashback_go_to_casino',
	cashback_registration = 'cashback_registration',
	cashback_close = 'cashback_close',
	information_go_to_main = 'information_go_to_main',
	header_click_search = 'header_click_search',
	profile_edit_mail = 'profile_edit_mail',
	profile_edit_phone = 'profile_edit_phone',
	profile_edit_password = 'profile_edit_password',
	profile_confirm_mail = 'profile_confirm_mail',
	profile_change_password_success = 'profile_change_password_success',
	click_menu = 'click_menu',
	filter_scroll = 'filter_scroll',
	click_support_choose_online_support = 'click_support_choose_online_support',
	profile_log_out = 'profile_log_out',
	levels_click_payment_cashback = 'levels_click_payment_cashback',
	all_bonuses_freespin = 'all_bonuses_freespin',
	registration_avtorizaition = 'registration_avtorizaition',
	new_profile_log_out = 'new_profile_log_out',
	modal_logout_click_close = 'modal_logout_click_close',
	modal_logout_click_stay = 'modal_logout_click_stay',
	modal_logout_click_logout = 'modal_logout_click_logout',
	signin_click_tab = 'signin_click_tab',
	signin_banner_show_forgot_password = 'signin_banner_show_forgot_password',
	signin_banner_click_close = 'signin_banner_click_close',
	signin_banner_click_forgot_password = 'signin_banner_click_forgot_password',
	payment_click_withdrawal = 'payment_click_withdrawal',
	error_404 = 'error_404',
	signup_open_modal = 'signup_open_modal',
	signin_open_modal = 'signin_open_modal',
	new_registration_click_sinup = 'new_registration_click_sinup',
	new_registration_input_phonenumber = 'new_registration_input_phonenumber',
	new_registration_input_mail = 'new_registration_input_mail',
	new_registration_input_password = 'new_registration_input_password',
	new_registration_click_support = 'new_registration_click_support',
	new_registration_click_currency = 'new_registration_click_currency',
	new_registration_choose_currency = 'new_registration_choose_currency',
	new_registration_click_singin = 'new_registration_click_singin',
	new_registration_avtorizaition = 'new_registration_avtorizaition',
	new_cashback_backtoplay = 'new_cashback_backtoplay',
	new_cashback_signup_new = 'new_cashback_signup_new',
	new_cashback_rules = 'new_cashback_rules',
	new_cashback_learnmore = 'new_cashback_learnmore',
	play_for_money_tosignup = 'play_for_money_tosignup',
	play_for_money_topayment = 'play_for_money_topayment',
	new_registration_switch = 'new_registration_switch',
	payment_modal_stay_dep = 'payment_modal_stay_dep',
	payment_modal_goout_dep = 'payment_modal_goout_dep',
	close_payment = 'close_payment',
	singnup_click_tab = 'singnup_click_tab',
	signup_click_social = 'signup_click_social',
	signin_click_social = 'signin_click_social',
	close_back = 'close_back',
	balance_glazik_close = 'balance_glazik_close',
	balance_glazik_open = 'balance_glazik_open',
	new_password_creat_modal_show = 'new_password_creat_modal_show',
	new_password_creat_modal_click = 'new_password_creat_modal_click',
	new_password_creat_modal_success = 'new_password_creat_modal_success',
	new_password_creat_modal_error = 'new_password_creat_modal_error',
	new_password_сlick_google = 'new_password_сlick_google',
	header_click_menu = 'header_click_menu',
	menu_mobile_levels = 'menu_mobile_levels',
	menu_mobile_games = 'menu_mobile_games',
	menu_mobile_free_spin = 'menu_mobile_free_spin',
	menu_mobile_bonus = 'menu_mobile_bonus',
	menu_mobile_payment_out = 'menu_mobile_payment_out',
	menu_mobile_click = 'menu_mobile_click',
	menu_mobile_click_choose_language = 'menu_mobile_click_choose_language',
	menu_mobile_click_change_language = 'menu_mobile_click_change_language',
	menu_click_logout = 'menu_click_logout',
	menu_click_support = 'menu_click_support',
	menu_mobile_payment_in = 'menu_mobile_payment_in',
	menu_click_close = 'menu_click_close',
	open_filter = 'open_filter',
	close_filter = 'close_filter',
	filter_unchoose_game_type = 'filter_unchoose_game_type',
	menu_advertising_click_banner = 'menu_advertising_click_banner',
	page_view = 'page_view',
	header_click_present = 'header_click_present',
	popup_click_close = 'popup_click_close',
	popup_click_cancel = 'popup_click_cancel',
	apk_show = 'apk_show',
	apk_click_download = 'apk_click_download',
	apk_click_close = 'apk_click_close'
}

export enum AnalyticsTestsNameE {
	bannerTest = 'test',
	promoCardsTest = 'test2',
	AUTH_BUTTONS = 'auth'
}

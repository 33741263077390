import React from 'react';
import s from './style.module.scss';

export const CardPreloader = ({ length = 1 }: { length: number }) => {
	const renderComponentsWithMap = () => {
		return Array.from({ length }, (_, index) => (
			<div
				className={s.preloader}
				key={index}
			/>
		));
	};

	return <>{renderComponentsWithMap()}</>;
};

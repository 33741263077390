import React from 'react';
import OneSignal from 'react-onesignal';
import toast, { Toast } from 'react-hot-toast';
import { NotificattionContainer } from '../NotificattionContainer';
import { useLocales } from '@lib/hooks';
import { AnalyticsEventType, NotificationsModalStorageE } from '@types';
import { sendAnalyticsHandler, getImageByURL } from '@utils';
import { setOnesignalExternalIdHandler } from '@lib/api/auth';
import s from './style.module.scss';

type PropsType = {
	t: Toast;
};

export const SubscribeNotificationToast = (props: PropsType) => {
	const { t } = props;

	const { localizeText } = useLocales({
		path: 'global',
		node: 'notifications'
	});

	const closeHandler = () => {
		sendAnalyticsHandler(AnalyticsEventType?.popup_click_close, {
			name_popup: 'subscribe_to_notifications'
		});
		toast?.dismiss(t?.id);
	};

	const cancelHandler = () => {
		sendAnalyticsHandler(AnalyticsEventType?.popup_click_cancel, {
			name_popup: 'subscribe_to_notifications'
		});

		window.localStorage.setItem(NotificationsModalStorageE.notification_modal, 'false');
		toast?.dismiss(t?.id);
	};

	const confirmHandler = () => {
		sendAnalyticsHandler(AnalyticsEventType?.popup_click_to_action, {
			name_popup: 'subscribe_to_notifications'
		});

		OneSignal?.Notifications?.requestPermission().then(async () => {
			if (OneSignal?.Notifications?.permission && OneSignal.User.PushSubscription.id) {
				setOnesignalExternalIdHandler({
					subscriptionId: OneSignal.User.PushSubscription.id
				});
			}
		});

		toast?.dismiss(t?.id);
	};

	return (
		<NotificattionContainer
			duration={1000000}
			onClose={() => closeHandler && closeHandler()}
			t={t}
		>
			<div className={s.container}>
				<img
					className={s.img}
					src={getImageByURL('../../../../../../assets/img/common/banner-notif.webp')}
				/>

				<div className={s.textBlock}>
					<h3 className={s.textTitle}>{localizeText('subscribe_notif_title')}</h3>

					<span className={s.textDescr}>{localizeText('subscribe_notif_descr')}</span>

					<div className={s.btns}>
						<button
							className={s.registerBtn}
							onClick={confirmHandler}
						>
							{localizeText('subscribe_btn')}
						</button>

						<button
							className={s.confirmBtn}
							onClick={cancelHandler}
						>
							{localizeText('not_subscribe_btn')}
						</button>
					</div>
				</div>
			</div>
		</NotificattionContainer>
	);
};

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		{...props}
	>
		<path
			fill='#A0A4AD'
			data-id='changed-color'
			fillRule='evenodd'
			d='M9.5 6.25a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0V7a.75.75 0 0 1 .75-.75ZM9.5 13.25a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0v-3a.75.75 0 0 1 .75-.75Z'
			clipRule='evenodd'
		/>
		<path
			fill='#A0A4AD'
			data-id='changed-color'
			fillRule='evenodd'
			d='M5.5 6.75a.75.75 0 0 0-.75.75v1.596c.333.078.716.214 1.084.443.765.476 1.416 1.332 1.416 2.711 0 1.38-.651 2.236-1.416 2.712a3.627 3.627 0 0 1-1.084.443v1.345c0 .415.336.75.75.75H17a2.25 2.25 0 0 0 2.25-2.25V9A2.25 2.25 0 0 0 17 6.75h-.25a.75.75 0 0 1 0-1.5H17A3.75 3.75 0 0 1 20.75 9v6.25A3.75 3.75 0 0 1 17 19H5.5a2.25 2.25 0 0 1-2.25-2.25v-2A.75.75 0 0 1 4 14c.155 0 .63-.056 1.041-.312.36-.223.709-.617.709-1.438 0-.82-.349-1.214-.709-1.438A2.222 2.222 0 0 0 4 10.5a.75.75 0 0 1-.75-.75V7.5A2.25 2.25 0 0 1 5.5 5.25H14a.75.75 0 0 1 0 1.5H5.5Z'
			clipRule='evenodd'
		/>
		<path
			fill='#A0A4AD'
			data-id='changed-color'
			fillRule='evenodd'
			d='M8.427 3.943a.75.75 0 0 0-1.04.268L6.15 6.372a.75.75 0 0 1-1.302-.744l1.235-2.16a2.25 2.25 0 0 1 3.123-.807L13.64 5.36a.75.75 0 0 1-.78 1.281L8.427 3.943ZM18.643 17.362a.75.75 0 0 1 .246 1.031l-1.254 2.038a2.25 2.25 0 0 1-2.994.796l-4.25-2.318a.75.75 0 1 1 .718-1.317l4.25 2.318a.75.75 0 0 0 .998-.265l1.254-2.038a.75.75 0 0 1 1.032-.245Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='M17 9.25a.75.75 0 0 1 .75.75v4a.75.75 0 0 1-1.5 0v-4a.75.75 0 0 1 .75-.75Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

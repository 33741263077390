import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M2.518 12.922c-.144-.393.1-.81.503-.918.404-.108.816.133.967.524a6.985 6.985 0 1 0-.343-3.868c-.08.41-.443.721-.86.686-.417-.036-.73-.403-.657-.815a8.5 8.5 0 1 1 .39 4.39Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M10.084 20.976c-.356-.2-.439-.663-.207-1 .232-.335.69-.416 1.05-.223a6.522 6.522 0 0 0 9.024-8.42c-.167-.373-.055-.825.296-1.034.352-.208.808-.093.983.276a8.001 8.001 0 0 1-11.146 10.401ZM11.25 4.75a.75.75 0 0 0-.75.75c0 .797.165 1.42.44 1.936.27.505.626.862.92 1.14l.11.103c.535.504.78.734.78 1.321 0 .288-.063.445-.117.53a.503.503 0 0 1-.24.193c-.258.11-.645.112-.996.042a.75.75 0 0 0-.897.735c0 1.978.828 3.252 1.665 3.67a.75.75 0 1 0 .67-1.34c-.132-.067-.602-.493-.772-1.523.301-.017.619-.076.92-.205a2 2 0 0 0 .915-.766c.238-.373.352-.824.352-1.336 0-1.26-.71-1.914-1.224-2.388a15.712 15.712 0 0 1-.136-.126c-.268-.253-.475-.474-.627-.758C12.117 6.454 12 6.078 12 5.5a.75.75 0 0 0-.75-.75Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='M9.75 4.75a.75.75 0 0 1 .75.75c0 .797-.165 1.42-.44 1.936-.27.505-.625.862-.92 1.14l-.109.103c-.536.504-.781.734-.781 1.321 0 .288.063.445.117.53a.503.503 0 0 0 .24.193c.258.11.645.112.996.042a.75.75 0 0 1 .897.735c0 1.978-.827 3.252-1.665 3.67a.75.75 0 1 1-.67-1.34c.132-.067.602-.493.772-1.523a2.734 2.734 0 0 1-.92-.205 2.001 2.001 0 0 1-.915-.766c-.238-.373-.352-.824-.352-1.336 0-1.26.71-1.914 1.224-2.388l.136-.126c.268-.253.475-.474.627-.758C8.883 6.454 9 6.078 9 5.5a.75.75 0 0 1 .75-.75Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

import {
	ICommonStatisticsInitialState,
	SetCurrentUserBetsPA,
	SetCurrentUserTransactionsPA
} from '@types';

export const setCurrentUserTransactions = (
	state: ICommonStatisticsInitialState,
	action: SetCurrentUserTransactionsPA
) => {
	state.currentUserTransactions = action.payload;
};

export const updateCurrentUserTransactions = (
	state: ICommonStatisticsInitialState,
	action: SetCurrentUserTransactionsPA
) => {
	state.currentUserTransactions = [...state.currentUserTransactions, ...action.payload];
};

export const setCurrentUserBets = (
	state: ICommonStatisticsInitialState,
	action: SetCurrentUserBetsPA
) => {
	state.currentUserBets = action.payload;
};

export const updateCurrentUserBets = (
	state: ICommonStatisticsInitialState,
	action: SetCurrentUserBetsPA
) => {
	state.currentUserBets = [...state.currentUserBets, ...action.payload];
};

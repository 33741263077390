import React, { SetStateAction, useEffect, useState } from 'react';
import { Ripple } from '..';
import { bonusSelectors } from '@store/reducers/bonus/selectors';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { isNull } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocales, useUrlLang } from '@lib/hooks';
import s from './style.module.scss';
import cn from 'classnames';
import { renderGameUrl } from '@utils';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';

type PropsType = {
	setIsHaveFreeSpins?: React.Dispatch<SetStateAction<boolean>>;
	onOpenFunc?: () => void;
	withoutPadding?: boolean;
};

export const FreespinBlock = (props: PropsType) => {
	const navigate = useNavigate();

	const { langUrlPrefix } = useUrlLang();

	const { setIsHaveFreeSpins, onOpenFunc, withoutPadding } = props;

	const { freeSpinsInfo } = useSelector(bonusSelectors.bonusInfo);
	const { isSidebarOpened } = useSelector(commonUISelectors.commonUIInfo);

	const [freeSpinsQty, setFreeSpinsQty] = useState<number>(0);

	useEffect(() => {
		if (!isNull(freeSpinsInfo)) {
			let totalQty = 0;

			for (const obj of freeSpinsInfo) totalQty += obj.amount;

			setFreeSpinsQty(totalQty);

			setIsHaveFreeSpins && setIsHaveFreeSpins(totalQty > 0);
		}
	}, [freeSpinsInfo]);

	const gotoFreeSpinGameHanlder = () => {
		onOpenFunc && onOpenFunc();
		if (freeSpinsInfo && freeSpinsInfo?.length > 0) {
			navigate(langUrlPrefix + renderGameUrl(freeSpinsInfo[0]?.gameKey));
			setModalType(null);
		}
	};

	const { localizeText } = useLocales({
		path: 'components/common/page',
		node: 'Sidebar'
	});

	if (freeSpinsQty === 0) {
		return null;
	}

	return (
		<div
			className={cn(
				s.balance_menu,
				{ [s.withoutPadding]: withoutPadding },
				{ [s.sidebarOpened]: isSidebarOpened }
			)}
		>
			<div
				className={s.card}
				onClick={gotoFreeSpinGameHanlder}
			>
				<div className={s.textBlock}>
					{freeSpinsQty
						?.toString()
						.split('')
						.map((digit, index) => (
							<div
								className={s.number}
								key={index}
							>
								<span>{digit}</span>
							</div>
						))}
					<span className={s.freeSpins}>{localizeText('free_spins_count')}</span>
				</div>
				<span className={s.play}>{localizeText('play')}</span>
				<Ripple />
			</div>
		</div>
	);
};

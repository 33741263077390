import React from 'react';
import { isTapX, isTopXGo } from '@config/global';
import { userSelectors } from '@store/reducers/user/selectors';
import { pathsConfig } from '@config/paths';
import { renderGameUrl } from '@utils';
import { bonusSelectors } from '@store/reducers/bonus/selectors';
import { setBonusGameOpen } from '@store/reducers/bonus/dispatchers';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AuthType, ButtonType, ModalType } from '@types';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { Button } from '../button';
import { useLocales, useUrlLang } from '@lib/hooks';
import { setAuthType } from '@store/reducers/auth/dispathcers';
import { authSelectors } from '@store/reducers/auth/selectors';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import s from './style.module.scss';
import cn from 'classnames';
import { deleteFavoriteGames, setFavoriteGames } from '@lib/api/game-provider';
import { gameProviderSelectors } from '@store/reducers/game-provider/selectors';
import { setCurrentInitedGame } from '@store/reducers/game-provider/dispatchers';
import { FavoriteSelected, FavoriteSvg } from '@components/svg';
import { Ripple } from '../ripple-animation';
import { BackgroundImageWithFadeIn } from '../provider-game-card/parts';

type ProviderGameCardP = {
	name: string;
	route: string;
	img: string | null;
	providerName: string;
	className?: string;
	onlyImage?: boolean;
	isFavourite?: boolean;
	id: number;
	isSearch: boolean;
	isDemo: boolean;
	isFreezeBalancePlay: boolean;
};

export const ProviderSearchGameCard = (props: ProviderGameCardP) => {
	const { img, route, name, providerName, isDemo, isFreezeBalancePlay, id, isFavourite } =
		props;
	const { isAuthed } = useSelector(authSelectors.authInfo);
	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);
	const { balance, bonusBalanceEnableValue, bonusBalance } = useSelector(
		userSelectors.userInfo
	);
	const { freeSpinsInfo } = useSelector(bonusSelectors.bonusInfo);
	const { currentInitedGame } = useSelector(gameProviderSelectors.gameProviderInfo);

	const navigate = useNavigate();
	const { langUrlPrefix } = useUrlLang();

	const { localizeText } = useLocales({
		path: 'components/common/elements',
		node: 'ProviderGameCard'
	});

	const openAuthModal = () => {
		setModalType(ModalType.auth);
		setAuthType(AuthType.signUp);
	};

	const startFullPLay = () => {
		if (isAuthed) {
			const isFreespinGame = freeSpinsInfo?.find((g) => g?.gameKey === route);

			if (isFreespinGame) {
				setBonusGameOpen({
					isFreespinGame: !!isFreespinGame,
					isFreezeBalancePlay,
					route
				});

				return;
			}

			if (balance <= bonusBalanceEnableValue && bonusBalance <= bonusBalanceEnableValue) {
				navigate(langUrlPrefix + pathsConfig.payment.link);

				return;
			}

			if (balance > bonusBalanceEnableValue) {
				navigate(langUrlPrefix + renderGameUrl(route));

				return;
			}

			if (balance <= bonusBalanceEnableValue && bonusBalance >= bonusBalanceEnableValue) {
				if (isFreezeBalancePlay && !isFreespinGame) {
					navigate(langUrlPrefix + renderGameUrl(route));

					return;
				}

				if (!isFreezeBalancePlay && !isFreespinGame) {
					navigate(langUrlPrefix + pathsConfig.payment.link);

					return;
				}
			}
		} else {
			openAuthModal();

			return;
		}
	};

	const startDemoPLay = () =>
		navigate(langUrlPrefix + renderGameUrl(route), { state: { isDemo: true } });

	const setFavoriteHandler = async () => {
		if (!isAuthed) {
			setModalType(ModalType.auth);
		} else {
			await setFavoriteGames({
				providerGamesType: undefined,
				gameId: id || 0
			}).then(
				() =>
					currentInitedGame &&
					setCurrentInitedGame({
						...currentInitedGame,
						isFavorite: true
					})
			);
		}
	};

	const deleteFavoriteHandler = async () => {
		if (!isAuthed) {
			setModalType(ModalType.auth);
		} else {
			await deleteFavoriteGames({
				providerGamesType: undefined,
				gameId: id || 0
			}).then(
				() =>
					currentInitedGame &&
					setCurrentInitedGame({
						...currentInitedGame,
						isFavorite: false
					})
			);
		}
	};

	const croppedName = isMobile && name.length > 16 ? `${name.slice(0, 16)}...` : name;

	const image =
		!img || img === 'null' || img === 'undefined'
			? '/assets/img/common/game-card-image-default.svg'
			: img;

	return (
		<div className={cn(s.gameWrapper, s.gameWrapperGrid, props.className)}>
			<div
				className={s.gameCover}
				onClick={() => !isDemo && startFullPLay()}
			>
				<div className={s.btnsBlock}>
					{!isTopXGo && !isTapX && (
						<Button
							type={ButtonType?.primary}
							rippleAnimation
							handle={startFullPLay}
							className={cn(s.button, s.play)}
							text={localizeText('play')}
						/>
					)}
					{isDemo && (
						<Button
							rippleAnimation
							handle={startDemoPLay}
							className={cn(s.button, s.demo)}
							text={localizeText('demo')}
						/>
					)}
				</div>
			</div>

			<div className={s.front}>
				<BackgroundImageWithFadeIn
					imageUrl={image}
					isDefault={image?.includes('default')}
				/>
			</div>
			<div className={s.gameInfoWrapper}>
				<span className={s.gameName}>{croppedName}</span>
				<span className={s.providerName}>{providerName}</span>
				<button
					onClick={isFavourite ? deleteFavoriteHandler : setFavoriteHandler}
					className={s.starIcon}
				>
					{isFavourite ? <FavoriteSelected /> : <FavoriteSvg />}
					<Ripple />
				</button>
			</div>
		</div>
	);
};

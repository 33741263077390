import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M7 9.25a.75.75 0 0 1 .75.75v11a.75.75 0 0 1-1.5 0V10A.75.75 0 0 1 7 9.25ZM17 9.25a.75.75 0 0 1 .75.75v11a.75.75 0 0 1-1.5 0V10a.75.75 0 0 1 .75-.75Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M4.25 11.5a.75.75 0 0 1 .75-.75h13.75a.75.75 0 0 1 0 1.5H5a.75.75 0 0 1-.75-.75ZM2.75 9a.75.75 0 0 1 .75-.75H20a.75.75 0 0 1 0 1.5H3.5A.75.75 0 0 1 2.75 9ZM1.75 6.5a.75.75 0 0 1 .75-.75H21a.75.75 0 0 1 0 1.5H2.5a.75.75 0 0 1-.75-.75Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			d='M16 5.755a4 4 0 1 0-8 0h8Z'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='M12.007 13.271a.75.75 0 0 1 .75.75v1.912a.75.75 0 0 1-1.5 0V14.02a.75.75 0 0 1 .75-.75Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

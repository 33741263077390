import React, { useState, useLayoutEffect } from 'react';
import styled from 'styled-components';

interface Props {
	color: string;
	duration: number;
	opacity: number;
	onMouseDown: (event: React.MouseEvent<HTMLElement>) => void;
}

type RipplePropsType = {
	duration?: number;
	color?: string;
	opacity?: number;
	handler?: () => void;
};

const RippleContainer = styled.div<Props>`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	z-index: 0;

	span {
		transform: scale(0);
		border-radius: 100%;
		position: absolute;
		opacity: ${(props) => props.opacity};
		background-color: ${(props) => props.color};
		animation-name: ripple;
		animation-duration: ${(props) => props.duration}ms;
	}

	@keyframes ripple {
		to {
			opacity: 0;
			transform: scale(2);
		}
	}
`;

const useDebouncedRippleCleanUp = (
	rippleCount: number,
	duration: number,
	cleanUpFunction: () => void
) => {
	useLayoutEffect(() => {
		let bounce: any;

		if (rippleCount > 0) {
			clearTimeout(bounce);

			bounce = setTimeout(() => {
				cleanUpFunction();
				clearTimeout(bounce);
			}, duration * 1000);
		}

		return () => clearTimeout(bounce);
	}, [rippleCount, duration, cleanUpFunction]);
};

export const Ripple = ({
	duration = 1000,
	color = '#fff',
	opacity = 0.5,
	handler
}: RipplePropsType) => {
	const [rippleArray, setRippleArray] = useState<
		Array<{ y: number; x: number; size: number }>
	>([]);

	useDebouncedRippleCleanUp(rippleArray.length, duration, () => {
		setRippleArray([]);
	});

	const addRipple = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		const node = event.currentTarget as HTMLElement;
		const rippleContainer = node?.getBoundingClientRect();
		const size =
			rippleContainer.width > rippleContainer.height
				? rippleContainer.width
				: rippleContainer.height;
		const x = event.clientX - rippleContainer.left - size / 3.75;
		const y = event.clientY - rippleContainer.top - size / 3.75;
		const newRipple = {
			x,
			y,
			size: size / 1.75
		};

		setRippleArray([...rippleArray, newRipple]);
	};

	return (
		<RippleContainer
			onClick={handler}
			duration={duration}
			color={color}
			opacity={opacity}
			onMouseDown={addRipple}
		>
			{rippleArray.length > 0 &&
				rippleArray.map((ripple, index) => (
					<span
						key={'span' + index}
						style={{
							top: ripple.y,
							left: ripple.x,
							minWidth: ripple.size,
							minHeight: ripple.size
						}}
					/>
				))}
		</RippleContainer>
	);
};

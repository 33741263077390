import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={48}
		height={48}
		fill='none'
		{...props}
	>
		<path
			fill='#A0A4AD'
			fillRule='evenodd'
			d='M31.235 14.551c-1.232-1.16-2.484-1.684-3.75-1.522-1.213.155-2.186.914-2.951 1.723l-1.36 1.44a.87.87 0 0 0-.02.021l-6.83 7.23-.003.002a3.638 3.638 0 0 0-.606.932 3.766 3.766 0 0 0-.326 1.05l-.003.019-.37 3.24v.003c-.095.85.16 1.669.757 2.235.598.567 1.43.777 2.277.63l3.218-.549.007-.001c.358-.064.73-.212 1.044-.38.31-.166.646-.394.903-.673l6.72-7.113c.09-.068.168-.15.23-.244l1.254-1.327c.765-.808 1.472-1.824 1.558-3.047.089-1.275-.51-2.498-1.747-3.667l-.002-.002ZM17.78 24.814l5.649-5.98a7.13 7.13 0 0 0 3.984 3.77l-5.649 5.979-.012.013a1.53 1.53 0 0 1-.375.264c-.189.1-.356.157-.449.174l-3.215.55h-.002c-.313.054-.481-.036-.56-.111-.081-.077-.181-.244-.146-.562v-.001l.368-3.225a1.86 1.86 0 0 1 .155-.463c.095-.202.193-.345.251-.407Zm7.193-7.614a5.125 5.125 0 0 0 3.981 3.772l1.02-1.08c.656-.692.979-1.282 1.016-1.811.033-.477-.152-1.154-1.125-2.074-.967-.91-1.65-1.055-2.125-.994-.526.067-1.098.422-1.752 1.113L24.972 17.2Z'
			clipRule='evenodd'
		/>
		<path
			fill='#A0A4AD'
			d='M15 33a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2H15Z'
		/>
	</svg>
);

export default SvgComponent;

import { AuthService } from '@lib/api/auth/AuthService';
import {
	setIsResetPasswordMode,
	setnNextAttemptResetPassword
} from '@store/reducers/auth/dispathcers';
import { setIsAuthPending } from '@store/reducers/auth/dispathcers';
import { ErrorToast } from '@components/common/global-settings';
import toast from 'react-hot-toast';
import { AnalyticsEventType } from '@types';
import { cookies, sendAnalyticsHandler } from '@utils';

export const resetPassword = async (
	params: { email?: string; phone?: string },
	setFieldError?: (field: string, error: string) => void,
	setIsSuccess?: React.Dispatch<React.SetStateAction<boolean>>,
	methodType?: string
) => {
	setIsAuthPending(true);
	try {
		const { status, data } = await AuthService.resetPassword(params);

		if (status === 201) {
			sendAnalyticsHandler(AnalyticsEventType?.new_password_success, {
				new_password_switch: methodType
			});

			if (data) {
				setIsResetPasswordMode(false);
				setIsAuthPending(false);

				if (!data?.isBlocked) {
					setIsSuccess && setIsSuccess(true);
				}

				if (data?.nextAttemptAt) {
					setnNextAttemptResetPassword(data?.nextAttemptAt);
					cookies?.setCookie('nextAttempt', data?.nextAttemptAt, 1);
				}
			}
		}
	} catch (error: any) {
		if (error?.response?.status === 400) {
			toast((t) => ErrorToast({ t: t, text: 'lot_of_requests' }));
		}

		if (error?.response?.status === 422 && error?.response?.data?.message) {
			error?.response?.data?.message?.forEach((e: any) => {
				if (e?.property === 'phone' || e?.property === 'email') {
					setFieldError && setFieldError('field', 'not_valid_format');
				}
			});
		}

		sendAnalyticsHandler(AnalyticsEventType?.new_password_error, {
			new_password_error: error?.response?.data?.message?.toString(),
			new_password_switch: methodType
		});

		setIsAuthPending(false);
	}
};

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		viewBox='0 0 24 24'
		fill='none'
		{...props}
	>
		<path
			fill='#6D7380'
			d='M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z'
		/>
		<path
			fill='#6D7380'
			fillRule='evenodd'
			d='M22.618 11.039C19.99 6.367 16.07 3.874 12 3.874S4.01 6.367 1.382 11.039a1.958 1.958 0 0 0 0 1.912C4.009 17.628 7.929 20.124 12 20.124c4.07 0 7.99-2.496 10.618-7.173a1.958 1.958 0 0 0 0-1.912ZM12 18.124c-3.174 0-6.498-1.938-8.861-6.128 2.363-4.186 5.686-6.122 8.86-6.122 3.175 0 6.5 1.936 8.862 6.122-2.363 4.19-5.687 6.128-8.861 6.128Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={20}
		height={20}
		fill='none'
		{...props}
	>
		<path
			fill='#fff'
			fillRule='evenodd'
			d='M10.5 6.603c-.841-.483-1.797-.633-2.598-.173-.801.46-1.152 1.364-1.152 2.337v2.467c0 .971.352 1.872 1.151 2.333.8.461 1.756.316 2.598-.17l2.133-1.232h.001c.842-.486 1.448-1.241 1.448-2.164 0-.924-.606-1.679-1.448-2.164L10.5 6.604ZM8.416 8.767c0-.618.211-.832.316-.892.106-.06.4-.135.935.173L11.8 9.28c.532.307.614.597.614.72 0 .122-.082.413-.614.72l-2.134 1.233c-.532.306-.826.231-.933.17-.107-.062-.317-.278-.317-.89V8.767Z'
			clipRule='evenodd'
		/>
		<path
			fill='#fff'
			fillRule='evenodd'
			d='M7.5.834c-2.166 0-3.898.428-5.068 1.598C1.262 3.602.833 5.335.833 7.501v5c0 2.165.428 3.898 1.599 5.068 1.17 1.17 2.902 1.598 5.068 1.598h5c2.165 0 3.898-.428 5.068-1.598 1.17-1.17 1.599-2.903 1.599-5.068v-5c0-2.166-.428-3.899-1.599-5.069C16.398 1.262 14.665.834 12.5.834h-5Zm-5 6.667c0-2.001.405-3.186 1.11-3.89.705-.705 1.889-1.11 3.89-1.11h5c2.001 0 3.185.405 3.89 1.11.705.704 1.11 1.889 1.11 3.89v5c0 2-.405 3.185-1.11 3.89-.705.704-1.889 1.11-3.89 1.11h-5c-2.001 0-3.185-.406-3.89-1.11-.705-.705-1.11-1.89-1.11-3.89v-5Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

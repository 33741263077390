import * as React from 'react';
import { SVGProps } from 'react';

export const CloseSvg = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={25}
		height={24}
		viewBox='0 0 25 24'
		fill='none'
		{...props}
	>
		<path
			fill='#fff'
			d='M7.207 5.29a1 1 0 0 0-1.414 1.415l5.293 5.293-5.293 5.293a1 1 0 0 0 1.414 1.414l5.293-5.293 5.293 5.293a1 1 0 0 0 1.414-1.414l-5.293-5.293 5.293-5.293a1 1 0 0 0-1.414-1.414L12.5 10.584 7.207 5.29Z'
		/>
	</svg>
);

import { setGameCount } from '@store/reducers/game-provider/dispatchers';
import { GameProviderService } from './GameService';

export const getGameCount = async () => {
	const { data, status } = await GameProviderService.gameCount();

	if (status === 200) {
		setGameCount(data);
	}
};

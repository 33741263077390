import { PaymentType } from '@types';
import React from 'react';
import { SVGProps } from 'react';
import BonusIcon from './BonusIcon';
import InvoiceIcon from './InvoiceIcon';
import WithdrawalIcon from './WithdrawalIcon';
import Cashback from './Cashback';

type IconsType = Record<
	keyof typeof PaymentType,
	((props: SVGProps<SVGSVGElement>) => JSX.Element) | undefined
>;

const SpecificTrans: IconsType = {
	invoice: InvoiceIcon,
	withdrawal: WithdrawalIcon,
	withdraw: WithdrawalIcon,
	'rmw-bonus': BonusIcon,
	promo_money: BonusIcon,
	cashback: Cashback,
	task: BonusIcon,
	referral: BonusIcon,
	freespin_win: BonusIcon,
	freeze_balance_update: BonusIcon,
	withdraw_invoice: WithdrawalIcon,
	refund: WithdrawalIcon,
	transfer_bonus: BonusIcon,
	bonus_expire: BonusIcon,
	level_upgrade_bonus: BonusIcon,
	bonus_balance: BonusIcon
};

export const TransTypeComponents = ({ name }: { name: keyof typeof PaymentType }) => {
	const TransType = SpecificTrans[name as keyof IconsType];

	return TransType ? <TransType /> : <div style={{ width: 20, height: 20 }} />;
};

import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={20}
		height={20}
		fill='none'
		{...props}
	>
		<path
			stroke='#fff'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.5}
			d='m17.5 2.5-15 15M17.5 7.5v-5h-5M2.5 12.5v5h5M2.5 2.5l15 15M2.5 7.5v-5h5M17.5 12.5v5h-5'
		/>
	</svg>
);

export default SvgComponent;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		{...props}
	>
		<path
			fill='#A0A4AD'
			data-id='changed-color'
			fillRule='evenodd'
			d='M3.25 7A2.75 2.75 0 0 1 6 4.25h12A2.75 2.75 0 0 1 20.75 7v9.5A2.75 2.75 0 0 1 18 19.25H6a2.75 2.75 0 0 1-2.75-2.75v-4a.75.75 0 0 1 1.5 0v4c0 .69.56 1.25 1.25 1.25h12c.69 0 1.25-.56 1.25-1.25V7c0-.69-.56-1.25-1.25-1.25H6c-.69 0-1.25.56-1.25 1.25v2a.75.75 0 0 1-1.5 0V7Z'
			clipRule='evenodd'
		/>
		<path
			fill='#A0A4AD'
			data-id='changed-color'
			fillRule='evenodd'
			d='M8.47 1.97a.75.75 0 0 1 1.06 0l2.5 2.5a.75.75 0 0 1-1.06 1.06l-2.5-2.5a.75.75 0 0 1 0-1.06Z'
			clipRule='evenodd'
		/>
		<path
			fill='#A0A4AD'
			data-id='changed-color'
			fillRule='evenodd'
			d='M14.488 2.43a.75.75 0 0 0-1.057.082l-1.5 1.75a.75.75 0 1 0 1.138.976l1.5-1.75a.75.75 0 0 0-.08-1.057ZM7 17.75a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0v-3a.75.75 0 0 1 .75-.75ZM17 17.75a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0v-3a.75.75 0 0 1 .75-.75ZM6.25 16a.75.75 0 0 1 .75-.75h10a.75.75 0 0 1 0 1.5H7a.75.75 0 0 1-.75-.75Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			d='M10.125 12.381V9.573a.6.6 0 0 1 .857-.542l2.808 1.33a.6.6 0 0 1 .023 1.073l-2.809 1.478a.6.6 0 0 1-.879-.53Z'
		/>
	</svg>
);

export default SvgComponent;

import { instance } from '@lib/api';
import { AxiosResponsePromise } from '@types';

type SignUp = (params: {
	email?: string;
	password?: string;
	phone?: string;
	ref?: string;
	tgId?: number;
	currency?: string;
	timezone?: string;
	partnerId?: string;
	locale: string;
	analyticalSpaceSessionId?: string;
	tokenWebpush?: string;
	isApp?: boolean;
}) => AxiosResponsePromise<any>;
type SignIn = (params: {
	email?: string;
	phone?: string;
	password?: string;
	tokenWebpush?: string;
	isApp?: boolean;
}) => AxiosResponsePromise<{ accessToken: string }>;

type Logout = () => AxiosResponsePromise<boolean>;
type ResetPassword = (params: {
	email?: string;
	phone?: string;
}) => AxiosResponsePromise<{ isBlocked: boolean; nextAttemptAt: string }>;

type ConfirmPassword = (params: {
	code?: string;
	newPassword?: string;
	newPasswordConfirm?: string;
}) => AxiosResponsePromise<{ accessToken: string }>;

type SetOnesignalExternalId = (params: {
	subscriptionId: string;
}) => AxiosResponsePromise<{ accessToken: string }>;

type SetFingerprintIds = (params: {
	visitorId: string;
	requestId: string;
}) => AxiosResponsePromise<any>;

interface IAuthService {
	signIn: SignIn;
	signUp: SignUp;
	logout: Logout;
	resetPassword: ResetPassword;
	confirmPassword: ConfirmPassword;
	setOnesignalExternalId: SetOnesignalExternalId;
	setFingerprintIds: SetFingerprintIds;
}

enum AuthEndpoints {
	signIn = '/auth/signin',
	signUp = '/auth/signup',
	logout = '/auth/logout',
	resetPassword = '/auth/forgot-password',
	confirmPassword = '/auth/forgot-password/confirm',
	setOnesignalExternalId = '/auth/set-onesignal-ext-id',
	setFingerprintIds = '/auth/set-fingerprint'
}

export const AuthService: IAuthService = {
	signIn: async (params) => await instance.post(AuthEndpoints.signIn, params),

	signUp: async (params) => await instance.post(AuthEndpoints.signUp, params),
	logout: async () => await instance.post(AuthEndpoints.logout),
	resetPassword: async (params) =>
		await instance.post(AuthEndpoints.resetPassword, params),
	confirmPassword: async (params) =>
		await instance.post(AuthEndpoints.confirmPassword, params),
	setOnesignalExternalId: async (params) =>
		await instance.post(AuthEndpoints.setOnesignalExternalId, params),
	setFingerprintIds: async (params) =>
		await instance.post(AuthEndpoints.setFingerprintIds, params)
};

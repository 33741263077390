import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import { GameProviderService } from './GameService';
import {
	setCurrentGameProvider,
	setProviders
} from '@store/reducers/game-provider/dispatchers';

import { FilterRequestType, IGameProviderInitialState } from '@types';

type GetProviders = (
	params: {
		limit: number;
		page: number;
		filter?: FilterRequestType;
	},
	isSpecificElement: boolean
) => Promise<IGameProviderInitialState['currentGameProvider'] | void>;

// eslint-disable-next-line consistent-return
export const getProviders: GetProviders = async (params, isSpecificElement) => {
	const {
		status,
		data: { data: providers }
	} = await GameProviderService.getProviders(params);

	if (status === 200) {
		const providersMappedByImages = providers?.map((provider) => ({
			...provider,
			// TODOубрать потом последние два условия
			image:
				isNull(provider.image) || isEmpty(provider.image) || provider.image === 'null'
					? ''
					: provider.image
		}));

		if (isSpecificElement) {
			const currentProvider = {
				name: providers[0].name,
				id: providers[0].id,
				totalGameQty: providers[0].gameQty
			};

			setCurrentGameProvider(currentProvider);

			return currentProvider;
		}

		setProviders(providersMappedByImages);
	}
};

import { StoreInner } from '@types';
import { Reducer } from '@reduxjs/toolkit';
import { auth } from './auth';
import { user } from './user';
import { payment } from './payment';
import { commonStatistics } from './common-statistics';
import { commonUI } from './common-ui';
import { bonus } from './bonus';
import { gameProvider } from './game-provider';

type ReducerWrap = {
	[K in keyof StoreInner]: Reducer<StoreInner[K]>;
};

export const reducer: ReducerWrap = {
	auth,
	user,
	payment,
	commonStatistics,
	commonUI,
	bonus,
	gameProvider
};

import * as React from 'react';
import { SVGProps } from 'react';
export const CheckSvg = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={18}
		height={13}
		fill='none'
		{...props}
	>
		<path
			fill='#fff'
			fillRule='evenodd'
			d='M17.237.763a1.042 1.042 0 0 1 0 1.473l-10 10a1.042 1.042 0 0 1-1.473 0l-5-5a1.042 1.042 0 0 1 1.473-1.473L6.5 10.027 15.764.763a1.042 1.042 0 0 1 1.473 0Z'
			clipRule='evenodd'
		/>
	</svg>
);

import * as React from 'react';
import { SVGProps } from 'react';

export const ProviderListSvg = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={20}
		height={20}
		fill='none'
		viewBox='0 0 20 20'
		{...props}
	>
		<path
			fill='#A0A4AD'
			d='M8 5.75a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1ZM8 10a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1ZM8 14.25a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1ZM5.25 4.575a1.175 1.175 0 0 0 0 2.35h.01a1.175 1.175 0 0 0 0-2.35h-.01ZM5.25 8.825a1.175 1.175 0 0 0 0 2.35h.01a1.175 1.175 0 0 0 0-2.35h-.01ZM5.25 13.075a1.175 1.175 0 0 0 0 2.35h.01a1.175 1.175 0 0 0 0-2.35h-.01Z'
		/>
		<path
			fill='#A0A4AD'
			fillRule='evenodd'
			d='M0 6a6 6 0 0 1 6-6h8a6 6 0 0 1 6 6v8a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V6Zm6-4a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4V6a4 4 0 0 0-4-4H6Z'
			clipRule='evenodd'
		/>
	</svg>
);

import * as React from 'react';
import { SVGProps } from 'react';

export const GreenCheckIconSvg = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={32}
		height={32}
		fill='none'
		{...props}
	>
		<rect
			width={32}
			height={32}
			fill='#2EDB73'
			rx={16}
		/>
		<path
			fill='#fff'
			fillRule='evenodd'
			d='M24.237 10.263a1.042 1.042 0 0 1 0 1.473l-10 10a1.042 1.042 0 0 1-1.473 0l-5-5a1.042 1.042 0 0 1 1.473-1.473l4.263 4.264 9.264-9.264a1.042 1.042 0 0 1 1.473 0Z'
			clipRule='evenodd'
		/>
	</svg>
);

import React, { useEffect } from 'react';
import { useLocales } from '@lib/hooks';
import {
	finishStopWithdrawal,
	setPaymentProcessingStatus
} from '@store/reducers/payment/dispathcers';
import s from './style.module.scss';
import cn from 'classnames';

type PaymentPreloaderP = {
	isActive: boolean;
	isRequestToWithdrawalSuccess?: boolean;
};

export const PaymentPreloader = (props: PaymentPreloaderP) => {
	const { isActive, isRequestToWithdrawalSuccess } = props;

	const { localizeText } = useLocales({
		path: 'components/common/elements',
		node: 'PaymentPreloader'
	});

	useEffect(() => {
		if (isRequestToWithdrawalSuccess) {
			setTimeout(() => {
				setPaymentProcessingStatus(false);
				finishStopWithdrawal();
			}, 5000);
		}
	}, [isRequestToWithdrawalSuccess]);

	return (
		<div className={cn(s.preloader, { [s.preloaderActive]: isActive })}>
			{isRequestToWithdrawalSuccess ? (
				<span className={s.preloaderSuccess} />
			) : (
				<>
					<span className={s.preloaderSquares}>
						<span />
						<span />
						<span />
					</span>
				</>
			)}
			<p className={s.preloaderText}>
				{localizeText(isRequestToWithdrawalSuccess ? 'wait_deposit' : 'wait_please')}
			</p>
		</div>
	);
};

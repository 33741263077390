import { SUPPORTED_LANGS, DEFAULT_LANG } from '@config/global';
import { useMatch } from 'react-router-dom';

const useUrlLang = () => {
	const langMatch = useMatch({
		path: '/:lang/*',
		caseSensitive: false,
		end: true
	});

	const supportedLang =
		langMatch?.params?.lang && SUPPORTED_LANGS?.includes(langMatch?.params?.lang)
			? langMatch?.params?.lang
			: undefined;

	const lang = supportedLang ?? DEFAULT_LANG;
	const langUrlPrefix = lang === DEFAULT_LANG ? '' : `/${lang}`;
	const path = langUrlPrefix ?? '/';

	return { lang, langUrlPrefix, path };
};

export default useUrlLang;

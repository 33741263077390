import { getCurrentUserData } from '.';
import { UserService } from './UserService';
import { ErrorToast } from '@components/common/global-settings';
import toast from 'react-hot-toast';

export const confirmPhone = async () => {
	await UserService.confirmPhone();
};

export const confirmPhoneCode = async (
	token: string,
	successFunc?: (success: boolean) => void
) => {
	try {
		await UserService.confirmPhoneCode(token);

		successFunc && successFunc(true);
		await getCurrentUserData();
	} catch {
		successFunc && successFunc(false);
		toast((t) => ErrorToast({ t: t, text: 'something_wrong' }));
	}
};

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		viewBox='0 0 24 24'
		fill='none'
		{...props}
	>
		<path
			fill='#fff'
			fillRule='evenodd'
			d='M23.207 2.707a1 1 0 0 0-1.414-1.414L17.565 5.52c-2.661-1.66-5.666-2.076-8.52-1.2-2.974.914-5.682 3.198-7.663 6.718a1.958 1.958 0 0 0 0 1.912c1.122 1.996 2.476 3.594 3.981 4.772l-3.57 3.57a1 1 0 1 0 1.414 1.414l20-20ZM6.79 16.297l2.681-2.682a3 3 0 0 1 4.144-4.143l2.49-2.491c-2.086-1.167-4.34-1.403-6.473-.748-2.381.732-4.712 2.609-6.493 5.763 1.058 1.875 2.31 3.3 3.65 4.3Z'
			clipRule='evenodd'
		/>
		<path
			fill='#fff'
			d='M19.66 8.544a1 1 0 0 1 1.404.164c.556.703 1.076 1.48 1.554 2.33a1.958 1.958 0 0 1 0 1.913c-2.945 5.242-7.518 7.74-12.07 7.066a1 1 0 1 1 .293-1.979c3.528.522 7.374-1.35 10.02-6.043a15.915 15.915 0 0 0-1.366-2.047 1 1 0 0 1 .164-1.404Z'
		/>
	</svg>
);

export default SvgComponent;

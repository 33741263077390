import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={12}
		height={12}
		fill='none'
		viewBox='0 0 12 12'
		{...props}
	>
		<path
			fill='#FF7A00'
			d='M6.6 3.25a.6.6 0 1 1-1.2 0 .6.6 0 0 1 1.2 0ZM6.5 5a.5.5 0 0 0-1 0v3.75a.5.5 0 0 0 1 0V5Z'
		/>
		<path
			fill='#FF7A00'
			fillRule='evenodd'
			d='M6 .5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM1.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={48}
		height={48}
		fill='none'
		{...props}
	>
		<path
			fill='#fff'
			fillRule='evenodd'
			d='M21 16.8c0-.997.803-1.8 1.8-1.8h4.6v1.8c0 1.105.171 2.16.905 2.895.735.734 1.79.905 2.895.905H33v4.777c0 .895-.728 1.623-1.623 1.623a1 1 0 1 0 0 2A3.625 3.625 0 0 0 35 25.377V19.6a1 1 0 0 0-.293-.707l-5.6-5.6A1 1 0 0 0 28.4 13h-5.6a3.796 3.796 0 0 0-3.8 3.8 1 1 0 1 0 2 0Zm10.586 1.8L29.4 16.414v.386c0 .995.179 1.34.32 1.48.14.141.485.32 1.48.32h.386Z'
			clipRule='evenodd'
		/>
		<path
			fill='#fff'
			fillRule='evenodd'
			d='M19.227 19c-1.966 0-3.601.387-4.72 1.506-1.12 1.12-1.507 2.755-1.507 4.72v3.547c0 1.966.387 3.601 1.506 4.72 1.12 1.12 2.755 1.507 4.72 1.507h3.547c1.966 0 3.601-.387 4.72-1.506 1.12-1.12 1.507-2.755 1.507-4.72v-2.8a1 1 0 0 0-.293-.708l-5.973-5.973a1 1 0 0 0-.707-.293h-2.8ZM15 25.227c0-1.768.36-2.746.92-3.307.561-.56 1.539-.92 3.307-.92h1.8v1.987c0 1.175.183 2.277.946 3.04.763.763 1.865.946 3.04.946H27v1.8c0 1.768-.36 2.746-.92 3.307-.561.56-1.539.92-3.307.92h-3.546c-1.768 0-2.746-.36-3.307-.92-.56-.561-.92-1.539-.92-3.307v-3.546Zm10.586-.254-2.56-2.559v.573c0 1.064.191 1.456.361 1.626.17.17.562.36 1.626.36h.573Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

import { createSlice } from '@reduxjs/toolkit';

import * as reducers from './reducers';

import {
	CryptoCurrency,
	CryptoCurrencyKey,
	CryptoCurrencyNetwork,
	IPaymentInitialState,
	PaymentMethodType,
	PaymentType
} from '@types';

export const initialState: IPaymentInitialState = {
	paymentType: PaymentType.invoice,
	selectedTypePaymentMethods: [],
	paymentMethodsWithdraw: [],
	paymentMethodsInvoice: [],
	paymentMethodType: PaymentMethodType.fiat,
	invoiceValue: '',
	invoiceActiveCryptoCurrency: {
		name: CryptoCurrency.ERC20,
		key: CryptoCurrencyKey.ERC20,
		network: CryptoCurrencyNetwork.ERC20
	},
	withdrawalValue: '',
	withdrawalCardNumber: '',
	withdrawalCryptoAddress: '',
	withdrawalActiveCryptoCurrency: {
		name: CryptoCurrency.ERC20,
		key: CryptoCurrencyKey.ERC20,
		network: CryptoCurrencyNetwork.ERC20
	},
	withdrawalCommission: { payout: 0, commission: 0, isPending: false },
	isRequestToWithdrawalSuccess: false,
	isPaymentProcessing: false,
	p2p: null,
	cryptoInvoiceDetails: null,
	iframePayment: null,
	selectedPaymentMethodId: null,
	userConfirmedPayment: false,
	qrPaymentInfo: {
		step: 1,
		qr: '',
		id: 0
	}
};

export const { actions, reducer } = createSlice({
	name: 'payment',
	initialState,
	reducers
});

import * as React from 'react';
import { SVGProps } from 'react';

export const CancelSvg = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={24}
		height={24}
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M3.11612 3.11612C3.60427 2.62796 4.39573 2.62796 4.88388 3.11612L12 10.2322L19.1161 3.11612C19.6043 2.62796 20.3957 2.62796 20.8839 3.11612C21.372 3.60427 21.372 4.39573 20.8839 4.88388L13.7678 12L20.8839 19.1161C21.372 19.6043 21.372 20.3957 20.8839 20.8839C20.3957 21.372 19.6043 21.372 19.1161 20.8839L12 13.7678L4.88388 20.8839C4.39573 21.372 3.60427 21.372 3.11612 20.8839C2.62796 20.3957 2.62796 19.6043 3.11612 19.1161L10.2322 12L3.11612 4.88388C2.62796 4.39573 2.62796 3.60427 3.11612 3.11612Z'
			fill='inherit'
		/>
	</svg>
);

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={18}
		height={18}
		fill='none'
		{...props}
	>
		<path
			stroke='#A0A4AD'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.5}
			d='M9 5v3.839l3.333 1.494'
		/>
		<path
			stroke='#A0A4AD'
			strokeMiterlimit={10}
			strokeWidth={1.5}
			d='M16.333 9A7.333 7.333 0 1 0 1.666 9a7.333 7.333 0 0 0 14.667 0Z'
		/>
	</svg>
);

export default SvgComponent;

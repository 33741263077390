/* eslint-disable consistent-return */
import { setAuthType, setIsAuthPending } from '@store/reducers/auth/dispathcers';
import { AuthService } from './AuthService';
import { getCurrentUserData } from '@lib/api/user';
import { setAuthToken } from '@lib/browser-storage';
import { getFreeSpinsQty } from '../bonus/getFreeSpinsQty';
import { SuccessToast } from '@components/common/global-settings';
import toast from 'react-hot-toast';
import { AnalyticsEventType } from '@types';
import { sendAnalyticsHandler } from '@utils';

export const confirmPassword = async (
	params: {
		code?: string;
		newPassword: string;
		newPasswordConfirm: string;
	},
	successFunc?: () => void,
	disableLoader?: () => void
) => {
	try {
		const response = await AuthService.confirmPassword(params);

		if (response.status !== 201)
			return Promise.reject(
				new Error(`Error expected status: 201, but got ${response.status}`)
			);

		if (response.status === 201) {
			sendAnalyticsHandler(AnalyticsEventType?.new_password_creat_modal_success);

			if (response?.data?.accessToken) {
				successFunc && successFunc();

				toast((t) => SuccessToast({ t: t, text: 'success_password_update' }));
				setAuthType(null);

				setAuthToken(response.data.accessToken);
				await getCurrentUserData();
				await getFreeSpinsQty();
			}

			setIsAuthPending(false);
		}
	} catch (error: any) {
		disableLoader && disableLoader();

		sendAnalyticsHandler(AnalyticsEventType?.new_password_creat_modal_error);
	}
};

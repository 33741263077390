import { createSlice } from '@reduxjs/toolkit';
import { ICommonUIInitialState } from '@types';
import * as reducers from './reducers';

export const initialState: ICommonUIInitialState = {
	isGamesSearching: false,
	isFullScreenGaming: false,
	isSidebarOpened: false,
	activeLastWinsStatisticsName: '',
	modalType: null,
	modalSocialBonusTaskType: null,
	isMobile: false,
	isAndroidBanner: false,
	isTablet: false,
	mobileHeight: 0,
	isLiveChatOpened: false,
	isSidebarDesktopOpened: true,
	is404Page: false,
	isSidebarTabletOpened: false,
	isUpdateGameProviders: false,
	isUpdateGamesList: false,
	isTelegram: false,
	isApp: false
};

export const { actions, reducer } = createSlice({
	name: 'commonUI',
	initialState,
	reducers
});

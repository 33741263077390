import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={20}
		height={20}
		{...props}
	>
		<path
			fill='#F0F0F0'
			d='M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10Z'
		/>
		<path
			fill='#0052B4'
			d='M19.378 13.479A9.978 9.978 0 0 0 20 10c0-1.223-.22-2.395-.622-3.478H.622A9.979 9.979 0 0 0 0 10c0 1.223.22 2.395.622 3.479l9.378.869 9.378-.87Z'
		/>
		<path
			fill='#D80027'
			d='M10 20c4.3 0 7.965-2.714 9.378-6.522H.622C2.035 17.286 5.7 20 10 20Z'
		/>
	</svg>
);

export default SvgComponent;

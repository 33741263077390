import React from 'react';
import { BackArrow } from '@components/svg';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocales, useUrlLang } from '@lib/hooks';
import { pathsConfig } from '@config/paths';
import s from './style.module.scss';
import cn from 'classnames';

export default function GoHome({
	fullWidth,
	analiticsHandler
}: {
	fullWidth?: boolean;
	analiticsHandler?: () => void;
}) {
	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);

	const { langUrlPrefix } = useUrlLang();
	const navigate = useNavigate();

	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'NotFoundPage'
	});

	return !isMobile ? (
		<div className={cn(s.backBlock, { [s.fullWidth]: fullWidth })}>
			<button
				onClick={() => {
					navigate(langUrlPrefix + pathsConfig.home.tabs.casino.link);
					analiticsHandler && analiticsHandler();
				}}
			>
				<BackArrow /> {localizeText('to_main')}
			</button>
		</div>
	) : null;
}

import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		{...props}
	>
		<path
			fill='#A0A4AD'
			fillRule='evenodd'
			d='M16.177 2.681a2.25 2.25 0 0 1 2.573 1.921l.181 1.337a.75.75 0 0 0 .4.566l1.197.615a2.25 2.25 0 0 1 .95 3.072l-.35.648a.75.75 0 0 1-1.32-.714l.35-.648a.75.75 0 0 0-.316-1.024l-1.196-.615a2.25 2.25 0 0 1-1.2-1.698l-.182-1.337a.75.75 0 0 0-.858-.64l-1.333.206a2.25 2.25 0 0 1-1.97-.669l-.933-.974a.75.75 0 0 0-1.07-.014l-.958.95a2.25 2.25 0 0 1-1.986.617L6.83 4.04a.75.75 0 0 0-.875.618l-.216 1.331a2.25 2.25 0 0 1-1.244 1.667l-1.215.586a.75.75 0 0 0-.344 1.014l.608 1.204a2.25 2.25 0 0 1-.027 2.08l-.639 1.188a.75.75 0 0 0 .318 1.022l1.2.617a2.25 2.25 0 0 1 1.2 1.698l.182 1.337a.75.75 0 0 0 .858.64l1.333-.205a2.25 2.25 0 0 1 1.97.668l.932.975a.75.75 0 0 0 1.07.014l.958-.95a2.25 2.25 0 0 1 1.986-.618l1.328.24a.75.75 0 0 0 .874-.617l.216-1.332a2.25 2.25 0 0 1 1.244-1.667l1.224-.59a.75.75 0 0 0 .347-1.01l-.315-.633a.75.75 0 1 1 1.343-.668l.315.635a2.25 2.25 0 0 1-1.038 3.027l-1.225.59a.75.75 0 0 0-.414.556l-.216 1.332a2.25 2.25 0 0 1-2.623 1.853l-1.327-.24a.75.75 0 0 0-.662.206l-.958.95a2.25 2.25 0 0 1-3.21-.041l-.933-.975a.75.75 0 0 0-.657-.223l-1.333.206a2.25 2.25 0 0 1-2.574-1.92l-.181-1.338a.75.75 0 0 0-.4-.566l-1.2-.617a2.25 2.25 0 0 1-.953-3.066l.64-1.189a.75.75 0 0 0 .008-.693l-.608-1.204A2.25 2.25 0 0 1 2.628 6.89l1.215-.586a.75.75 0 0 0 .415-.556l.216-1.331a2.25 2.25 0 0 1 2.622-1.854l1.328.24a.75.75 0 0 0 .662-.205l.957-.95a2.25 2.25 0 0 1 3.211.04l.933.976a.75.75 0 0 0 .656.222l1.334-.206Z'
			clipRule='evenodd'
		/>
		<path
			fill='#FF2400'
			d='M16.263 12.626h-.915a.2.2 0 0 1-.2-.2V12.1c0-.11.09-.2.2-.2h.915a.872.872 0 0 0 .37-.067.44.44 0 0 0 .206-.184.541.541 0 0 0 .068-.27.659.659 0 0 0-.068-.297.5.5 0 0 0-.206-.22.707.707 0 0 0-.37-.085h-.508a.2.2 0 0 0-.2.2v2.769a.2.2 0 0 1-.2.2h-.68a.2.2 0 0 1-.2-.2v-3.493c0-.11.089-.2.2-.2h1.588c.36 0 .668.057.927.17.26.115.461.272.6.471.14.198.21.425.21.68 0 .25-.07.47-.21.657a1.36 1.36 0 0 1-.6.439 2.494 2.494 0 0 1-.927.155ZM13.914 11.922v.158c0 .298-.048.565-.145.802a1.709 1.709 0 0 1-1.022.987c-.238.087-.501.131-.789.131a2.31 2.31 0 0 1-.794-.131 1.838 1.838 0 0 1-.622-.382 1.743 1.743 0 0 1-.406-.605 2.153 2.153 0 0 1-.142-.802v-.158c0-.297.047-.565.142-.802a1.73 1.73 0 0 1 .403-.604c.174-.168.38-.295.619-.382.24-.09.505-.134.794-.134.288 0 .55.045.789.134a1.71 1.71 0 0 1 1.028.987c.097.236.145.504.145.801Zm-1.096.158v-.163c0-.192-.02-.362-.059-.508a1.042 1.042 0 0 0-.166-.372.7.7 0 0 0-.274-.224.83.83 0 0 0-.367-.078.853.853 0 0 0-.375.078.682.682 0 0 0-.268.224c-.072.1-.127.224-.163.372a2.202 2.202 0 0 0-.053.508v.163c0 .19.018.36.053.508.036.146.09.27.163.372a.72.72 0 0 0 .27.23.873.873 0 0 0 .38.077.83.83 0 0 0 .366-.077.698.698 0 0 0 .268-.23 1.07 1.07 0 0 0 .166-.372c.039-.148.059-.317.059-.508ZM8.199 10c.11 0 .2.09.2.2v3.493a.2.2 0 0 1-.2.2h-.681a.2.2 0 0 1-.2-.2V10.2c0-.11.09-.2.2-.2h.68Zm1.349 0c.11 0 .2.09.2.2v.325a.2.2 0 0 1-.2.2H6.2a.2.2 0 0 1-.2-.2V10.2c0-.11.09-.2.2-.2h3.348Z'
		/>
	</svg>
);

export default SvgComponent;

import { MetrikFlags } from '@types';

export const valueOfYandexMetrikaTest = (name: string) => {
	let result = null;

	(window as any)?.ymab('metrika.97429474', 'getFlags', (flags: MetrikFlags) => {
		for (const [key, value] of Object.entries(flags)) {
			if (key === name) {
				result = value[0];
			}
		}
	});

	return result;
};

import React, { useState, useEffect } from 'react';
import s from './style.module.scss';
import { isNumber } from 'lodash';
import cn from 'classnames';

type PropsType = {
	currentValue: number;
	maxValue: number;
	isDesktop?: boolean;
	customBg?: string;
	containerClassName?: string;
	fillerClassName?: string;
	labelClassName?: string;
	isSidebarOpened?: boolean;
};

export const ProgressBar = (props: PropsType) => {
	const [progress, setProgress] = useState<number>(0);

	const {
		currentValue,
		maxValue,
		isDesktop,
		customBg,
		containerClassName,
		fillerClassName,
		labelClassName,
		isSidebarOpened
	} = props;

	useEffect(() => {
		if (currentValue === maxValue) {
			setProgress(0);
		} else {
			setProgress(isNumber(currentValue) ? (currentValue / maxValue) * 100 : 0);
		}
	}, [currentValue, maxValue]);

	return (
		<div
			style={{ backgroundColor: customBg }}
			className={cn(
				s.containerStyles,
				containerClassName,
				{
					[s.containerStylesDesktop]: isDesktop
				},
				{ [s.containerStylesSidebarOpened]: isSidebarOpened }
			)}
		>
			<div
				className={cn(s.fillerStyles, fillerClassName)}
				style={{ width: progress <= 100 ? `${progress || 1}%` : '100%' }}
			>
				<span className={cn(s.labelStyles, labelClassName)} />
			</div>
		</div>
	);
};

import { createSlice } from '@reduxjs/toolkit';

import * as reducers from './reducers';
import { ICommonStatisticsInitialState } from '@types';

export const initialState: ICommonStatisticsInitialState = {
	onlineUserQty: 0,
	totalUserQty: 0,
	totalGameQty: 0,
	todayWonGameQty: 0,
	lastWinsStatistics: {
		all: [],
		highAmount: [],
		highCoeff: []
	},
	recentBigWins: [],
	recentBigWin: null,
	currentUserTransactions: [],
	currentUserBets: [],
	randomOnlineUserQtyAdding: 0,
	randomTotalUserQty: 115000
};

export const { actions, reducer } = createSlice({
	name: 'commonStatistics',
	initialState,
	reducers
});

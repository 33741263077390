import { AntiSpamModules } from '@lib/browser-storage/anti-spam/antiSpamMonitor';
import { Countries, Currencies, Languages } from '@types';

export interface IRegionCouple {
	country: Countries;
	langCode: Languages;
	currency: Currencies;
}

interface MetaTagsPair {
	title: string;
	description: string;
}

interface IMetaConfig {
	authTokenName: string;
	p2pInvoiceDetails: string;
	cryptoInvoiceDetails: string;
	settings: {
		availablePhotoTypes: Array<string>;
		availablePhotoSize: number;
	};
	antiSpamMonitorConditions: Record<
		AntiSpamModules,
		Array<{ iterationsCount: number; forbiddenTime: number }>
	>;

	metatags: {
		topx: {
			main_page: MetaTagsPair;
			live_dealers: MetaTagsPair;
			crash_games: MetaTagsPair;
			tv_games: MetaTagsPair;
			bonuses: MetaTagsPair;
			information: MetaTagsPair;
			providers: MetaTagsPair;
			page_404: MetaTagsPair;
		};
		tapx: {
			main_page: MetaTagsPair;
			live_dealers: MetaTagsPair;
			crash_games: MetaTagsPair;
			tv_games: MetaTagsPair;
			bonuses: MetaTagsPair;
			information: MetaTagsPair;
			providers: MetaTagsPair;
			page_404: MetaTagsPair;
		};
		topxgo: {
			main_page: MetaTagsPair;
			live_dealers: MetaTagsPair;
			crash_games: MetaTagsPair;
			tv_games: MetaTagsPair;
			bonuses: MetaTagsPair;
			information: MetaTagsPair;
			providers: MetaTagsPair;
			page_404: MetaTagsPair;
		};
	};
}

export const metaConfig: IMetaConfig = {
	authTokenName: 'auth_token',
	p2pInvoiceDetails: 'p2p_invoice_details',
	cryptoInvoiceDetails: 'crypto_invoice_details',
	settings: {
		availablePhotoTypes: ['image/png', 'image/jpeg'],
		availablePhotoSize: 2 // mb
	},
	antiSpamMonitorConditions: {
		promocode: [
			{
				iterationsCount: 5,
				forbiddenTime: 5 * 60000 // in minutes
			},
			{
				iterationsCount: 10,
				forbiddenTime: 60 * 60000 // in minutes
			},
			{
				iterationsCount: 15,
				forbiddenTime: 24 * 3600000 // in hours
			}
		]
	},

	metatags: {
		topx: {
			main_page: {
				title: 'TopX Online Casino — Official Site',
				description:
					'Dive into the ultimate gaming experience at TopX Online Casino. Enjoy a wide selection of slots, table games, and exclusive titles. Join now for exciting gameplay and big wins!'
			},
			live_dealers: {
				title: 'Live Dealer Games at TopX — Real-Time Casino Action Online',
				description:
					'Experience the thrill of live dealer games at TopX. Play Blackjack, Roulette, Baccarat, and more with professional dealers. Real casino action, in real-time, from the comfort of your home.'
			},
			crash_games: {
				title: 'Crash Games at TopX Casino — Fast-Paced Betting Excitement',
				description:
					'Get in on the fast-paced action with TopX Casino\'s Crash Games. Predict the crash for big wins in a dynamic, engaging gaming environment. Start playing now for an adrenaline rush!'
			},
			tv_games: {
				title: 'TopX TV Games — Interactive Live Casino Television Games 🤟',
				description:
					'Join the fun with TopX\'s TV Games, offering an interactive betting experience on live television games. Play, bet, and win in real-time on a variety of unique TV-themed casino games.'
			},
			bonuses: {
				title: 'TopX Casino Bonuses — Boost Your Winnings with Exciting Offers 🎁',
				description:
					'Maximize your winnings at TopX with our attractive casino bonuses. From welcome offers to loyalty rewards, we\'ve got a range of bonuses to enhance your gaming adventure. Sign up now!'
			},
			information: {
				title: 'About TopX Casino — Your Go-To Source for Online Gaming Information ℹ️',
				description:
					'Learn all about TopX Casino, your top destination for online gaming. Discover our story, values, and commitment to providing a safe and fair gaming experience for all players.'
			},
			providers: {
				title: 'TopX Casino\'s Game Providers — Leading Industry Innovators',
				description:
					'Explore the world-class game providers behind TopX Casino\'s diverse gaming portfolio. Featuring industry leaders offering the latest in slots, table games, and more. Play games powered by the best today!'
			},
			page_404: {
				title: '404 - Not found',
				description: 'Not found'
			}
		},
		tapx: {
			main_page: {
				title: 'TapX Online Casino — Official Site',
				description:
					'Dive into the ultimate gaming experience at TapX Online Casino. Enjoy a wide selection of slots, table games, and exclusive titles. Join now for exciting gameplay and big wins!'
			},
			live_dealers: {
				title: 'Live Dealer Games at TapX — Real-Time Casino Action Online',
				description:
					'Experience the thrill of live dealer games at TapX. Play Blackjack, Roulette, Baccarat, and more with professional dealers. Real casino action, in real-time, from the comfort of your home.'
			},
			crash_games: {
				title: 'Crash Games at TapX Casino — Fast-Paced Betting Excitement',
				description:
					'Get in on the fast-paced action with TapX Casino\'s Crash Games. Predict the crash for big wins in a dynamic, engaging gaming environment. Start playing now for an adrenaline rush!'
			},
			tv_games: {
				title: 'TapX TV Games — Interactive Live Casino Television Games 🤟',
				description:
					'Join the fun with TapX\'s TV Games, offering an interactive betting experience on live television games. Play, bet, and win in real-time on a variety of unique TV-themed casino games.'
			},
			bonuses: {
				title: 'TapX Casino Bonuses — Boost Your Winnings with Exciting Offers 🎁',
				description:
					'Maximize your winnings at TapX with our attractive casino bonuses. From welcome offers to loyalty rewards, we\'ve got a range of bonuses to enhance your gaming adventure. Sign up now!'
			},
			information: {
				title: 'About TapX Casino — Your Go-To Source for Online Gaming Information ℹ️',
				description:
					'Learn all about TapX Casino, your top destination for online gaming. Discover our story, values, and commitment to providing a safe and fair gaming experience for all players.'
			},
			providers: {
				title: 'TapX Casino\'s Game Providers — Leading Industry Innovators',
				description:
					'Explore the world-class game providers behind TapX Casino\'s diverse gaming portfolio. Featuring industry leaders offering the latest in slots, table games, and more. Play games powered by the best today!'
			},
			page_404: {
				title: '404 - Not found',
				description: 'Not found'
			}
		},
		topxgo: {
			main_page: {
				title: 'Top-x-go Online Casino — Official Site',
				description:
					'Dive into the ultimate gaming experience at Top-x-go Online Casino. Enjoy a wide selection of slots, table games, and exclusive titles. Join now for exciting gameplay and big wins!'
			},
			live_dealers: {
				title: 'Live Dealer Games at Top-x-go — Real-Time Casino Action Online',
				description:
					'Experience the thrill of live dealer games at Top-x-go. Play Blackjack, Roulette, Baccarat, and more with professional dealers. Real casino action, in real-time, from the comfort of your home.'
			},
			crash_games: {
				title: 'Crash Games at Top-x-go Casino — Fast-Paced Betting Excitement',
				description:
					'Get in on the fast-paced action with Top-x-go Casino\'s Crash Games. Predict the crash for big wins in a dynamic, engaging gaming environment. Start playing now for an adrenaline rush!'
			},
			tv_games: {
				title: 'Top-x-go TV Games — Interactive Live Casino Television Games 🤟',
				description:
					'Join the fun with Top-x-go\'s TV Games, offering an interactive betting experience on live television games. Play, bet, and win in real-time on a variety of unique TV-themed casino games.'
			},
			bonuses: {
				title: 'Top-x-go Casino Bonuses — Boost Your Winnings with Exciting Offers 🎁',
				description:
					'Maximize your winnings at Top-x-go with our attractive casino bonuses. From welcome offers to loyalty rewards, we\'ve got a range of bonuses to enhance your gaming adventure. Sign up now!'
			},
			information: {
				title:
					'About Top-x-go Casino — Your Go-To Source for Online Gaming Information ℹ️',
				description:
					'Learn all about Top-x-go Casino, your top destination for online gaming. Discover our story, values, and commitment to providing a safe and fair gaming experience for all players.'
			},
			providers: {
				title: 'Top-x-go Casino\'s Game Providers — Leading Industry Innovators',
				description:
					'Explore the world-class game providers behind Top-x-go Casino\'s diverse gaming portfolio. Featuring industry leaders offering the latest in slots, table games, and more. Play games powered by the best today!'
			},
			page_404: {
				title: '404 - Not found',
				description: 'Not found'
			}
		}
	}
};

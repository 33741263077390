import React, { useState } from 'react';
import s from './style.module.scss';
import cn from 'classnames';

type PropsTypes = {
	src: string;
	alt: string;
	className: string;
};

const ImageWithFadeIn = (props: PropsTypes) => {
	const { src, alt, className } = props;

	const [loaded, setLoaded] = useState(false);

	const handleImageLoad = () => {
		setLoaded(true);
	};

	return (
		<div className={s.container}>
			<img
				src={src}
				alt={alt}
				className={cn(s.fadeIn, className, { [s.loaded]: loaded })}
				onLoad={handleImageLoad}
			/>
		</div>
	);
};

export default ImageWithFadeIn;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		{...props}
	>
		<path
			fill='#A0A4AD'
			d='M14.936 2.775a.75.75 0 0 1 1.044.184.75.75 0 0 0 1.23-.86 2.25 2.25 0 0 0-3.135-.553L12.028 2.98a.75.75 0 0 0 .86 1.229l2.048-1.434ZM7.154 8.224a.75.75 0 0 0-.86-1.229L2.198 9.863a2.75 2.75 0 0 0-.676 3.83l5.163 7.372a2.75 2.75 0 0 0 3.83.676l11.468-8.03a2.75 2.75 0 0 0 .675-3.83l-1.434-2.048a.75.75 0 0 0-1.229.86l1.434 2.048a1.25 1.25 0 0 1-.307 1.74l-11.468 8.03a1.25 1.25 0 0 1-1.74-.306L2.75 12.833a1.25 1.25 0 0 1 .307-1.741l4.096-2.868Z'
		/>
		<path
			fill='#A0A4AD'
			d='M11.415 12.565a.75.75 0 0 0-.86-1.229l-4.096 2.868a.75.75 0 0 0 .86 1.229l4.096-2.868ZM12.562 14.203a.75.75 0 0 0-.86-1.229l-2.458 1.721a.75.75 0 1 0 .86 1.229l2.458-1.72Z'
		/>
		<path
			fill='#FF2400'
			fillRule='evenodd'
			d='M14.04 6.454c-.571-.577-1.178-1.044-1.78-1.37-.973-.527-2.18-.8-3.128-.138-.656.46-.932 1.204-.987 1.946-.064.874.16 1.882.587 2.876.26.606.604 1.223 1.023 1.822.848 1.211 1.882 2.169 2.902 2.721.974.527 2.18.801 3.128.138.948-.664 1.103-1.892.94-2.987-.1-.677-.33-1.407-.678-2.14l2.74-1.92a.75.75 0 0 0-.86-1.228l-2.624 1.837a11.183 11.183 0 0 0-.287-.41l4.262-2.984a.75.75 0 0 0-.86-1.228L14.04 6.454Zm-3.99-.197c.183-.128.642-.202 1.448.234.759.41 1.617 1.18 2.353 2.231.736 1.052 1.166 2.121 1.292 2.975.135.907-.092 1.313-.275 1.441-.184.129-.643.203-1.449-.234-.759-.41-1.617-1.18-2.353-2.231a8.675 8.675 0 0 1-.864-1.537c-.363-.844-.5-1.589-.461-2.127.034-.456.182-.663.309-.752Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

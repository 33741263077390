import { useRef, useLayoutEffect, DependencyList } from 'react';

const isBrowser = typeof window !== 'undefined';

function getScrollPosition({ useWindow }: { useWindow?: boolean }) {
	if (!isBrowser) return { x: 0, y: 0 };

	const target = document.body;
	const position = target.getBoundingClientRect();

	return useWindow
		? { x: window.scrollX, y: window.scrollY }
		: { x: position.left, y: position.top };
}

type EffectType = {
	prevPos: { x: number; y: number };
	currPos: { x: number; y: number };
};

export function useScrollPosition(
	effect: ({ prevPos, currPos }: EffectType) => void,
	deps: DependencyList | undefined,
	useWindow?: boolean,
	wait?: number
) {
	const position = useRef(getScrollPosition({ useWindow }));

	let throttleTimeout: ReturnType<typeof setTimeout> | null = null;

	const callBack = () => {
		const currPos = getScrollPosition({ useWindow });

		effect({ prevPos: position.current, currPos });
		position.current = currPos;
		throttleTimeout = null;
	};

	useLayoutEffect(() => {
		const handleScroll = () => {
			if (wait) {
				if (throttleTimeout === null) {
					throttleTimeout = setTimeout(callBack, wait);
				}
			} else {
				callBack();
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => window.removeEventListener('scroll', handleScroll);
	}, deps);
}

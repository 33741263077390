export const formatBalance = (input: number | string): number => {
    const value = Number(input);

    if (isNaN(value)) {
        return 0;
    }

    if (Number.isInteger(value)) {
        return value;
    }

    return parseFloat(value.toFixed(2));
};

import React, { useEffect } from 'react';
import s from './style.module.scss';
import toast, { Toast } from 'react-hot-toast';
import { CloseSvg } from '@components/svg';

type PropsType = {
	children: string | JSX.Element | JSX.Element[];
	t: Toast;
	duration?: number;
	onClose?: () => void;
};

export const NotificattionContainer = (props: PropsType) => {
	const { children, t, duration, onClose } = props;

	useEffect(() => {
		setTimeout(() => {
			toast?.dismiss(t?.id);
		}, duration || 5000);
	}, []);

	return (
		<div className={s.container}>
			{children}
			<span
				onClick={() => {
					onClose && onClose();
					toast?.dismiss(t?.id);
				}}
				className={s.close}
			>
				<CloseSvg />
			</span>
		</div>
	);
};

import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={21}
		height={20}
		fill='none'
		{...props}
	>
		<path
			stroke='#fff'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeMiterlimit={10}
			strokeWidth={1.5}
			d='M15.558 12.025 10.5 17.084l-5.058-5.059M10.5 2.917v14.025'
		/>
	</svg>
);

export default SvgComponent;

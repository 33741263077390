import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M2.572 6.334c-.211.35-.065.799.3.986.364.186.808.04 1.022-.308a9.517 9.517 0 1 1-1.237 3.174c.078-.402-.15-.81-.545-.919a.704.704 0 0 0-.888.524 11 11 0 1 0 1.348-3.457Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M19.802 10.23c.09.399-.198.77-.603.824-.405.053-.772-.233-.87-.63a6.522 6.522 0 1 0-.18 3.749c.136-.385.529-.635.927-.544.398.092.649.49.521.878a8 8 0 1 1 .205-4.277Z'
			clipRule='evenodd'
		/>
		<path
			fill={props.fill || '#A0A4AD'}
			fillRule='evenodd'
			d='M12 2.75a.75.75 0 0 1 .75.75V4a.75.75 0 0 1-1.5 0v-.5a.75.75 0 0 1 .75-.75ZM4.47 4.47a.75.75 0 0 1 1.06 0l1.343 1.343a.75.75 0 0 1-1.06 1.06L4.47 5.53a.75.75 0 0 1 0-1.06Zm15.06 0a.75.75 0 0 1 0 1.06l-1.343 1.343a.75.75 0 0 1-1.06-1.06L18.47 4.47a.75.75 0 0 1 1.06 0ZM2.75 12a.75.75 0 0 1 .75-.75H4a.75.75 0 0 1 0 1.5h-.5a.75.75 0 0 1-.75-.75Zm4.123 5.127a.75.75 0 0 1 0 1.06L5.53 19.53a.75.75 0 0 1-1.06-1.06l1.343-1.343a.75.75 0 0 1 1.06 0Zm10.253 0a.75.75 0 0 1 1.061 0l1.343 1.343a.75.75 0 1 1-1.06 1.06l-1.343-1.343a.75.75 0 0 1 0-1.06ZM12 19.25a.75.75 0 0 1 .75.75v.5a.75.75 0 0 1-1.5 0V20a.75.75 0 0 1 .75-.75Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			d='M11.345 8.5a.9.9 0 0 1 .746.394l.484.716-.003.002 1.962 2.932.016-.01 1.643 2.433a.11.11 0 0 1-.093.172h-3.123a.903.903 0 0 1-.658-.284l-.946-1.414a.022.022 0 0 0-.034-.003l-.133.139a.022.022 0 0 0-.002.028l.436.637-.443.557a.901.901 0 0 1-.706.34H8.406a.11.11 0 0 1-.078-.19l2.45-2.304a.034.034 0 0 0 .005-.044L8.13 8.672a.11.11 0 0 1 .094-.172h3.122ZM14.001 11.267a.034.034 0 0 1-.054-.002l-.64-.956a.034.034 0 0 1 .007-.044l1.84-1.52a.903.903 0 0 1 .618-.245h.195a.11.11 0 0 1 .088.179l-2.054 2.588Z'
		/>
	</svg>
);

export default SvgComponent;

import { bindActions } from '@utils';
import { actions } from './reducer';

export const {
	deleteFavoriteProviderGamesDispatcher,
	updateFavoriteProviderGames,
	setProviderGames,
	setLocalGames,
	setProviders,
	setCurrentInitedGame,
	clearCurrentInitedGame,
	setGameCategories,
	setCurrentGameCategory,
	addProviderGames,
	setCurrentGameProvider,
	setGameCount
} = bindActions(actions);

import { BonusService } from './BonusService';
import { store } from '@store/index';
import { getCurrentUserData } from '../user';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { antiSpamMonitor } from '@lib/browser-storage';
import { AntiSpamModules } from '@lib/browser-storage/anti-spam/antiSpamMonitor';
import { setPromocodeUsingBan } from '@store/reducers/bonus/dispatchers';
import { SuccessToast } from '@components/common/global-settings';
import toast from 'react-hot-toast';
import { AnalyticsEventType } from '@types';
import { sendAnalyticsHandler } from '@utils';

export const initPromocode = async (
	values?: { promocode: string },
	setFieldError?: (field: string, error: string) => void
) => {
	const {
		bonus: {
			promocode: { inputValue }
		}
	} = store.getState();

	try {
		const data = await BonusService.initPromocode({
			code: values?.promocode || inputValue
		});

		// успешно использвоан
		if (data?.status === 201) {
			sendAnalyticsHandler(AnalyticsEventType?.promocode_activate_success);
			toast((t) => SuccessToast({ t: t, text: 'promo_code_activated' }));

			setModalType(null);
			await getCurrentUserData();
		}
	} catch (error: any) {
		const { isForbidden, remainedBlockedTime } = await antiSpamMonitor(
			AntiSpamModules.promocode
		);

		sendAnalyticsHandler(AnalyticsEventType?.promocode_activate_error);

		setPromocodeUsingBan({
			usingForbiddenTime: remainedBlockedTime,
			isForbiddenForUsing: isForbidden
		});

		// не существует
		if (error?.response?.status === 404) {
			setFieldError && setFieldError('promocode', 'promocode_404');
		}

		// // уже использован
		if (error?.response?.status === 400) {
			setFieldError && setFieldError('promocode', 'promocode_404');
		}
	}
};

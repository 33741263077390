import {
	AuthPA,
	IAuthInitialState,
	SetAuthTokenPA,
	SetAuthTypePA,
	SetIsAuthPendingPA,
	SetIsResetPasswordModePA,
	SetIsSendedConfirmPA,
	SetNextAttemptResetPasswordPA,
	SetReceivedCredentialsPA
} from '@types';

export const setAuthType = (state: IAuthInitialState, action: SetAuthTypePA) => {
	state.authType = action.payload;
};

// TODOрефакт. вынести в конфиг инит значения
export const auth = (state: IAuthInitialState, action: AuthPA) => {
	state.authType = null;
	state.receivedCredentials = null;
	state.isAuthed = action.payload;
};

export const setAuthToken = (state: IAuthInitialState, action: SetAuthTokenPA) => {
	state.authToken = action.payload;
};

export const setnNextAttemptResetPassword = (
	state: IAuthInitialState,
	action: SetNextAttemptResetPasswordPA
) => {
	state.nextAttemptResetPassword = action.payload;
};

export const setReceivedCredentials = (
	state: IAuthInitialState,
	action: SetReceivedCredentialsPA
) => {
	state.receivedCredentials = action.payload;
};

export const setIsSendedConfirm = (
	state: IAuthInitialState,
	action: SetIsSendedConfirmPA
) => {
	state.isSendedConfirm = action.payload;
};

export const setIsResetPasswordMode = (
	state: IAuthInitialState,
	action: SetIsResetPasswordModePA
) => {
	state.isResetPasswordMode = action.payload;
};

export const setIsAuthPending = (
	state: IAuthInitialState,
	action: SetIsAuthPendingPA
) => {
	state.isAuthPending = action.payload;
};

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={25}
		fill='none'
		{...props}
	>
		<rect
			width={24}
			height={24}
			y={0.5}
			fill='#2EDB73'
			rx={6}
		/>
		<path
			stroke='#fff'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.5}
			d='m8.5 13 2 2 5-5'
		/>
	</svg>
);

export default SvgComponent;

import axios, { AxiosInstance, AxiosResponse } from 'axios';
import isNull from 'lodash/isNull';
import {
	getAuthToken,
	removeAntiSpamMonitor,
	removeAuthToken
} from '@lib/browser-storage';
import { reloadPage } from '@utils';
import { AntiSpamModules } from '@lib/browser-storage/anti-spam/antiSpamMonitor';
import { auth, setIsAuthPending } from '@store/reducers/auth/dispathcers';
import { clearUserData } from '@store/reducers/user/dispatchers';
import { clearPayment } from '@store/reducers/payment/dispathcers';
import { clearBonus } from '@store/reducers/bonus/dispatchers';
import qs from 'qs';
import { ErrorToast } from '@components/common/global-settings';
import toast from 'react-hot-toast';
import { NotificationsModalStorageE } from '@types';

export const instance: AxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	paramsSerializer: {
		serialize: (params: any) => qs.stringify(params, { arrayFormat: 'repeat' })
	}
});

// request interceptors

// @ts-ignore
instance.interceptors.request.use(
	(config) => {
		const authToken = getAuthToken();

		// @ts-ignore
		if (isNull(authToken)) config.headers = {};
		// @ts-ignore
		else config.headers.Authorization = `Bearer ${authToken}`;

		return config;
	},
	(error) => error
);

// response interceptors

// @ts-ignore
instance.interceptors.response.use(
	(config: AxiosResponse) => config,
	(error) => {
		if (error.response.status === 401) {
			removeAuthToken();

			removeAntiSpamMonitor(AntiSpamModules.promocode);
			localStorage?.removeItem(NotificationsModalStorageE.notification_modal);

			auth(false);
			clearUserData();
			clearPayment();
			clearBonus();

			reloadPage();
			window.location.href = '/';
		}

		if (error?.response?.status === 403 && error?.response?.data?.message === 'BLOCKED') {
			toast((t) => ErrorToast({ t: t, text: 'account_blocked' }));

			removeAuthToken();
			removeAntiSpamMonitor(AntiSpamModules.promocode);

			localStorage?.removeItem('deposite_bonus_authed');

			auth(false);
			clearUserData();
			clearPayment();
			clearBonus();
		}

		if (error.response.status === 404 && error.config.url === '/auth/forgot-password') {
			toast((t) => ErrorToast({ t: t, text: 'not_that_user' }));

			setIsAuthPending(false);
		}

		if (error.response.status === 404 && error.config.url === '/auth/signin') {
			toast((t) => ErrorToast({ t: t, text: 'bad_password_account' }));

			setIsAuthPending(false);
		}

		throw error;
	}
);

// @ts-ignore
instance.interceptors.response.use(
	(config: AxiosResponse) => config,
	(error) => {
		if (error.response && error.response.status >= 500) console.log('Серверная ошибка');

		throw error;
	}
);

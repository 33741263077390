import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		{...props}
	>
		<path
			fill='#A0A4AD'
			data-id='changed-color'
			fillRule='evenodd'
			d='M2.572 6.334c-.211.35-.065.799.3.986.364.186.808.04 1.022-.308a9.517 9.517 0 1 1-1.237 3.174c.078-.402-.15-.81-.545-.919a.704.704 0 0 0-.888.524 11 11 0 1 0 1.348-3.457Z'
			clipRule='evenodd'
		/>
		<path
			fill='#A0A4AD'
			data-id='changed-color'
			fillRule='evenodd'
			d='M19.743 9.99c.103.394-.174.776-.577.841-.403.066-.778-.209-.89-.602a6.522 6.522 0 1 0-.042 3.687c.12-.39.502-.657.903-.582.401.075.67.462.558.855a8 8 0 1 1 .048-4.2Z'
			clipRule='evenodd'
		/>
		<path
			fill='#A0A4AD'
			data-id='changed-color'
			fillRule='evenodd'
			d='M12 2.75a.75.75 0 0 1 .75.75V4a.75.75 0 0 1-1.5 0v-.5a.75.75 0 0 1 .75-.75ZM12 19.25a.75.75 0 0 1 .75.75v.5a.75.75 0 0 1-1.5 0V20a.75.75 0 0 1 .75-.75ZM4.75 12a.75.75 0 0 1-.75.75h-.5a.75.75 0 0 1 0-1.5H4a.75.75 0 0 1 .75.75ZM19.53 19.53a.75.75 0 0 1-1.06 0l-1.343-1.343a.75.75 0 0 1 1.06-1.06l1.343 1.343a.75.75 0 0 1 0 1.06ZM6.873 6.873a.75.75 0 0 1-1.06 0L4.47 5.53a.75.75 0 1 1 1.06-1.06l1.343 1.343a.75.75 0 0 1 0 1.06ZM4.47 19.53a.75.75 0 0 1 0-1.06l1.343-1.343a.75.75 0 1 1 1.06 1.06L5.53 19.53a.75.75 0 0 1-1.06 0ZM17.127 6.873a.75.75 0 0 1 0-1.06L18.47 4.47a.75.75 0 1 1 1.06 1.06l-1.343 1.343a.75.75 0 0 1-1.06 0ZM12.75 6.75a.75.75 0 0 0-.75.75c0 .797.165 1.42.44 1.936.27.505.625.862.92 1.14l.109.103c.536.504.781.734.781 1.321 0 .288-.063.445-.117.53a.503.503 0 0 1-.24.193c-.258.11-.645.112-.996.042A.75.75 0 0 0 12 13.5c0 1.978.828 3.252 1.665 3.67a.75.75 0 1 0 .67-1.34c-.132-.067-.602-.493-.773-1.523.302-.017.62-.076.92-.205.346-.147.677-.39.916-.766.238-.373.352-.824.352-1.336 0-1.26-.71-1.914-1.224-2.388l-.136-.126c-.268-.253-.475-.474-.627-.758-.146-.274-.263-.65-.263-1.228a.75.75 0 0 0-.75-.75Z'
			clipRule='evenodd'
		/>
		<path
			fill='var(--main-accent)'
			fillRule='evenodd'
			d='M11.25 6.75a.75.75 0 0 1 .75.75c0 .797-.165 1.42-.44 1.936-.27.505-.625.862-.92 1.14l-.109.103c-.536.504-.781.734-.781 1.321 0 .288.063.445.117.53a.503.503 0 0 0 .24.193c.258.11.645.112.996.042A.75.75 0 0 1 12 13.5c0 1.978-.828 3.252-1.665 3.67a.75.75 0 1 1-.67-1.34c.132-.067.602-.493.773-1.523a2.734 2.734 0 0 1-.92-.205 2.001 2.001 0 0 1-.916-.766c-.238-.373-.352-.824-.352-1.336 0-1.26.71-1.914 1.224-2.388l.136-.126c.268-.253.475-.474.627-.758.146-.274.263-.65.263-1.228a.75.75 0 0 1 .75-.75Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;

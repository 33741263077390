import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		{...props}
	>
		<path
			fill='#A0A4AD'
			data-id='changed-color'
			fillRule='evenodd'
			d='M20 16V8a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1Zm1.5-8A2.5 2.5 0 0 0 19 5.5H5A2.5 2.5 0 0 0 2.5 8v8A2.5 2.5 0 0 0 5 18.5h14a2.5 2.5 0 0 0 2.5-2.5V8Z'
			clipRule='evenodd'
		/>
		<path
			fill='#A0A4AD'
			data-id='changed-color'
			fillRule='evenodd'
			d='m8.756 19.704 5.416-2.629.655 1.35-5.416 2.629a3.25 3.25 0 0 1-4.208-1.255l-.996-1.664 1.287-.77.996 1.664a1.75 1.75 0 0 0 2.266.675ZM14.434 2.59a3.25 3.25 0 0 1 4.364 1.368l1.16 2.191-1.327.702-1.159-2.191a1.75 1.75 0 0 0-2.35-.737L9.094 7.037l-.688-1.332 6.028-3.114Z'
			clipRule='evenodd'
		/>
		<path
			fill='red'
			d='M11.856 15.364V9h.408v6.364h-.408Zm.885-4.137a.537.537 0 0 0-.186-.388c-.11-.092-.276-.139-.5-.139a.996.996 0 0 0-.35.053.421.421 0 0 0-.2.136.339.339 0 0 0-.066.2.327.327 0 0 0 .032.166.404.404 0 0 0 .124.131.979.979 0 0 0 .217.105c.088.031.192.06.313.084l.418.09c.281.06.522.138.723.236.2.098.364.213.492.345.128.131.221.279.281.443.061.164.093.343.094.537-.001.335-.085.618-.25.85a1.535 1.535 0 0 1-.712.53c-.306.12-.675.18-1.106.18-.442 0-.828-.064-1.158-.195a1.615 1.615 0 0 1-.766-.604c-.18-.274-.272-.624-.273-1.05h1.312a.779.779 0 0 0 .117.393c.07.106.167.187.293.241.128.055.28.082.455.082.148 0 .271-.018.37-.054a.5.5 0 0 0 .227-.152.358.358 0 0 0 .08-.221.315.315 0 0 0-.078-.204.597.597 0 0 0-.238-.16 2.54 2.54 0 0 0-.45-.133l-.507-.11c-.451-.098-.806-.26-1.067-.49-.258-.23-.387-.544-.385-.941a1.401 1.401 0 0 1 .259-.848 1.73 1.73 0 0 1 .728-.57 2.657 2.657 0 0 1 1.071-.203c.411 0 .767.069 1.067.206.3.138.53.332.693.582.164.248.247.54.249.872H12.74Z'
		/>
	</svg>
);

export default SvgComponent;

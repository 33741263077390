import { BonusService } from './BonusService';
import { getReferralRewards, getReferralsQtyAndPendingRewards } from '.';
import { SuccessToast } from '@components/common/global-settings';
import toast from 'react-hot-toast';

export const referralPendingRewards = async () => {
	const { status } = await BonusService.referralPendingRewards();

	if (status === 200) {
		toast((t) => SuccessToast({ t: t, text: 'money_transfer_to_balance' }));

		getReferralRewards();
		getReferralsQtyAndPendingRewards();
	}
};

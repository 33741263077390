import {
	InitUserPA,
	IUserInitialState,
	SetActiveCurrencyPA,
	SetBalancePA,
	SetBonusBalancePA,
	SetBonusBalanceWagerPA,
	SetCreatedAtPA,
	SetEditingSettingsPA,
	SetLangCodePA,
	SetLocationDataPA,
	SetNewCurrentUserImagePA,
	SetPremiumStatusPA,
	SetReferralCodePA,
	SetSocialsLinkedPA,
	SetUpdMetaPA,
	SetUserLevels,
	SetUserLevelSumPA,
	SetUserLevleIdPA,
	SetDepositWagerPA
} from '@types';

export const initUser = (state: IUserInitialState, action: InitUserPA) => {
	for (const property in action.payload) {
		if (
			property === 'balance' ||
			property === 'bonusBalanceEnableValue' ||
			property === 'bonusBalanceMaxValue' ||
			property === 'bonusBalance' ||
			property === 'depositWager' ||
			property === 'bonusBalanceWager'
		) {
			state[property] = action.payload[property] / 100;
		} else {
			// @ts-ignore
			state[property] = action.payload[property];
		}
	}
};

export const setLocationData = (state: IUserInitialState, action: SetLocationDataPA) => {
	state.locationData = action.payload;
};

export const setBalance = (state: IUserInitialState, action: SetBalancePA) => {
	state.balance = action.payload / 100;
};

export const setBonusBalance = (state: IUserInitialState, action: SetBonusBalancePA) => {
	state.bonusBalance = action.payload / 100;
};

export const setBonusBalanceWager = (
	state: IUserInitialState,
	action: SetBonusBalanceWagerPA
) => {
	state.bonusBalanceWager = action.payload / 100;
};

export const setUserLevelSum = (state: IUserInitialState, action: SetUserLevelSumPA) => {
	state.userLevelSum = action.payload;
};

export const setDepositWager = (state: IUserInitialState, action: SetDepositWagerPA) => {
	state.depositWager = action.payload / 100;
};

export const setCreatedAt = (state: IUserInitialState, action: SetCreatedAtPA) => {
	state.createdAt = action.payload;
};

export const setUserLevelId = (state: IUserInitialState, action: SetUserLevleIdPA) => {
	state.userLevelId = action.payload;
};

export const setUsersLevels = (state: IUserInitialState, action: SetUserLevels) => {
	state.userLevels = action.payload;
};

export const setEditingSettings = (
	state: IUserInitialState,
	action: SetEditingSettingsPA
) => {
	state.editingSettings =
		action.payload.rule === 'add'
			? [...state.editingSettings, action.payload.setting]
			: state.editingSettings.filter((setting) => setting !== action.payload.setting);
};

export const setUpdMeta = (state: IUserInitialState, action: SetUpdMetaPA) => {
	state.updMeta = {
		...state.updMeta,
		[action.payload.setting]: action.payload.value
	};
};

export const setActiveCurrency = (
	state: IUserInitialState,
	action: SetActiveCurrencyPA
) => {
	state.currency = action.payload;
};

export const setLangCode = (state: IUserInitialState, action: SetLangCodePA) => {
	state.langCode = action.payload;
};

// TODOрефакт. вынести пустой объект, назначитть здесь и еще в редьюсере
export const clearUserData = (state: IUserInitialState) => {
	state.id = 0;
	state.username = '';
	state.email = '';
	state.image = '';
	state.balance = 0;
	state.isVerified = false;
	state.isPremium = false;
	state.bonusBalance = 0;
	state.userLevelId = 0;
	state.referralCode = '';
	state.editingSettings = [];
	state.updMeta = {};
	state.socialsLinked = { vk: false, telegram: false };
};

export const setNewCurrentUserImage = (
	state: IUserInitialState,
	action: SetNewCurrentUserImagePA
) => {
	state.image = action.payload;
};

export const setSocialsLinked = (
	state: IUserInitialState,
	action: SetSocialsLinkedPA
) => {
	state.socialsLinked[action.payload.social] = action.payload.isLinked;
};

export const setReferralCode = (state: IUserInitialState, action: SetReferralCodePA) => {
	state.referralCode = action.payload;
};

export const setPremiumStatus = (
	state: IUserInitialState,
	action: SetPremiumStatusPA
) => {
	state.isPremium = action.payload;
};
